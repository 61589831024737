import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { Http } from "../../utility/http";
import { Business } from "../../shared/generic.interface";
import { SkeletonComp } from "../../shared/Skeleton/Skeleton";
import { Link } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  addBusinessUnitModalToggle,
  businessUnitIdState,
  editBusinessUnitModalToggle,
} from "../../state/atom/settings";
import { AddBusinessUnitModal } from "./Modals/Business/AddBusinessUnitModal";
import { EditBusinessUnitModal } from "./Modals/Business/EditBusinessUnitModal";

export const BusinessTableList: React.FC = () => {
  const [businessUnitList, setBusinessUnitList] = useState<Array<Business>>([]);
  const [businessUnitListCloneData, setBusinessUnitListCloneData] = useState<Array<Business>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showAddModal, setShowAddModal] = useRecoilState(
    addBusinessUnitModalToggle
  );
  const [showEditModal, setShowEditModal] = useRecoilState(
    editBusinessUnitModalToggle
  );

  const setBusinessUnitId = useSetRecoilState(businessUnitIdState);
  const ITEMS_PER_PAGE: number = 10;
  const SR_NO = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const TOTAL_PAGES = Math.ceil(businessUnitList.length / ITEMS_PER_PAGE);
  // Calculate the range of visible pages based on the current page
  const VISIBLE_PAGE = 5;
  const startPage = Math.max(1, currentPage - Math.floor(VISIBLE_PAGE / 2));
  const endPage = Math.min(TOTAL_PAGES, startPage + VISIBLE_PAGE - 1);
  const inputRef = useRef<HTMLInputElement | null>(null);


  useEffect(() => {
    const getBusinessUnits = () => {
      setLoading(true);
      Http.request("get", `${process.env.REACT_APP_API_URL}business-units`)
        .then((result: any) => {
          setBusinessUnitList(result.data.items);
          setBusinessUnitListCloneData(result.data.items);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    getBusinessUnits();
  }, [showAddModal, showEditModal]);

  //pagination code...
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const paginateData = businessUnitList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  //handle search feature
  const filterRequest = (event: any) => {
    let filteredRequest: any = businessUnitListCloneData.filter((item: Business) => {
      return (
        item.name
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    setBusinessUnitList(filteredRequest);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleShowEditModal = () => {
    setShowEditModal(true);
  };

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  //handle clear search input
  const handleClearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setBusinessUnitList(businessUnitListCloneData);
    }
  };

  return (
    <>
      <Card>
        <div className="text-right panel-title mb-0">
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "20px" }}
          >
            Business-Units List
          </span>

          {/* Search box */}
          <div className="form-group has-search user-search-box">
            <span className="fa fa-search form-control-feedback"></span>

            <input type="text" className="form-control" placeholder="Search" ref={inputRef} onChange={filterRequest} />
            {inputRef?.current?.value !== "" && (
              <i
                className="bi bi-x-circle-fill cancle-icon"
                onClick={handleClearSearch}
              ></i>
            )}
          </div>

          <span
            style={{
              paddingRight: "60px",
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
          >
            <button
              type="button"
              className="btn btn-primary btn"
              onClick={handleShowAddModal}
            >
              Add Business-Unit
            </button>
          </span>
          {/* Close Icon */}
          {/* <span className="close-btn" id="state-mgt-close">
            &times;
          </span> */}
        </div>
        <div
          className="table-responsive table-responsive-lg table-responsive-md table-responsive-xl p-0"
          style={{ maxHeight: "350px" }}
        >
          {/* List of the district in table form */}
          <table className="table align-items-center justify-content-center mb-0 text-white">
            <thead
              style={{ background: "#6a294b", color: "white" }}
              className="table-head"
            >
              <tr>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  No
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  Name
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody style={{ fontSize: "14px" }}>
              {loading && <SkeletonComp totalCol={3} />}
              {!loading &&
                paginateData.length > 0 &&
                paginateData.map((item: Business, index: number) => {
                  return (
                    <tr key={index} style={{ color: "black" }}>
                      <td>
                        <div className="d-flex px-2">
                          <div className="my-auto">
                            <h6 className="mb-0 text-sm">{SR_NO + index}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <p className="text-sm text-uppercase font-weight-bold mb-0">
                          {item.name}
                        </p>
                      </td>
                      <td>
                        <div className="ms-auto">
                          <Link
                            className="fixed-plugin-button btn btn-sm  btn-primary text-gradient"
                            to=""
                            onClick={() => {
                              setBusinessUnitId(item.id);
                              handleShowEditModal();
                            }}
                          >
                            <i className="material-icons">edit</i>
                          </Link>
                          &nbsp;
                          {/* <Link
                          className="fixed-plugin-button btn btn-sm btn-danger text-gradient"
                          to=""
                          // onClick={handleShowDeleteModal}
                        >
                          <i className="material-icons">close</i>
                        </Link> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {!loading && (
          <div className="d-flex justify-content-center pt-3">
            <Pagination>
              {currentPage > 1 && (
                <>
                  <Pagination.First
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                  />
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                </>
              )}
              {Array.from({ length: endPage - startPage + 1 }).map(
                (_, index: number) => (
                  <Pagination.Item
                    key={startPage + index}
                    active={startPage + index === currentPage}
                    onClick={() => handlePageChange(startPage + index)}
                  >
                    {startPage + index}
                  </Pagination.Item>
                )
              )}
              {currentPage !== TOTAL_PAGES && (
                <>
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === TOTAL_PAGES}
                  />
                  <Pagination.Last
                    onClick={() => handlePageChange(TOTAL_PAGES)}
                    disabled={currentPage === TOTAL_PAGES}
                  />
                </>
              )}
            </Pagination>
          </div>
        )}
      </Card>
      {showAddModal && <AddBusinessUnitModal />}
      {showEditModal && <EditBusinessUnitModal />}
    </>
  );
};

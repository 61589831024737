import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { NotFound } from "./pages/notfound";
import Login from "./pages/logix";
import Dashboard from "./pages/dashboard";
import Protected from "./protected";
import { Home } from "./pages/home";
import { getUserDetails } from "./utility/jwt";
import { RecoilRoot } from "recoil";
import { Signup } from "./pages/signup";
import { ForgotPassword } from "./pages/forgot-password";
import { ResetPassword } from "./pages/reset-password";
import { Report } from "./pages/report";
import { GIS } from "./pages/gis";
import { SettingsPage } from "./pages/settings";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const isLoggedIn = getUserDetails().isLoggedIn;
const role = getUserDetails().user.role;

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <Routes>
          {isLoggedIn ? (
            role === "le_data" ? (
              <Route path="/" element={<Navigate to="/gis" />} />
            ) : (
              <Route path="/" element={<Navigate to="/home" />} />
            )
          ) : (
            <Route path="/" element={<Login />} />
          )}
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="dashboard"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="home"
            element={
              <Protected>
                <Home />
              </Protected>
            }
          />
          <Route
            path="report"
            element={
              <Protected>
                <Report />
              </Protected>
            }
          />
          <Route
            path="gis"
            element={
              <Protected>
                <GIS />
              </Protected>
            }
          />
          <Route
            path="settings"
            element={
              <Protected>
                <SettingsPage />
              </Protected>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

reportWebVitals();

import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { toaster } from "../../../../state/atom/toaster";
import { addBusinessUnitModalToggle } from "../../../../state/atom/settings";
import { Modal } from "react-bootstrap";
import { Http } from "../../../../utility/http";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiHeaders } from "../../../../utility/generic";

type AddBusinessUnitForm = {
  name: string;
};
export const AddBusinessUnitModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useRecoilState(addBusinessUnitModalToggle);
  const setToasterStatus = useSetRecoilState(toaster);

  const addBusinessUnitValidationSchema = Yup.object().shape({
    name: Yup.string().required("Business unit name is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddBusinessUnitForm>({
    resolver: yupResolver(addBusinessUnitValidationSchema),
  });

  const onSubmitHandler = async (data: AddBusinessUnitForm) => {
    setLoading(true);
    await Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}business-units`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: "Business-Unit Added.",
          text: result.message,
        });
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          className="panel-title"
          style={{ marginBottom: 0 }}
          closeButton
          closeVariant="white"
        >
          <Modal.Title className="text-white">Add Business-Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {submitErrorMessage && (
              <div className="text-center py-1">
                <span className="text-danger">{submitErrorMessage}</span>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="d-flex flex-column">
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="inputStype"
                      style={{ color: "black" }}
                    >
                      Name
                    </label>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    id="business-Unit"
                    placeholder="Enter business unit name."
                    {...register("name")}
                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group px-3 py-2">
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1 w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

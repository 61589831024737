import ReactECharts from "echarts-for-react";
import { MainLayout } from "../layout/main-layout";
import { selectedMenu } from "../state/atom/generic";
import { Menu, fixNumber } from "../shared/generic.constant";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";
import { DashboardFilter } from "../component/dashboard/dashboard-filter";
import { profileDetails } from "../state/atom/user";
import { useNavigate } from "react-router-dom";
import { gisList } from "../state/atom/gis";
import { dashboardSummary } from "../state/atom/dashboard";
import { GISMap } from "../component/gis/gis-map";
import { validatePermission } from "../utility/permission";
import { getDashBoardSummary } from "../shared/dashboard";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ReactApexChart from "react-apexcharts";
const Dashboard = () => {
  validatePermission("menu", "dashboard");
  const navigate = useNavigate();
  const [gis, setGisList] = useRecoilState(gisList);
  const setSelectedMenu = useSetRecoilState(selectedMenu);
  setSelectedMenu(Menu.dashboard);
  const myProfileDetails = useRecoilValue(profileDetails);
  const dashboardSummaryData = useRecoilValue(dashboardSummary);
  console.log(dashboardSummaryData);
  const [requestStatics, setRequestStatics] = useState({
    totalBusiness: [],
    totalSite: [],
    totalState: [],
  });
  useEffect(() => {
    const { dashboardAccess } = myProfileDetails;
    if (!dashboardAccess) {
      navigate("/home");
    }
  }, [myProfileDetails, navigate]);
  //*Bar chart options
  const barchartoptions = {
    grid: { top: 20, right: 40, bottom: 20, left: 40 },
    xAxis: {
      data: ["Area Required ", "Sale Deed", "Mutation", "NA"],
      axisLabel: {
        rotate: 360,
        color: "black",
      },
    },
    yAxis: {
      axisLabel: {
        color: "black",
      },
      axisLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      type: "value",
    },
    series: [
      {
        data: [
          {
            value: fixNumber(dashboardSummaryData.totalAreaRequired),
            itemStyle: {
              color: "#964B00",
            },
          },
          {
            value: fixNumber(dashboardSummaryData.totalSalesDeed),
            itemStyle: {
              color: "#fd516c",
            },
          },
          {
            value: fixNumber(dashboardSummaryData.totalMutationStatus),
            itemStyle: {
              color: "#a2e572",
            },
          },
          {
            value: fixNumber(dashboardSummaryData.totalNAStatus),
            itemStyle: {
              color: "#8de5dc",
            },
          },
        ],
        type: "bar",
        name: "Area in Acres",
        barWidth: "20%",
        color: "#1CA6EF",
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  const [businessChart, setBusinessChart] = useState<any>(null);
  const options = {
    legend: {
      display: false,
    },
  };
  useEffect(() => {
    ChartJS.register(ArcElement, Tooltip, Legend);
    if (dashboardSummaryData?.activeBusiness?.length) {
      const businessName = dashboardSummaryData.activeBusiness.map(
        (summry: any) => summry.name
      );
      const businessCount = dashboardSummaryData.activeBusiness.map(
        (summry: any) => summry.count
      );
      const colors = dashboardSummaryData.activeBusiness.map(
        (summry: any) => summry.color
      );

      /* const data = {
        labels: businessName,
        datasets: [
          {
            label: "Total sites: ",
            data: businessCount,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      }; */

      const data = {
        series: businessCount,
        options: {
          chart: {
            type: "donut",
          },
          labels: businessName,
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            // @ts-ignore: Unreachable code error
            formatter: function (val, opts) {
              return opts.w.globals.labels[opts.seriesIndex].length > 7
                ? opts.w.globals.labels[opts.seriesIndex].substring(0, 7) +
                    "..."
                : opts.w.globals.labels[opts.seriesIndex];
            },
            offsetY: 30,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          legend: {
            show: false,
          },
          title: {
            text: "Business Wise Sites",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
            },
          },
        },
      };
      /* const data = {
        series: [
          44, 55, 13, 33, 44, 55, 13, 33, 44, 55, 13, 33, 44, 55, 13, 33,
        ],
        options: {
          chart: {
            type: "donut",
          },
          labels: [
            "Team ABCDH HHYY J",
            "Team B",
            "Team C",
            "Team D",
            "Team A",
            "Team B",
            "Team C",
            "Team D",
            "Team A",
            "Team B",
            "Team C",
            "Team D",
            "Team A",
            "Team B",
            "Team C",
            "Team D",
          ],
          dataLabels: {
            enabled: true, // Enable data labels
            enabledOnSeries: undefined,
            // @ts-ignore: Unreachable code error
            formatter: function (val, opts) {
              return opts.w.globals.labels[opts.seriesIndex].substring(0, 7) + "...";
            },
            offsetY: 30,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          legend: {
            show: false,
          },
        },
      }; */
      setBusinessChart(data);
    }
  }, [dashboardSummaryData]);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-request`,
      null,
      apiHeaders()
    )
      .then((result) => {
        const dashBoardSummary = getDashBoardSummary(result.data.landRequest);
        setRequestStatics({
          totalBusiness: dashBoardSummary.totalBusiness,
          totalSite: dashBoardSummary.totalSite,
          totalState: dashBoardSummary.totalState,
        });
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-parcel`,
      null,
      apiHeaders()
    )
      .then((result) => {
        setGisList(result);
      })
      .catch((error) => {});
  }, [setGisList]);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const businessPopover = (
    <Popover id="popover-positioned-right" title="Popover right">
      {/* {requestStatics.totalBusiness.join(", ")} */}
      <ul className="popover-container">
        {dashboardSummaryData?.activeBusiness?.map((business: any) => {
          return <li>{business.name}</li>;
        })}
      </ul>
    </Popover>
  );

  const statePopover = (
    <Popover id="popover-positioned-right" title="Popover right">
      <ul className="popover-container">
        {dashboardSummaryData?.totalState?.map((state: any) => {
          return <li>{state.name}</li>;
        })}
      </ul>
    </Popover>
  );
  const sitePopover = (
    <Popover id="popover-positioned-right" title="Popover right">
      <ul className="popover-container">
        {dashboardSummaryData?.totalSite?.map((site: any) => {
          return <li>{site.name}</li>;
        })}
      </ul>
    </Popover>
  );

  return (
    <MainLayout>
      <div className="container-fluid main-content full-layout-content dashboard-block">
        <div className="dashboard-widget" style={{ height: "36vh" }}>
          <div className="header-body">
            <div className="row h-50  mt-1 dashboard-card">
              <div className="col-xl-4 colcustom-3">
                <div className="card card-stats mb-4 mb-xl-0 h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center h-100">
                      <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">
                          Business
                        </h5>
                        <span className="action-btn">
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={businessPopover}
                            rootClose
                          >
                            <Button>
                              {dashboardSummaryData?.activeBusiness?.length}
                            </Button>
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="material-icons opacity-10 pe-1">
                            work_outline
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 colcustom-3">
                <div className="card card-stats mb-4 mb-xl-0 h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center h-100">
                      <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">
                          State
                        </h5>
                        <span className="action-btn">
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={statePopover}
                            rootClose
                          >
                            <Button>
                              {dashboardSummaryData?.totalState?.length}
                            </Button>
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="material-icons opacity-10 pe-1">
                            public
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 colcustom-3">
                <div className="card card-stats mb-4 mb-xl-0 h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center h-100">
                      <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">
                          Site
                        </h5>
                        {/* <span className="h2 font-weight-bold mb-0">
                          {requestStatics.totalSite.length}
                        </span> */}
                        <span className="action-btn">
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={sitePopover}
                            rootClose
                          >
                            <Button>
                              {dashboardSummaryData?.totalSite?.length}
                            </Button>
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="material-icons opacity-10 pe-1">
                            apartment
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row h-50">
              {/* <div className="col-xl-3 col-lg-6">
                <div className="card card-stats mb-4 mb-xl-0">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">
                          Total Request
                        </h5>
                        <span className="h2 font-weight-bold mb-0">
                          {dashboardSummaryData.totalRequest}
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="material-icons opacity-10 pe-1">
                            request_page
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-3 colcustom-4 col-box">
                <div className="card card-stats  mb-xl-0 h-100">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title font-weight-bold text-uppercase text-muted mb-0">
                          Total Area (Acre) Required
                        </h5>
                        <span className="mb-0 blue-color widget-desc">
                          {fixNumber(dashboardSummaryData.totalAreaRequired)}
                        </span>
                      </div>
                      {/* <div className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="material-icons opacity-10 pe-1">
                            area_chart
                          </i>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 colcustom-4 col-box">
                <div className="card card-stats  mb-xl-0 h-100">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title font-weight-bold text-uppercase text-muted mb-0">
                          Total Sale deed (Acre)
                        </h5>
                        <span className="mb-0 widget-desc">
                          Completed:&nbsp;
                          <span className="blue-color">
                            {fixNumber(dashboardSummaryData.totalSalesDeed)}
                          </span>
                          <br></br>
                          &nbsp;Pending:&nbsp;
                          <span className="blue-color">
                            {fixNumber(
                              dashboardSummaryData.totalPendingSalesDeed
                            )}
                          </span>
                        </span>
                        {/* <span className="mb-0">
                          Pending:&nbsp;
                          {fixNumber(
                            dashboardSummaryData.totalPendingSalesDeed
                          )}
                        </span> */}
                      </div>
                      {/* <div className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="material-icons opacity-10 pe-1">
                            receipt_long
                          </i>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 colcustom-4 col-box">
                <div className="card card-stats mb-xl-0 h-100">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title font-weight-bold text-uppercase text-muted mb-0">
                          Total Mutation (Acre){" "}
                        </h5>
                        <span className="mb-0 widget-desc">
                          Completed:&nbsp;
                          <span className="blue-color">
                            {fixNumber(
                              dashboardSummaryData.totalMutationStatus
                            )}
                          </span>
                          <br></br>
                          &nbsp; Pending:&nbsp;
                          <span className="blue-color">
                            {fixNumber(
                              dashboardSummaryData.totalPendingMutationStatus
                            )}
                          </span>
                        </span>
                      </div>
                      {/* <div className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="material-icons opacity-10 pe-1">
                            published_with_changes
                          </i>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 colcustom-4 col-box">
                <div className="card card-stats mb-xl-0 h-100">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title font-weight-bold text-uppercase text-muted mb-0">
                          Total NA (Acre)
                        </h5>
                        <span className="mb-0 widget-desc">
                          Completed:&nbsp;
                          <span className="blue-color">
                            {fixNumber(dashboardSummaryData.totalNAStatus)}
                          </span>
                          <br></br>
                          &nbsp;Pending:&nbsp;
                          <span className="blue-color">
                            {fixNumber(
                              dashboardSummaryData.totalPendingNAStatus
                            )}
                          </span>
                        </span>
                        {/* <span className="mb-0">
                          Pending:&nbsp;
                          {fixNumber(dashboardSummaryData.totalPendingNAStatus)}
                        </span> */}
                      </div>
                      {/* <div className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="material-icons opacity-10 pe-1">
                            published_with_changes
                          </i>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DashboardFilter></DashboardFilter>
        </div>
        <div className="row" style={{ height: "calc(100vh - 35vh - 20px)" }}>
          <div className="col-lg-4 col-md-6 mb-4" style={{ height: "100%" }}>
            <h6
              className="mb-0"
              style={{
                paddingTop: "10px",
                paddingLeft: "5px",
                background: "#fff",
              }}
            >
              Acquisition Status
            </h6>
            <ReactECharts
              option={barchartoptions}
              style={{ height: "90%", background: "#fff" }}
            />
            <hr className="dark horizontal" />
          </div>
          <div
            className="col-lg-4 col-md-6 mb-4"
            style={{ height: "100%", background: "#fff" }}
          >
            {/* <h6 className="mb-0 mt-2">Acquisition Status</h6> */}
            {/* {businessChart && <Doughnut data={businessChart} />} */}
            {businessChart && (
              <ReactApexChart
                options={businessChart?.options}
                series={businessChart?.series}
                type="donut"
              />
            )}

            <hr className="dark horizontal" />
          </div>

          <div className="col-lg-4 col-md-6 mb-4 mb-3 h-100 ">
            <h6 className="gis-map-title">GIS Map</h6>
            <div className="card z-index-2 " style={{ height: "100%" }}>
              <div
                className="card-body "
                style={{
                  background: "#8d3a7a",
                  color: "white",
                  padding: "0",
                }}
              >
                <GISMap
                  mapHeight="calc(100% - 46px)"
                  classList="dashboard-map-div"
                  showExtraButton={false}
                ></GISMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Dashboard;

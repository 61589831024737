import { useRecoilState } from "recoil";
import { toaster } from "../state/atom/toaster";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import ToastContainer  from "react-bootstrap/ToastContainer";

export const Toaster = () => {
  const [toasterData, setToasterData] = useRecoilState(toaster);
  //console.log(`toasterData`, toasterData);
  const { status, title, text } = toasterData;
  
  // Function to hide the Toast after 7 seconds
  const hideToastAfterDelay = () => {
    setTimeout(() => {
      setToasterData({ status: false, title: "", text: "" });
    }, 7000); // 7000 milliseconds = 7 seconds
  };

  // Call the hideToastAfterDelay function whenever status is true
  if (status) {
    hideToastAfterDelay();
  }
  return (
    <>
      <Row>
        <ToastContainer
          position="top-end"
          className="p-3"
        >
          <Toast
            show={status}
            onClose={() =>
              setToasterData({ status: false, title: "", text: "" })
            }
          >
            <Toast.Header className="toaster-header" closeVariant='white' >
              <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body className="toaster-body">{text}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Row>
    </>
  );
};

import {
  Land,
  Acquisition,
  ProposalType,
  Role,
  RoleForFindUsers,
  LandTypeSelect,
} from "./generic.interface";

export const BusinessUnit: any = {
  adani_enterprises_ltd: "Adani Enterprises",
  adani_ports_and_sez_ltd: "Adani Ports & SEZ",
  adani_green_energy_ltd: "Adani Green Energy",
  adani_total_gas_ltd: "Adani Total Gas",
  adani_transmission_ltd: "Adani Transmission",
  adani_power_ltd: "Adani Power",
};

export const RoleName: Role = {
  abu_spoc: "ABU SPOC",
  le_spoc: "LE SPOC",
  le_m: "LE M",
  le_dh: "LE DH",
  abu_eng: "ABU ENG",
};

export const RoleNameForFindUser: RoleForFindUsers = {
  le_spoc: "LE SPOC",
  le_dh: "LE DH",
};

export const LandType: Land = {
  PRIVATE_LAND: "Private",
  PRIVATE_LEASE_LAND: "Private Lease",
  GOVT_LAND: "Government",
  GOVT_LEASE_LAND: "Government Lease",
  FOREST: "Forest",
};

export const LandType1 = [
  { value: "PRIVATE_LAND", label: "Private" },
  { value: "PRIVATE_LEASE_LAND", label: "Private Lease" },
  { value: "GOVT_LAND", label: "Government" },
  { value: "GOVT_LEASE_LAND", label: "Government Lease" },
  { value: "FOREST", label: "Forest" },
  { value: "TO_BE_IDENTIFIED", label: "To Be Identified" },
  { value: "SC_ST_LAND", label: "SC/ST" },
];
export const AcquisitionType: Acquisition = {
  lease: "Lease",
  purchase: "Purchase",
  purchaselease: `Purchase & Lease`,
  ats: 'ATS'
};

export const Proposal_Type: ProposalType = {
  aggregator: "Aggergator",
  landbank: "Land Bank",
};

export const Menu = {
  home: "home",
  report: "report",
  dashboard: "dashboard",
  gis: "gis",
};

export const SettingsMenu = {
  state: 'state',
  district: 'district',
  city: 'city',
  business: 'business-unit',
  company: 'company'
}

export const capitalizeString = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const fixNumber = (number: any) => {
  if (number) {
    return Number(number).toFixed(2);
  }
  return 0;
};

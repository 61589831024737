export const getLandSummary = (filterRequest: any) => {
  let landSummary = {
    totalRequest: 0,
    totalAreaRequired: 0,
    totalAreaAccquired: 0,
    totalSalesDeed: 0,
    totalPendingSalesDeed: 0,
    totalMutationStatus: 0,
    totalPendingMutationStatus: 0,
    totalNAStatus: 0,
    totalPendingNAStatus: 0,
    totalTSRStatus: 0,
    totalFreeHoldArea: 0,
    totalLeaseArea: 0,
    activeBusiness: [],
    totalState: [],
    totalSite: [],
    landType: [],
  };
  landSummary.totalRequest = totalRequest(filterRequest);
  landSummary.totalAreaRequired = totalAreaRequired(filterRequest);
  landSummary.totalAreaAccquired = totalAreaAccquired(filterRequest);
  landSummary.totalSalesDeed = totalSalesDeed(filterRequest);
  landSummary.totalPendingSalesDeed = totalPendingSalesDeed(filterRequest);
  landSummary.totalMutationStatus = totalMutationStatus(filterRequest);
  landSummary.totalPendingMutationStatus =
    totalPendingMutationStatus(filterRequest);
  landSummary.totalNAStatus = totalNAStatus(filterRequest);
  landSummary.totalPendingNAStatus = totalPendingNAStatus(filterRequest);
  landSummary.totalTSRStatus = totalTSRStatus(filterRequest);
  landSummary.totalFreeHoldArea = totalFreeHoldArea(filterRequest);
  landSummary.totalLeaseArea = totalLeaseArea(filterRequest);
  landSummary.activeBusiness = activeBusiness(filterRequest);
  landSummary.totalState = totalState(filterRequest);
  landSummary.totalSite = totalSite(filterRequest);
  landSummary.landType = landType(filterRequest);
  return landSummary;
};

const colors = [
  "rgba(215, 67, 93, 0.6)",
  "rgba(34, 189, 227, 0.8)",
  "rgba(142, 215, 89, 0.4)",
  "rgba(245, 176, 66, 0.5)",
  "rgba(112, 34, 211, 0.7)",
  "rgba(18, 156, 127, 0.3)",
  "rgba(255, 102, 51, 0.6)",
  "rgba(74, 192, 47, 0.9)",
  "rgba(201, 15, 87, 0.2)",
  "rgba(38, 185, 154, 0.8)",
  "rgba(255, 193, 7, 0.4)",
  "rgba(0, 128, 255, 0.5)",
  "rgba(255, 77, 166, 0.7)",
  "rgba(33, 150, 243, 0.3)",
  "rgba(255, 61, 0, 0.6)",
  "rgba(0, 230, 118, 0.9)",
  "rgba(255, 87, 34, 0.2)",
  "rgba(123, 31, 162, 0.4)",
  "rgba(76, 175, 80, 0.7)",
  "rgba(255, 152, 0, 0.8)",
  "rgba(103, 58, 183, 0.5)",
  "rgba(121, 85, 72, 0.6)",
  "rgba(63, 81, 181, 0.3)",
  "rgba(0, 150, 136, 0.9)",
  "rgba(255, 235, 59, 0.4)",
  "rgba(156, 39, 176, 0.7)",
  "rgba(233, 30, 99, 0.6)",
  "rgba(255, 99, 71, 0.2)",
  "rgba(56, 142, 60, 0.8)",
  "rgba(255, 87, 34, 0.5)",
];

export const activeBusiness = (filterRequest: any) => {
  const activeBusinessArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      const index = activeBusinessArray.findIndex(
        (activeBusiness: any) =>
          activeBusiness.name === request.businessUnit.name
      );
      if (index !== -1) {
        activeBusinessArray[index].area += request.area;
        activeBusinessArray[index].count += 1;
      } else {
        activeBusinessArray.push({
          name: request.businessUnit.name,
          area: request.area,
          count: 1,
          color: colors[request.businessUnit.id] || colors[0],
        });
      }
    }
  });
  return activeBusinessArray;
};

export const totalState = (filterRequest: any) => {
  const totalStateArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      request.landParcel.forEach((parcel: any) => {
        const index = totalStateArray.findIndex(
          (state: any) => state.name === request.state.stateName
        );
        if (index !== -1) {
          totalStateArray[index].area += parcel.parcelArea;
        } else {
          totalStateArray.push({
            name: request.state.stateName,
            area: parcel.parcelArea,
          });
        }
      });
    }
  });
  return totalStateArray;
};

export const totalSite = (filterRequest: any) => {
  const totalSiteArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      request.landParcel.forEach((parcel: any) => {
        const index = totalSiteArray.findIndex(
          (state: any) => state.name === request.location
        );
        if (index !== -1) {
          totalSiteArray[index].area += parcel.parcelArea;
        } else {
          totalSiteArray.push({
            name: request.location,
            area: parcel.parcelArea,
          });
        }
      });
    }
  });
  return totalSiteArray;
};

export const landType = (filterRequest: any) => {
  const totalLandTypeArray: any = [];
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        const index = totalLandTypeArray.findIndex(
          (land: any) => land.name === parcel.landType
        );
        if (index !== -1) {
          totalLandTypeArray[index].area += parcel.parcelArea;
        } else {
          if (parcel.landType && parcel.landType != " ") {
            totalLandTypeArray.push({
              name: parcel.landType,
              area: parcel.parcelArea,
            });
          }
        }
      }
    });
  });
  return totalLandTypeArray;
};

export const totalRequest = (filterRequest: any) => {
  let requestCnt = 0;
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      requestCnt += 1;
    }
  });
  return requestCnt;
};

export const totalAreaRequired = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      totalArea += request.area;
    }
  });
  return totalArea;
};

export const totalAreaAccquired = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (
          parcel.acquiredStatus &&
          parcel.acquiredStatus.toUpperCase() === "YES"
        ) {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalSalesDeed = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (
          parcel.saleDeedStatus &&
          parcel.saleDeedStatus.toUpperCase() === "YES"
        ) {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalPendingSalesDeed = (filterRequest: any) => {
  /* let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (parcel.saleDeedStatus.toUpperCase() !== "YES") {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea; */
  return totalAreaRequired(filterRequest) - totalSalesDeed(filterRequest);
};

export const totalMutationStatus = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (
          parcel.mutationStatus &&
          parcel.mutationStatus.toUpperCase() === "YES"
        ) {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalPendingMutationStatus = (filterRequest: any) => {
  /* let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (parcel.mutationStatus.toUpperCase() !== "YES") {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea; */
  return totalSalesDeed(filterRequest) - totalMutationStatus(filterRequest);
};

export const totalNAStatus = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (parcel.naStatus && parcel.naStatus.toUpperCase() === "YES") {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalPendingNAStatus = (filterRequest: any) => {
  /* let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (parcel.naStatus.toUpperCase() !== "YES") {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea; */
  return totalMutationStatus(filterRequest) - totalNAStatus(filterRequest);
};

export const totalTSRStatus = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (parcel.tsrStatus && parcel.tsrStatus.toUpperCase() === "YES") {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalFreeHoldArea = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        totalArea += Number(parcel.freeHoldArea);
      }
    });
  });
  return totalArea;
};

export const totalLeaseArea = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        totalArea += Number(parcel.leaseArea);
      }
    });
  });
  return totalArea;
};

// @ts-nocheck
import { JwtPayLoad } from "../shared/jwt.interface";
import { ROLES_PERMISSIONS } from "../shared/role.constant";
import { getUserDetails } from "./jwt";

export const checkPermission = (
  role: string,
  module: string,
  permission: string
) => {
  return ROLES_PERMISSIONS[role].permissions[module][permission];
};

export const validatePermission = (module: string, permission: string) => {
  const { user }: JwtPayLoad = getUserDetails();
  if (!checkPermission(user?.role, module, permission)) {
    if (user?.role === "le_data") {
      window.location.href = "gis";
    } else {
      window.location.href = "home";
    }
  }
};

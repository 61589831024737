import { useEffect, useState } from "react";

interface Props {
  width?: string;
  line: number;
}
export const LoadinSkeleton = ({ width, line }: Props) => {
  const [array, setArray] = useState<any>([]);

  useEffect(() => {
    for (let i = 0; i < line; i++) {
      array.push(i);
    }
    setArray(array);
  }, [array, line]);

  return (
    <>
      <div
        className="loader-skelton placeholder-glow"
        aria-hidden="true"
        style={{ width }}
      >
        {array.map((item: number) => {
          return <span className="tile placeholder"></span>;
        })}
      </div>
    </>
  );
};

// imports
import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { requestId } from "../../state/atom/request";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgressBar } from "react-bootstrap";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { toaster } from "../../state/atom/toaster";
import { LandType1, Proposal_Type } from "../../shared/generic.constant";
import Select from "react-select";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { updateAggregatorList } from "../../state/atom/aggregator";
import {
  addProposalToggle,
  kmlData,
  updateProposalList,
} from "../../state/atom/proposal";
import { mapCenter } from "../../state/atom/generic";
import { Land, ProposalType } from "../../shared/generic.interface";
import { kml } from "@tmcw/togeojson";
import { InfoBox } from "../info-hover";
import JSZip from "jszip";
import { customStyles } from "../../shared/landTypeStyle";
import { Link } from "react-router-dom";

// interfaces
interface ProposalFromInput {
  requestId?: string;
  proposalName: string;
  proposalType: string;
  aggregatorId: string;
  offeredArea?: number;
  landType: Array<{ value: string; label: string }>;
  noOfPropertyOwner?: number;
  location?: string;
  locationLong?: number;
  locationLat?: number;
  offeredRate?: number;
  otherDetails?: string;
  comment?: string;
  isInline: boolean;
  inLineComment?: string;
  isDeviated: boolean;
  deviatedComment?: string;
  distanceFromRoad?: number;
  distanceFromRail?: number;
  distanceFromCity?: number;
  locationFile: string;
  circleRateFile?: string;
  circleRatePerAcre?: number;
  distanceFromAirport?: number;
  kmlData: any;
}

// Component displaying the proposal form.
export const ProposalAddForm = () => {
  const setMapCenter = useSetRecoilState(mapCenter);
  // Validation schema
  const validationSchema = Yup.object().shape({
    proposalName: Yup.string().required("Proposal Name is required"),
    proposalType: Yup.string().required("Proposal Type is required"),
    aggregatorId: Yup.string().when("proposalType", {
      is: (val: string) => val === "aggergator",
      then: (validationSchema) =>
        validationSchema.required("Aggregator ID is required"),
      otherwise: (validationSchema) => validationSchema.notRequired(),
    }),
    landType: Yup.array()
      .min(1, "Please select at least one land type")
      .required("Type Of Land is required"),
    isInline: Yup.boolean().optional(),
    inLineComment: Yup.string().test(
      "Inline",
      "Inline Comment is required",
      function (value) {
        return !this.parent.isInline || !!value;
      }
    ),
    isDeviated: Yup.boolean().optional(),
    deviatedComment: Yup.string().test(
      "Deviated",
      "Deviation Comment is required",
      function (value) {
        return !this.parent.isDeviated || !!value;
      }
    ),
    /* locationFile: Yup.mixed().test(
      "fileRequired",
      "KMZ file is required",
      (value: any) => {
        return value && value.length > 0;
      }
    ), */
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    clearErrors,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<ProposalFromInput>({
    resolver: yupResolver(validationSchema),
  });

  // states and hooks
  const [propAddToggle, setPropAddToggle] = useRecoilState(addProposalToggle);
  const watchProposalType = watch("proposalType");
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const setToasterStatus = useSetRecoilState(toaster);
  const reqId = useRecoilValue(requestId);
  const [locationFile, setLocationFile] = useState("");
  const [circleRateFile, setCircleRateFile] = useState("");
  const [viewLocationFile, setViewLocationFile] = useState("");
  const [viewCircleFile, setViewCircleFile] = useState("");
  const setKmlData = useSetRecoilState(kmlData);
  const [aggregator, setAggregator] = useState([
    { id: 0, firstName: "", lastName: "" },
  ]);
  const [kmzUploadProgress, setKMZUploadProgress] = useState(false);
  const [circleRateUploadProgress, setCircleRateKMZUploadProgress] =
    useState(false);
  const setUpdateProposalList = useSetRecoilState(updateProposalList);
  const [updateAggregatorDropDown, setUpdateAggregatorDropDown] =
    useRecoilState(updateAggregatorList);

  useEffect(() => {
    if (watchProposalType === "aggregator") {
      // Make GET request for get the all aggregator
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}aggregator`,
        null,
        apiHeaders()
      )
        .then((result: any) => {
          setAggregator(result.data.aggregator);
          setUpdateAggregatorDropDown(false);
        })
        .catch((error: any) => {});
    }
  }, [
    watchProposalType,
    updateAggregatorDropDown,
    setUpdateAggregatorDropDown,
  ]);

  /**
  Handles the form submission when the user clicks the submit button.
  This function performs an API call to submit the land proposal .
  @param {ProposalFromInput} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: ProposalFromInput) => {
    setLoading(true);
    data.locationFile = locationFile || "";
    data.circleRateFile = circleRateFile || "";
    data.requestId = reqId;
    const modifiedTypeOfLand = data?.landType?.map((item: any) => {
      return item.value;
    });
    data.landType = modifiedTypeOfLand;

    const cleanedData = removeEmptyValues(data);

    if (cleanedData.distanceFromCity) {
      cleanedData.distanceFromCity = Number(cleanedData.distanceFromCity);
    }

    if (cleanedData.distanceFromRail) {
      cleanedData.distanceFromRail = Number(cleanedData.distanceFromRail);
    }

    if (cleanedData.distanceFromAirport) {
      cleanedData.distanceFromAirport = Number(cleanedData.distanceFromAirport);
    }

    if (cleanedData.distanceFromRoad) {
      cleanedData.distanceFromRoad = Number(cleanedData.distanceFromRoad);
    }

    if (cleanedData.offeredRate) {
      cleanedData.offeredRate = Number(cleanedData.offeredRate);
    }

    if (cleanedData.locationLat) {
      cleanedData.locationLat = Number(cleanedData.locationLat);
    }

    if (cleanedData.locationLong) {
      cleanedData.locationLong = Number(cleanedData.locationLong);
    }

    if (cleanedData.noOfPropertyOwner) {
      cleanedData.noOfPropertyOwner = Number(cleanedData.noOfPropertyOwner);
    }

    if (cleanedData.offeredArea) {
      cleanedData.offeredArea = Number(cleanedData.offeredArea);
    }
    if (cleanedData.circleRatePerAcre) {
      cleanedData.circleRatePerAcre = Number(cleanedData.circleRatePerAcre);
    }

    if (!cleanedData.hasOwnProperty("isInline")) {
      cleanedData.isInline = false;
    }

    if (cleanedData.isInline === false && "inLineComment" in cleanedData) {
      delete cleanedData["inLineComment"];
    }

    if (!cleanedData.hasOwnProperty("isDeviated")) {
      cleanedData.isDeviated = false;
    }

    if (cleanedData.isDeviated === false && "deviatedComment" in cleanedData) {
      delete cleanedData["deviatedComment"];
    }

    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}proposal`,
      cleanedData,
      apiHeaders()
    )
      .then((result: any) => {
        setUpdateProposalList(true);
        reset();
        setViewCircleFile("");
        setViewLocationFile("");
        setCircleRateFile("");
        setLocationFile("");
        setPropAddToggle(false);
        reset({ landType: [] });
        setToasterStatus({
          status: true,
          title: "Proposal",
          text: "Proposal created successfully",
        });
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
      });
    setLoading(false);
  };

  const removeEmptyValues = (obj: any) => {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (
        newObj[key] === "" ||
        newObj[key] === null ||
        newObj[key] === undefined
      ) {
        delete newObj[key];
      }
    }
    return newObj;
  };

  // Handle location file change
  const onLocationFileChange = async (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    let kmlData: any;
    const fileNameArray = event.target.files[0].name.split(".");
    const fileType = fileNameArray[fileNameArray.length - 1];
    if (fileType === "kmz") {
      kmlData = await convertKmzToJson(event.target.files[0]);
    } else {
      kmlData = await convertKMLToJson(event.target.files[0]);
    }

    // const kmlData: any = await convertKMLToJson(event.target.files[0]);
    if (kmlData) {
      console.log("kmlData==>", kmlData);
      setKmlData(kmlData);
      const lon =
        kmlData.features[0].geometry.coordinates.length === 1
          ? kmlData.features[0].geometry.coordinates[0][0][0]
          : kmlData.features[0].geometry.coordinates[0];
      const lat =
        kmlData.features[0].geometry.coordinates.length === 1
          ? kmlData.features[0].geometry.coordinates[0][0][1]
          : kmlData.features[0].geometry.coordinates[1];

      setMapCenter({
        zoom: 12,
        isShow: false,
        center: [lon, lat],
      });
      setValue("kmlData", kmlData);
    }

    setKMZUploadProgress(true);
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/proposal/location`,
      formData
    )
      .then((result: any) => {
        setLocationFile(result.fileName);
        setViewLocationFile(result.url);
        setKMZUploadProgress(false);
      })
      .catch((error: any) => {
        setKMZUploadProgress(false);
      });
  };

  const convertKMLToJson = (kmlFile: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const kmlText = event.target.result;
        const kmlDOM = new DOMParser().parseFromString(kmlText, "text/xml");
        const geoJSON = kml(kmlDOM);
        resolve(geoJSON);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(kmlFile);
    });
  };

  const convertKmzToJson = async (kmzFile: any) => {
    console.log("Hello::");
    const zip = new JSZip();
    try {
      const kmzData = await zip.loadAsync(kmzFile);
      const kmlData = await kmzData.file(/.*\.kml$/i)[0].async("text");
      const kmlDOM = new DOMParser().parseFromString(kmlData, "text/xml");
      const geoJSON = kml(kmlDOM);
      return geoJSON;
    } catch (error) {
      console.error("Error reading KMZ file:", error);
    }
  };

  // Handle Circle file change
  const onCircleFileChange = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setCircleRateKMZUploadProgress(true);
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/proposal/circle-rate`,
      formData
    )
      .then((result: any) => {
        setCircleRateFile(result.fileName);
        setViewCircleFile(result.url);
        setCircleRateKMZUploadProgress(false);
      })
      .catch((error: any) => {
        setCircleRateKMZUploadProgress(false);
      });
  };

  // Handle the on wheel change
  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
  };

  const isDeviated = watch("isDeviated");
  const isInline = watch("isInline");

  return (
    <div className="box">
      <div
        className={
          "new-proposal-init new-proposal-popup align-self-end " +
          (!propAddToggle ? "new-proposal-init--collapsed" : "")
        }
      >
        <div
          className="text-left panel-title sticky-heading"
          style={{ background: "#375db1" }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "15px" }}
          >
            Add Proposal{" "}
          </span>
          <span
            className="close-btn"
            id="new-proposal-close"
            onClick={() => {
              setPropAddToggle(false);
              clearErrors();
            }}
          >
            &times;
          </span>
        </div>
        <div style={{ overflowY: "auto" }}>
          <h6 className="mb-0 panel-header">
            Fill all the details for new proposal.
          </h6>
          {submitErrorMessage && (
            <div className="text-center">
              <span className="text-warning">{submitErrorMessage}</span>
            </div>
          )}

          <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
            {/* Proposal Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Proposal Name*</label>
              <input
                type="text"
                className="form-control"
                id="proposalName"
                placeholder="for example ADP-30MV-IN-AHM"
                {...register("proposalName")}
              />

              {errors.proposalName && (
                <span className="text-warning">
                  {errors.proposalName.message}
                </span>
              )}
            </div>

            {/* Proposal Type */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputLocation">Proposal Type*</label>
              <select
                className="form-select"
                aria-label="Default Select Proposal Type"
                {...register("proposalType")}
              >
                <option value="">---Please Select Proposal Type---</option>
                {Object.keys(Proposal_Type).map((unit, index) => {
                  return (
                    <option key={unit} value={unit}>
                      {Proposal_Type[unit as keyof ProposalType]}
                    </option>
                  );
                })}
              </select>

              {errors.proposalType && (
                <span className="text-warning">
                  {errors.proposalType.message}
                </span>
              )}
            </div>

            {/* Aggregator Id */}
            {watchProposalType === "aggregator" ? (
              <div className="form-group px-3 py-2">
                <label htmlFor="inputAggregatorId">Aggregator Name*</label>
                <select
                  className="form-select"
                  aria-label="Default select aggregator"
                  {...register("aggregatorId")}
                >
                  <option value="">---Please Select Aggregator---</option>
                  {aggregator?.map((aggregator, index) => {
                    return (
                      <option key={aggregator.id} value={aggregator?.id}>
                        {aggregator?.firstName} {aggregator?.lastName}
                      </option>
                    );
                  })}
                </select>

                {errors.aggregatorId && (
                  <span className="text-warning">
                    {errors.aggregatorId.message}
                  </span>
                )}
              </div>
            ) : null}

            {/* Offered Area */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputLatitude">Offered Area</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="offeredArea"
                placeholder="for example 75"
                onWheel={numberInputOnWheelPreventChange}
                {...register("offeredArea")}
              />

              {errors.offeredArea && (
                <span className="text-warning">
                  {errors.offeredArea.message}
                </span>
              )}
            </div>

            {/* Type Of Land */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputTLand">Type Of Land*</label>

              <Controller
                name="landType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={LandType1}
                    className="basic-multi-select"
                    placeholder="Select Land Type"
                    classNamePrefix="select"
                    styles={customStyles}
                  />
                )}
              />

              {errors.landType && (
                <span className="text-warning">{errors.landType.message}</span>
              )}
            </div>

            {/* No Of Property Owner */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputLatitude">No Of Property Owner</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="inputLatitude"
                placeholder="for example 7"
                onWheel={numberInputOnWheelPreventChange}
                {...register("noOfPropertyOwner")}
              />

              {errors.noOfPropertyOwner && (
                <span className="text-warning">
                  {errors.noOfPropertyOwner.message}
                </span>
              )}
            </div>

            {/* Location */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputProposed">Location</label>
              <input
                type="text"
                className="form-control"
                id="location"
                placeholder="for example Survey number-285, Bhadaj, Ahmedabad"
                {...register("location")}
              />

              {errors.location && (
                <span className="text-warning">{errors.location.message}</span>
              )}
            </div>

            {/* Offered Rate(Per Acre) */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Offered Rate(Lakhs per acre)</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="offeredRate"
                placeholder="for example 285"
                onWheel={numberInputOnWheelPreventChange}
                {...register("offeredRate")}
              />

              {errors.offeredRate && (
                <span className="text-warning">
                  {errors.offeredRate.message}
                </span>
              )}
            </div>

            {/* Circle Rate Per acre */}
            <div className="form-group px-3 py-2">
              <label htmlFor="distanceFromRail">Circle Rate (Lakhs per acre)</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="circleRatePerAcre"
                placeholder="for example 28,000"
                onWheel={numberInputOnWheelPreventChange}
                {...register("circleRatePerAcre")}
              />

              {errors.circleRatePerAcre && (
                <span className="text-warning">
                  {errors.circleRatePerAcre.message}
                </span>
              )}
            </div>

            {/* Circle Rate */}
            <div className="form-group px-3 py-2">
              <label htmlFor="circleRateFile" className="form-label">
                Circle Rate
              </label>
              &nbsp;
              <InfoBox
                data={"Circle Rate DOC (PDF) will show the Circle Rate DOC."}
              ></InfoBox>
              {viewCircleFile && (
                <>
                  <br></br>
                  <Link
                    target="_blank"
                    title="View File"
                    style={{ color: "#fff", textDecoration: "none" }}
                    to={viewCircleFile}
                    className="view-file"
                  >
                    <i className="bi bi-pencil-square"></i> &nbsp;View File
                  </Link>
                  &nbsp;
                </>
              )}
              {viewCircleFile && (
                <>
                  <br></br>
                  <span
                    onClick={() => {
                      setViewCircleFile("");
                      setCircleRateFile("");
                      setValue("circleRateFile", "");
                    }}
                    className="a-link"
                  >
                    &nbsp;<i className="bi bi-folder-x"></i>&nbsp; Remove File
                  </span>
                  <br></br>
                </>
              )}
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="circleRateFile"
                {...register("circleRateFile")}
                onChange={onCircleFileChange}
              />
              {errors.circleRateFile && (
                <span className="text-warning">
                  {errors.circleRateFile.message}
                </span>
              )}
              {circleRateUploadProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* Other Details */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputProposed">Other Details</label>
              <input
                type="text"
                className="form-control"
                id="otherDetails"
                placeholder="for example Relevant details of land and ownership"
                {...register("otherDetails")}
              />

              {errors.otherDetails && (
                <span className="text-warning">
                  {errors.otherDetails.message}
                </span>
              )}
            </div>

            {/* Comment */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputProposed">Comment</label>
              <input
                type="text"
                className="form-control"
                id="commentOnLand"
                placeholder="for example The land is suited for the project"
                {...register("comment")}
              />

              {errors.comment && (
                <span className="text-warning">{errors.comment.message}</span>
              )}
            </div>

            {/* In line with requirement */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <Controller
                  name="isInline"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="checkbox"
                      id="isInline"
                      className="form-check-input"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
                &nbsp; In line with requirement
              </label>
              &nbsp;
              <InfoBox
                data={
                  "In line with requirement will show the In line with requirement."
                }
              ></InfoBox>
            </div>

            {/* In line requirement comment */}
            {isInline && (
              <div className="form-group px-3 py-2">
                <label htmlFor="inputLineComment">
                  In line requirement comment*
                </label>
                <Controller
                  name="inLineComment"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      id="inLineComment"
                      placeholder="Land is in line with requirements"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />

                {errors.inLineComment && (
                  <span className="text-warning">
                    {errors.inLineComment.message}
                  </span>
                )}
              </div>
            )}

            {/* Deviation from requirement */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <Controller
                  name="isDeviated"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="checkbox"
                      id="isDeviated"
                      className="form-check-input"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
                &nbsp; Deviation from requirement
              </label>
              &nbsp;
              <InfoBox
                data={
                  "Deviation from requirement will show the Deviation from requirement."
                }
              ></InfoBox>
            </div>

            {/* Deviation from requirement comment */}
            {isDeviated && (
              <div className="form-group px-3 py-2">
                <label htmlFor="inputDeviatedComment">
                  Deviation from requirement comment*
                </label>
                <Controller
                  name="deviatedComment"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      id="deviatedComment"
                      placeholder="Land deviates from requirements"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />

                {errors.deviatedComment && (
                  <span className="text-warning">
                    {errors.deviatedComment.message}
                  </span>
                )}
              </div>
            )}

            {/* Distance from road(In KM) */}
            <div className="form-group px-3 py-2">
              <label htmlFor="distanceFromRoad">
                Distance from road (In KM)
              </label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="distanceFromRoad"
                placeholder="for example 3"
                onWheel={numberInputOnWheelPreventChange}
                {...register("distanceFromRoad")}
              />

              {errors.distanceFromRoad && (
                <span className="text-warning">
                  {errors.distanceFromRoad.message}
                </span>
              )}
            </div>

            {/* Distance from rail(In KM) */}
            <div className="form-group px-3 py-2">
              <label htmlFor="distanceFromRail">
                Distance from rail (In KM)
              </label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="distanceFromRail"
                placeholder="for example 5"
                onWheel={numberInputOnWheelPreventChange}
                {...register("distanceFromRail")}
              />

              {errors.distanceFromRail && (
                <span className="text-warning">
                  {errors.distanceFromRail.message}
                </span>
              )}
            </div>

            {/* Distance from city (In KM) */}
            <div className="form-group px-3 py-2">
              <label htmlFor="distanceFromCity">
                Distance from city (In KM)
              </label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="distanceFromCity"
                placeholder="for example 6"
                onWheel={numberInputOnWheelPreventChange}
                {...register("distanceFromCity")}
              />

              {errors.distanceFromCity && (
                <span className="text-warning">
                  {errors.distanceFromCity.message}
                </span>
              )}
            </div>

            {/* Distance from Airport (In KM) */}
            <div className="form-group px-3 py-2">
              <label htmlFor="distanceFromCity">
                Distance from Airport (In KM)
              </label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="distanceFromAirport"
                placeholder="for example 6"
                onWheel={numberInputOnWheelPreventChange}
                {...register("distanceFromAirport")}
              />

              {errors.distanceFromAirport && (
                <span className="text-warning">
                  {errors.distanceFromAirport.message}
                </span>
              )}
            </div>

            {/* Latitude */}
            <div className="form-group px-3 py-2 lat-lon">
              <div className="col-6">
                <label htmlFor="inputLatitude">Latitude</label>
                <input
                  type="number"
                  step="any"
                  className="form-control"
                  id="locationLat"
                  placeholder="Ex. 23.1378"
                  onWheel={numberInputOnWheelPreventChange}
                  {...register("locationLat")}
                />

                {errors.locationLat && (
                  <span className="text-warning">
                    {errors.locationLat.message}
                  </span>
                )}
              </div>
              &nbsp;
              <div className="col-6">
                <label htmlFor="longitude">Longitude</label>
                <input
                  type="number"
                  step="any"
                  className="form-control"
                  id="locationLong"
                  placeholder="Ex. 72.5437"
                  onWheel={numberInputOnWheelPreventChange}
                  {...register("locationLong")}
                />

                {errors.locationLong && (
                  <span className="text-warning">
                    {errors.locationLong.message}
                  </span>
                )}
              </div>
            </div>

            <div className="w-100 text-end">
              <span
                className="btn btn-dark mb-0 me-2"
                onClick={() => {
                  if (getValues("locationLong") && getValues("locationLat")) {
                    setMapCenter({
                      zoom: 17,
                      center: [
                        getValues("locationLong") || 0,
                        getValues("locationLat") || 0,
                      ],
                      isShow: true,
                    });
                  }
                }}
              >
                <i className="material-icons text-lg align-middle">
                  add_location
                </i>
                Zoom To
              </span>
            </div>

            {/* KMZ File */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                KML OR KMZ File
              </label>
              &nbsp;
              <InfoBox data={"KML File will show the KML File."}></InfoBox>
              {viewLocationFile && (
                <>
                  <br></br>
                  <Link
                    target="_blank"
                    title="View File"
                    style={{ color: "#fff", textDecoration: "none" }}
                    to={viewLocationFile}
                    className="view-file"
                  >
                    <i className="bi bi-pencil-square"></i> &nbsp;View File
                  </Link>
                  &nbsp;
                </>
              )}
              {viewLocationFile && (
                <>
                  <br></br>
                  <span
                    onClick={() => {
                      setViewLocationFile("");
                      setLocationFile("");
                      setValue("locationFile", "");
                    }}
                    className="a-link"
                  >
                    &nbsp;<i className="bi bi-folder-x"></i>&nbsp; Remove File
                  </span>
                  <br></br>
                </>
              )}
              <input
                className="form-control"
                type="file"
                accept=".kml,.kmz"
                id="locationFileProposal"
                {...register("locationFile")}
                onChange={onLocationFileChange}
              />
              {errors.locationFile && (
                <span className="text-warning">
                  {errors.locationFile.message}
                </span>
              )}
              {kmzUploadProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* Submit Button */}
            <div className="form-group px-3 py-2">
              <button
                type="submit"
                className="btn bg-gradient-primary-login w-100 my-4 mb-2 btn1"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "SUBMIT PROPOSAL"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

import Modal from "react-bootstrap/Modal";
import { useRecoilState, useSetRecoilState } from "recoil";
import { addStateModalToggle } from "../../../../state/atom/settings";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Http } from "../../../../utility/http";
import { apiHeaders } from "../../../../utility/generic";
import { useState } from "react";
import { toaster } from "../../../../state/atom/toaster";

type AddStateForm = {
  stateName: string;
  stateCode: string;
  lat: number;
  lon: number;
};

export const AddStateModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useRecoilState(addStateModalToggle);
  const setToasterStatus = useSetRecoilState(toaster);

  const addStateValidationSchema = Yup.object().shape({
    stateName: Yup.string().required("State name is required."),
    stateCode: Yup.string().required("State code is required."),
    lat: Yup.number()
      .typeError("Latitude must be a number.")
      .required("Latitude is required."),
    lon: Yup.number()
      .typeError("Longitude must be a number.")
      .required("Longitude is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddStateForm>({
    resolver: yupResolver(addStateValidationSchema),
  });

  const onSubmitHandler = async (data: AddStateForm) => {
    setLoading(true);
    await Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}states`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: "State Added.",
          text: result.message,
        });
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNumericInput = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9.-]/g, "");
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header
        className="panel-title text-white"
        style={{ marginBottom: 0 }}
        closeButton
        closeVariant="white"
      >
        <Modal.Title>Add State</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {submitErrorMessage && (
            <div className="text-center py-1">
              <span className="text-danger">{submitErrorMessage}</span>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="d-flex flex-column">
              {/* State Name */}
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label
                    className="form-label"
                    htmlFor="inputStype"
                    style={{ color: "black" }}
                  >
                    State Name
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    errors.stateName ? "is-invalid" : ""
                  }`}
                  id="stateName"
                  placeholder="GUJARAT"
                  {...register("stateName")}
                />
                {errors.stateName && (
                  <span className="text-danger">
                    {errors.stateName.message}
                  </span>
                )}
              </div>
              {/* State Code */}
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label
                    className="form-label"
                    htmlFor="inputStype"
                    style={{ color: "black" }}
                  >
                    State Code
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    errors.stateCode ? "is-invalid" : ""
                  }`}
                  id="stateCode"
                  placeholder="GJ"
                  {...register("stateCode")}
                />
                {errors.stateCode && (
                  <span className="text-danger">
                    {errors.stateCode.message}
                  </span>
                )}
              </div>
              {/* Latitude */}
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label
                    className="form-label"
                    htmlFor="inputStype"
                    style={{ color: "black" }}
                  >
                    Latitude
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${errors.lat ? "is-invalid" : ""}`}
                  id="latitude"
                  placeholder="34.56789"
                  onInput={handleNumericInput}
                  {...register("lat")}
                />

                {errors.lat && (
                  <span className="text-danger">{errors.lat.message}</span>
                )}
              </div>
              {/* Longitude */}
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label
                    className="form-label"
                    htmlFor="inputStype"
                    style={{ color: "black" }}
                  >
                    Longitude
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${errors.lon ? "is-invalid" : ""}`}
                  id="longitude"
                  placeholder="98.45678"
                  onInput={handleNumericInput}
                  {...register("lon")}
                />

                {errors.lon && (
                  <span className="text-danger">{errors.lon.message}</span>
                )}
              </div>
            </div>
            <div className="form-group px-3 py-2">
              <button
                type="submit"
                className="btn bg-gradient-primary-login btn1 w-100"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Add State
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

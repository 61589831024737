export const customStyles = {
    option: (provided:any, state:any) => ({
      ...provided,
      color: "black",
    }),
    multiValue: (provided:any) => ({
      ...provided,
      color:'white',
      backgroundImage: 'linear-gradient(195deg, #ab2e6e 0%, #0070b4 100%)',
      borderRadius : '10px'
    }),
    multiValueLabel: (provided:any) => ({
      ...provided,
      color: "white",
    }),
  }
import { ReactNode } from "react";
import { Header } from "../component/header";

interface Props {
  children?: ReactNode;
}
export const MainLayout = ({ children, ...props }: Props) => {
  return (
    <>
      <Header></Header>
      {children}
    </>
  );
};

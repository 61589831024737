import { atom } from "recoil";

export const engReportToggle = atom({
  key: "engReportToggle",
  default: false,
});

export const viewEngReportToggle = atom({
  key: "viewEngReportToggle",
  default: false,
});

export const editEngReportListToggle = atom({
  key: "editEngReportListToggle",
  default: false,
});
export const editEngReportListID = atom({
  key: "editEngReportListID",
  default: '',
});

export const engReportDetailsToggle = atom({
  key: "engReportDetailsToggle",
  default: false,
});
import { useRecoilState, useSetRecoilState } from "recoil";
import { Business, Company, State } from "../../shared/generic.interface";
import { requestId, requestList } from "../../state/atom/request";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  gisFeatureLayerFilter,
  gisFilterToggle,
  gisList,
  landSummary,
  landSummaryToggle,
  siteFilterState,
} from "../../state/atom/gis";
import { Link } from "react-router-dom";
import { getLandSummary } from "../../shared/gis";
import { mapCenter } from "../../state/atom/generic";
import { getUserDetails } from "../../utility/jwt";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { ROLES } from "../../shared/role.constant";
interface MisFilterFormInputs {
  businessName: string;
  companyName: string;
  state: string;
  district: string;
  city: string;
  village: string;
  site: string;
  surveyNo: string;
}
export const GisFilter = () => {
  const { user }: JwtPayLoad = getUserDetails();
  const [request, setRequestList] = useRecoilState(requestList);
  const setRequestId = useSetRecoilState(requestId);
  let [gisFeatureLayerFilterData, setGisFeatureLayerFilter] = useRecoilState(
    gisFeatureLayerFilter
  );
  const setLandSummary = useSetRecoilState(landSummary);
  const setLandSummaryToggle = useSetRecoilState(landSummaryToggle);
  const [gis, setGisList] = useRecoilState(gisList);
  const [gisFilterData, setGisFilterData] = useState<any>([]);
  const [gisFilterDataClone, setGisFilterDataClone] = useState<any>([]);
  const setmapCenter = useSetRecoilState(mapCenter);
  const [gisClone, setGisClone] = useState<any>({});
  const [states, setState] = useState<State[]>([]);
  const [surveys, setSurvey] = useState<any[]>([]);
  const [villages, setVillage] = useState<any[]>([]);
  const [sites, setSite] = useState<State[]>([]);
  const [siteFilter, setSiteFilter] = useRecoilState(siteFilterState);
  const [business, setBusiness] = useState<Business[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const isStateUpdated = useRef(false);
  const [gisFltrToggle, setGisFilterToggle] = useRecoilState(gisFilterToggle);
  const validationSchema = Yup.object().shape({
    businessName: Yup.string().notRequired(),
    companyName: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    village: Yup.string().notRequired(),
    site: Yup.string().notRequired(),
    surveyNo: Yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<MisFilterFormInputs>({
    resolver: yupResolver(validationSchema),
  });
  const businessName = watch("businessName");
  const companyName = watch("companyName");
  const state = watch("state");
  const site = watch("site");
  const surveyNo = watch("surveyNo");
  const village = watch("village");
  useEffect(() => {
    if (
      !isStateUpdated.current &&
      gis?.data?.request.length &&
      request?.data?.landRequest.length
    ) {
      setGisClone({ ...gis });
      const landSummary = getLandSummary(gis.data.request);
      setLandSummary(landSummary);
      isStateUpdated.current = true;
    }
  }, [gis, request, isStateUpdated, setLandSummary, setRequestId]);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-parcel/filter-data`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        setGisFilterData(result.data.request);
        setGisFilterDataClone(result.data.request);
        createFilterData(result.data.request);
      })
      .catch((error: any) => {});
  }, [setGisFilterData]);

  const onSubmit = (data: MisFilterFormInputs) => {
    let filterRequest: any = JSON.parse(JSON.stringify(gisClone.data.request));
    if (data.businessName) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return Number(parcel.businessName) === Number(data.businessName);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.state) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return Number(parcel.state) === Number(data.state);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
      const state = states.find((state) => state.id === Number(data.state));
      if (state) {
        setmapCenter({
          zoom: 12,
          center: [state.lon, state.lat],
          isShow: false,
        });
      }
    }

    if (data.companyName) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return Number(parcel.companyName) === Number(data.companyName);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.site) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return parcel.site === data.site;
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.surveyNo) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return parcel.surveyNo === data.surveyNo;
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    setGisFeatureLayerFilter(gisFeatureLayerFilterData);
    const landSummary = getLandSummary(filterRequest);
    setLandSummary(landSummary);
    setGisList({
      data: {
        request: filterRequest,
      },
    });
  };

  const filterData = (e: any, field: string) => {
    let filterData: any = gisFilterDataClone;
    if (e.target.value) {
      if (field === "business") {
        filterData = filterData.filter((request: any) => {
          return Number(request.businessName) === Number(e.target.value);
        });
        gisFeatureLayerFilterData.BusinessName = e.target.value;
        createFilterData(filterData, "business");
        setValue("businessName", e.target.value);
      }

      if (field === "company") {
        filterData = filterData.filter((request: any) => {
          return Number(request.companyName) === Number(e.target.value);
        });
        gisFeatureLayerFilterData.CompanyName = e.target.value;
        createFilterData(filterData, "company");
        setValue("companyName", e.target.value);
      }

      if (field === "state") {
        filterData = filterData.filter((request: any) => {
          return Number(request.state.id) === Number(e.target.value);
        });
        gisFeatureLayerFilterData.State = e.target.value;
        createFilterData(filterData, "state");
        setValue("state", e.target.value);
      }

      if (field === "site") {
        filterData = filterData.filter((request: any) => {
          return request.site === e.target.value;
        });
        gisFeatureLayerFilterData.Site = e.target.value;
        createFilterData(filterData, "site");
        setValue("site", e.target.value);
      }

      if (field === "survey") {
        filterData = filterData.filter((request: any) => {
          return request.surveyNo === e.target.value;
        });
        gisFeatureLayerFilterData.SurveyNo = e.target.value;
        createFilterData(filterData, "survey");
        setValue("surveyNo", e.target.value);
      }

      if (field === "village") {
        filterData = filterData.filter((request: any) => {
          return request.village === e.target.value;
        });
        gisFeatureLayerFilterData.Village = e.target.value;
        createFilterData(filterData, "village");
        setValue("village", e.target.value);
      }
      setGisFilterData(filterData);
    }
  };

  const createFilterData = (data: any, skip: string = "") => {
    const businessData = [];
    if (skip !== "business") {
      for (const parcel of data) {
        if (parcel.business) {
          const index = businessData.findIndex(
            (business) => business.id === parcel.business.id
          );
          if (index === -1) {
            businessData.push(parcel.business);
          }
        }
      }
      setBusiness(businessData);
    }
    if (skip !== "company") {
      const companies = [];
      for (const parcel of data) {
        if (parcel.company) {
          const index = companies.findIndex(
            (company) => company.id === parcel.company.id
          );
          if (index === -1) {
            companies.push(parcel.company);
          }
        }
      }
      setCompanies(companies);
    }

    if (skip !== "state") {
      const states = [];
      for (const parcel of data) {
        if (parcel.state) {
          const index = states.findIndex(
            (state) => state.id === parcel.state.id
          );
          if (index === -1) {
            states.push(parcel.state);
          }
        }
      }
      setState(states);
    }

    if (skip !== "site") {
      const sites = [];
      for (const parcel of data) {
        const index = sites.findIndex((site) => site === parcel.site);
        if (index === -1) {
          sites.push(parcel.site);
        }
      }
      setSite(sites);
    }

    if (skip !== "survey") {
      const surveys = [];
      for (const parcel of data) {
        const index = surveys.findIndex((survey) => survey === parcel.surveyNo);
        if (index === -1) {
          surveys.push(parcel.surveyNo);
        }
      }
      setSurvey(surveys);
    }

    if (skip !== "village") {
      const villages = [];
      for (const parcel of data) {
        const index = villages.findIndex(
          (village) => village === parcel.village
        );
        if (index === -1) {
          villages.push(parcel.village);
        }
      }
      setVillage(villages);
    }
  };

  useEffect(() => {
    const event = {
      target: {
        value: siteFilter,
      },
    };
    setValue("site", siteFilter);
    filterData(event, "site");
  }, [setValue, siteFilter]);

  return (
    <>
      <div className="box">
        <div
          className={
            "new-request-corner-btn gis-corner-btn align-self-end " +
            (!gisFltrToggle ? "new-request-corner-btn--collapsed" : "")
          }
          onClick={() => {
            setGisFilterToggle(true);
          }}
          id="new-request-corner-btn"
        >
          <Link
            to=""
            className="nav-link font-weight-bold d-flex text-white corner-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons p-1">
              GIS Filter&nbsp;
            </span>
          </Link>
        </div>
        <div
          className={
            "request-detail-init " +
            (!gisFltrToggle ? "request-detail-init--collapsed" : "")
          }
        >
          <div
            className="text-right panel-title sticky-heading"
            style={{ background: "#375db1" }}
          >
            <span style={{ color: "white", fontSize: "24px" }}>
              GIS Filters
            </span>
            <span
              className="close-btn"
              id="request-detail-close"
              onClick={() => setGisFilterToggle(!gisFltrToggle)}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "75vh" }}>
            <h6 className="mb-0 panel-header">
              Select the data for apply filter.
            </h6>
            <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group px-3 py-2">
                <label htmlFor="inputBusiness">Business Name</label>
                <select
                  className="form-select"
                  aria-label="Default Business Name"
                  {...register("businessName")}
                  onChange={(event) => filterData(event, "business")}
                  value={businessName}
                >
                  <option value="" selected>
                    ---Business Name ---
                  </option>
                  {business.map((unit: any, index: any) => {
                    return <option value={unit.id}>{unit.name}</option>;
                  })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label htmlFor="inputBusiness">Company Name</label>
                <select
                  className="form-select"
                  aria-label="Default Company Name"
                  {...register("companyName")}
                  onChange={(event) => filterData(event, "company")}
                  value={companyName}
                >
                  <option value="" selected>
                    ---Select Company Name---
                  </option>
                  {companies.map((company: any, index: any) => {
                    return <option value={company.id}>{company.name}</option>;
                  })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label htmlFor="select-state">Select State</label>
                <select
                  className="form-select"
                  {...register("state")}
                  onChange={(event) => filterData(event, "state")}
                  value={state}
                >
                  <option value="">---Please Select State---</option>
                  {states.map((state: any, index: any) => {
                    return <option value={state.id}>{state.stateName}</option>;
                  })}
                </select>
              </div>

              <div className="form-group px-3 py-2">
                <label htmlFor="select-city">Select Site</label>
                <select
                  className="form-control"
                  aria-label="Default City"
                  {...register("site")}
                  onChange={(event) => filterData(event, "site")}
                  value={site}
                >
                  <option value="" selected>
                    ---Select Site---
                  </option>
                  {sites.map((site: any, index: any) => {
                    return <option value={site}>{site}</option>;
                  })}
                </select>
              </div>

              <div className="form-group px-3 py-2">
                <label htmlFor="select-state">Village</label>
                <select
                  className="form-control"
                  aria-label="Default City"
                  {...register("village")}
                  onChange={(event) => filterData(event, "village")}
                  value={village}
                >
                  <option value="" selected>
                    ---Select Village---
                  </option>
                  {villages.map((village: any, index: any) => {
                    return <option value={village}>{village}</option>;
                  })}
                </select>
              </div>

              <div className="form-group px-3 py-2">
                <label htmlFor="select-survey-no">Select Survey No</label>
                <select
                  className="form-select"
                  aria-label="Default select-survey-no"
                  {...register("surveyNo")}
                  onChange={(event) => filterData(event, "survey")}
                  value={surveyNo}
                >
                  <option value="" selected>
                    ---Select Survey No---
                  </option>
                  {surveys.map((survey: any, index: any) => {
                    return <option value={survey}>{survey}</option>;
                  })}
                </select>
              </div>
              <div
                className="form-group d-flex  mt-3 mb-3"
                style={{ justifyContent: "space-evenly" }}
              >
                <button
                  type="button"
                  onClick={() => {
                    const landSummary = getLandSummary(gisClone.data.request);
                    setLandSummary(landSummary);
                    setLandSummaryToggle(false);
                    setGisList(gisClone);
                    setGisFilterData(gisFilterDataClone);
                    createFilterData(gisFilterDataClone);
                    setGisFeatureLayerFilter({
                      SurveyNo: "",
                      Village: "",
                      BusinessName: "",
                      CompanyName: "",
                      Site: "",
                      State: "",
                      Request_Id: "",
                      abu_or_lem_user_id:
                        user.role === ROLES.abu_spoc ? user.userId : "",
                      le_spoc_user_id:
                        user.role === ROLES.le_spoc ? user.userId : "",
                      le_dh_user_id:
                        user.role === ROLES.le_dh ? user.userId : "",
                    });
                    reset();
                  }}
                  className="btn bg-gradient-primary-login  btn1"
                >
                  Reset FILTER
                </button>
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1"
                >
                  APPLY FILTER
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

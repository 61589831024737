import { atom } from "recoil";

export const stateListState = atom({
  key: 'stateListState',
  default: []
})

//setting state id
export const stateIdState = atom({
  key: 'stateIdState',
  default: 0
})

//settings state of add,edit,delete toggle modals...
export const addStateModalToggle = atom({
  key: "addStateModalToggle",
  default: false,
});
export const editStateModalToggle = atom({
  key: "editStateModalToggle",
  default: false,
});
export const deleteStateModalToggle = atom({
  key: "deleteStateModalToggle",
  default: false,
});

//setting district id
export const districtIdState = atom({
  key: 'districtIdState',
  default: 0
})

//settings distrcit of add,edit,delete toggle modals...
export const addDistrictModalToggle = atom({
  key: "addDistrictModalToggle",
  default: false,
});
export const editDistrictModalToggle = atom({
  key: "editDistrictModalToggle",
  default: false,
});
export const deleteDistrictModalToggle = atom({
  key: "deleteDistrictModalToggle",
  default: false,
});


//settings city id
export const cityIdState = atom({
  key: 'cityIdState',
  default: 0
})

//settings city of add,edit,delete toggle modals...
export const addCityModalToggle = atom({
  key: "addCityModalToggle",
  default: false,
});
export const editCityModalToggle = atom({
  key: "editCityModalToggle",
  default: false,
});
export const deleteCityModalToggle = atom({
  key: "deleteCityModalToggle",
  default: false,
});

//settings business-unit id
export const businessUnitIdState = atom({
  key: 'businessUnitIdState',
  default: 0
})


//settings business-unit of add,edit,delete togggle modals...
export const addBusinessUnitModalToggle = atom({
  key: "addBusinessUnitModalToggle",
  default: false,
});
export const editBusinessUnitModalToggle = atom({
  key: "editBusinessUnitModalToggle",
  default: false,
});
export const deleteBusinessUnitModalToggle = atom({
  key: "deleteBusinessUnitModalToggle",
  default: false,
});

//settings company id
export const companyIdState = atom({
  key: 'companyIdState',
  default: 0
})


//settings company of add,edit,delete togggle modals...
export const addCompanyModalToggle = atom({
  key: "addCompanyModalToggle",
  default: false,
});
export const editCompanyModalToggle = atom({
  key: "editCompanyModalToggle",
  default: false,
});
export const deleteCompanyModalToggle = atom({
  key: "deleteCompanyModalToggle",
  default: false,
});

// imports
import { useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Link } from "react-router-dom";
import {
  usersListToggle,
  userId,
  editUserToggle,
  addUserToggle,
  userListClone,
  userList,
} from "../../state/atom/user";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { toaster } from "../../state/atom/toaster";
import { updateUserList } from "../../state/atom/user";
import { Modal, Button } from "react-bootstrap";
import { UserStatus } from "../../shared/user.constant";
import { IUserStatus, User } from "../../shared/user.interface";
import { listHighlighter } from "../../state/atom/listhighlighter";
import { ROLESNAME } from "../../shared/role.constant";
import { ROLES_TYPE } from "../../shared/role.interface";

// interfaces
interface Props {
  userss: any;
}

/**
Component displaying a list of users.
@param {any} userss - list of the all users
*/
const UserList = ({ userss }: Props) => {
  // state and hooks
  const [userListToggle, setUserListToggle] = useRecoilState(usersListToggle);
  const setUserEditToggle = useSetRecoilState(editUserToggle);
  const [userID, setUserID] = useRecoilState(userId);
  const [userClone, setUserListClone] = useRecoilState(userListClone);
  const setToasterStatus = useSetRecoilState(toaster);
  const setUpdateUsersList = useSetRecoilState(updateUserList);
  const setAddUserToggle = useSetRecoilState(addUserToggle);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState("");
  const [listHighLighter, setListHighLighter] = useRecoilState(listHighlighter);
  const [users, setUsers] = useRecoilState(userList);
  
  // handle for edit user
  const setUserEditData = (id: string) => {
    setUserEditToggle(true);
    setUserID(id);
  };

  // handle delete  user
  const handleDeleteUser = (id: string) => {
    setUserID(id);
    setSelectedUserID(id);
    setShowDeleteConfirmation(true);
  };

  // delete user confirmed
  const deleteConfirmed = () => {
    // Make the DELETE request for delete the user
    Http.request(
      "delete",
      `${process.env.REACT_APP_API_URL}users/${selectedUserID}`,
      null,
      apiHeaders()
    )
      .then((data) => {
        setListHighLighter(false);
        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "User Deleted",
          text: "User Deleted successfully",
        });

        // hide the user list
        setUserListToggle(false);

        // update the user list
        setUpdateUsersList(true);
      })
      .catch((err) => {});

    // hide the confirmation model
    setShowDeleteConfirmation(false);
  };

  // handle the checkbox for assign the dashboard access.
  const handleCheckboxUpdate = (isDashboardActive: boolean, id: string) => {
    const updatedValue = {
      dashboardAccess: !isDashboardActive,
    };

    // Make the PATCH request the assign the user
    Http.request(
      "PATCH",
      `${process.env.REACT_APP_API_URL}users/${id}`,
      updatedValue,
      apiHeaders()
    )
      .then((data) => {
        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Dashboard",
          text: "Dashboard access updated successfully",
        });

        // update the user list
        setUpdateUsersList(true);
      })
      .catch((err) => {});
  };

  // handle search user 
  const filterRequest = (e: any) => {
    let filteredRequest: any = userClone.data.user.filter(
      (req: User) => {
        return (
          req.firstName.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
            -1 ||
          req.lastName
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1 ||
          req.email
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1 /* ||
          req.businessUnitName.name
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1 */
        );
      }
    );
    setUsers({
      data: {
        user: filteredRequest,
      },
    });
  };

  // Add user button
  const handleAddUserFormToggle: any = () => {
    setAddUserToggle(true);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setUsers({
        data: {
          user: userClone.data.user,
        },
      });
    }
  };
  return (
    <div
      className={
        "user-mgt-init  " +
        (!userListToggle ? "request-list-init--collapsed" : "")
      }
    >
      {/* Heading panel */}
      <div className="text-right panel-title mb-0">
        <span style={{ color: "white", fontSize: "24px", paddingLeft: "20px" }}>
          Existing User Management
        </span>

        {/* Search box */}
        <div className="form-group has-search user-search-box">
          <span className="fa fa-search form-control-feedback"></span>
          
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            ref={inputRef}
            onChange={filterRequest}
          />
          <i className="bi bi-x-circle-fill cancle-icon" onClick={handleClearSearch}></i>
        </div>

        <span
          style={{
            paddingRight: "60px",
            paddingTop: "2px",
            paddingBottom: "2px",
          }}
        >
          <button
            onClick={() => {
              handleAddUserFormToggle();
              setUserListToggle(false);
              setUserEditToggle(false);
            }}
            type="button"
            className="btn btn-primary btn-xs"
          >
            Add User
          </button>
        </span>
        <span
          className="close-btn"
          id="user-mgt-close"
          onClick={() => setUserListToggle(false)}
        >
          &times;
        </span>
      </div>
      <div>
        <div className="table-responsive p-0" style={{ maxHeight: "230px" }}>
          {/* List of the user in table form */}
          <table className="table align-items-center justify-content-center mb-0 text-white">
            <thead
              style={{ background: "#6a294b", color: "white" }}
              className="table-head"
            >
              <tr>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  No
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  User Name
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  Email Id
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  Employee Id
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  Business
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  Role
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  Status
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody style={{ fontSize: "14px" }}>
              {users?.data?.user.map((item: any, index: number) => {
                return (
                  <tr
                    key={index}
                    className={
                      listHighLighter
                        ? userID === item.id
                          ? "selected-row text-white"
                          : ""
                        : ""
                    }
                  >
                    <td>
                      <div className="d-flex px-2">
                        <div className="my-auto">
                          <h6 className="mb-0 text-sm">{index + 1}</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="text-sm font-weight-bold mb-0">{`${item.firstName} ${item.lastName}`}</p>
                    </td>
                    <td>
                      <p className="text-sm font-weight-bold mb-0">
                        {item.email}
                      </p>
                    </td>
                    <td>
                      <p className="text-sm font-weight-bold mb-0">
                        {item.employeeId == null ? "-" : item.employeeId}
                      </p>
                    </td>
                    <td>
                      <p className="text-sm font-weight-bold mb-0">
                        <ul>
                        {item?.businessUnitName?.map((unit:any)=>{
                          return (
                            <li key={unit?.id}>{unit?.name}</li>
                          )
                        })}
                        </ul>
                      </p>
                    </td>

                    <td>
                      <p className="text-sm font-weight-bold mb-0">
                        {item.role == null
                          ? "-"
                          : Object.keys(ROLESNAME)
                              .filter((unit) => unit === item.role)
                              .map(
                                (unit) => ROLESNAME[unit as keyof ROLES_TYPE]
                              )}
                      </p>
                    </td>
                    <td>
                      <p className="text-sm font-weight-bold mb-0">
                        {
                          UserStatus[
                            item.verificationStatus as keyof IUserStatus
                          ]
                        }
                      </p>
                    </td>
                    <td>
                      <div className="ms-auto text-center">
                        <Link
                          className="fixed-plugin-button btn btn-link text-white text-gradient"
                          to=""
                          onClick={() => {
                            setAddUserToggle(false);
                            setUserEditData(item.id);
                            setListHighLighter(true);
                          }}
                        >
                          <i className="material-icons text-sm me-2">edit</i>
                        </Link>
                        <Link
                          className="fixed-plugin-button btn btn-link text-white text-gradient"
                          to=""
                          onClick={() => {
                            handleDeleteUser(item.id);
                            setListHighLighter(true);
                          }}
                        >
                          <i className="material-icons text-sm me-2">close</i>
                        </Link>
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck6"
                          checked={item.dashboardAccess}
                          onChange={() =>
                            handleCheckboxUpdate(item.dashboardAccess, item.id)
                          }
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck6"
                        >
                          Dashboard
                        </label>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete confirmation model */}
      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteConfirmed}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserList;

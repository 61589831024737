import { Link } from "react-router-dom";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import * as XLSX from "xlsx";
import fileDownload from "js-file-download";

export interface Props {
  id: string;
}
export const ExportEngReport = ({ id }: Props) => {
  const exportReport = () => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}eng-report/${id}`,
      null,
      apiHeaders()
    )
      .then((result) => {
        const requestExportData: any = [];
        let engReportObject: any = {};

        engReportObject = {};
        engReportObject["Near By Railway Station"] = result.data.engReport.nearByRailwayStation;
        engReportObject["Near By Airport"] = result.data.engReport.nearByAirport;
        engReportObject["Near By City"] = result.data.engReport.nearByCity;
        engReportObject["Site Location"] = result.data.engReport.siteLocation;
        engReportObject["Proposed Capacity"] = result.data.engReport.proposedCapacity;
        engReportObject["Proposed Area"] = result.data.engReport.proposedArea;
        engReportObject["Comment"] = result.data.engReport.comment;
        engReportObject["Status"] = result.data.engReport.status;
        engReportObject["Site Visit Date"] = result.data.engReport.siteVisitDate;
        engReportObject["Created Date Time"] = result.data.engReport.createdDateTime;
        engReportObject["Near By Highway"] = result.data.engReport.nearByHighway;
        engReportObject["Elevation From Msl"] = result.data.engReport.elevationFromMsl;
        engReportObject["Site Orientation"] = result.data.engReport.siteOrientation;
        engReportObject["Seismic Zone"] = result.data.engReport.seismicZone;
        engReportObject["Avg Annual Wind Speed"] = result.data.engReport.avgAnnualWindSpeed;
        engReportObject["Sasic Wind Speed Code"] = result.data.engReport.basicWindSpeedCode;
        engReportObject["Sind Zone"] = result.data.engReport.windZone;
        engReportObject["Annual Rain fall"] = result.data.engReport.annualRainfall;
        engReportObject["Near By Pooling Station"] = result.data.engReport.nearByPoolingStation;
        engReportObject["Land Type"] = result.data.engReport.landType;
        engReportObject["Soil Condition"] = result.data.engReport.soilCondition;
        engReportObject["Availability Of Water"] = result.data.engReport.availabilityOfWater;
        engReportObject["Vegetation"] = result.data.engReport.vegetation;
        engReportObject["Displacement Of Huts And People"] = result.data.engReport.displacementOfHutsAndPeople;
        engReportObject["Village Road Passing Though Land"] = result.data.engReport.villageRoadPassingThoughLand;
        engReportObject["Presence Of HtLt Line"] = result.data.engReport.presenceOfHtLtLine;
        engReportObject["Workshop Structure Or Access Road"] = result.data.engReport.workshopStructureOrAccessRoad;
        engReportObject["Is Land Adjacent To Village"] = result.data.engReport.isLandAdjacentToVillage;
        engReportObject["Presence Water"] = result.data.engReport.presenceWater;
        engReportObject["Survey Report"] = result.data.engReport.surveyReport;
        engReportObject["EngReport Status"] = result.data.engReport.engReportStatus;
        engReportObject["Comment On Eng Report"] = result.data.engReport.commentOnEngReport;
        engReportObject["Proposal name"] = result.data.engReport.proposal.proposalName;
        
        requestExportData.push(engReportObject);

        const worksheet = XLSX.utils.json_to_sheet(requestExportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        fileDownload(dataBlob, `Eng Report.xlsx`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Link
        className="drawer-eng-button btn btn-link text-white text-gradient  mb-0"
        to=""
        title="Download Eng Report"
        onClick={exportReport}
      >
        <i className="material-icons text-sm me-2">download</i>
      </Link>
    </>
  );
};

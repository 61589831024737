import { useEffect, useRef, useState } from "react";
import { Http } from "../../utility/http";
import { Business, State } from "../../shared/generic.interface";
import { useRecoilState, useSetRecoilState } from "recoil";
import { gisFeatureLayerFilter, gisList } from "../../state/atom/gis";
import { getLandSummary } from "../../shared/gis";
import { dashboardSummary } from "../../state/atom/dashboard";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { apiHeaders } from "../../utility/generic";
interface DashboardFilterFormInputs {
  businessName: string;
  state: string;
  project: string;
}
export const DashboardFilter = () => {
  const [states, setState] = useState<State[]>([]);
  const [gisFilterData, setGisFilterData] = useState<any>([]);
  let [gisFeatureLayerFilterData, setGisFeatureLayerFilter] = useRecoilState(
    gisFeatureLayerFilter
  );
  const [gisFilterDataClone, setGisFilterDataClone] = useState<any>([]);
  const [gisClone, setGisClone] = useState<any>({});
  const setdashboardSummary = useSetRecoilState(dashboardSummary);
  const [projects, setProject] = useState<any[]>([]);
  const [business, setBusiness] = useState<Business[]>([]);
  const [gis, setGisList] = useRecoilState(gisList);
  const isStateUpdated = useRef(false);
  const validationSchema = Yup.object().shape({
    businessName: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    project: Yup.string().notRequired(),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<DashboardFilterFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  const businessName = watch("businessName");
  const project = watch("project");
  const state = watch("state");
  useEffect(() => {
    Http.request("get", `${process.env.REACT_APP_API_URL}generic/state`)
      .then((result: any) => {
        setState(result.data.state);
      })
      .catch((error: any) => {});
  }, []);
  useEffect(() => {
    if (!isStateUpdated.current && gis?.data?.request.length) {
      setGisClone({ ...gis });
      const landSummary = getLandSummary(gis.data.request);
      setdashboardSummary(landSummary);
      isStateUpdated.current = true;
    }
  }, [isStateUpdated, gis, setdashboardSummary]);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-parcel/filter-data`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        setGisFilterData(result.data.request);
        setGisFilterDataClone(result.data.request);
        createFilterData(result.data.request);
      })
      .catch((error: any) => {});
  }, [setGisFilterData]);

  const onSubmit = (data: DashboardFilterFormInputs) => {
    let filterRequest: any = JSON.parse(JSON.stringify(gisClone.data.request));
    if (data.businessName) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return Number(parcel.businessName) === Number(data.businessName);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.state) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return Number(parcel.state) === Number(data.state);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.project) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return parcel.site === data.project;
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }
    console.log("xxxxxxxxxxxxxx", gisFeatureLayerFilterData);

    const landSummary = getLandSummary(filterRequest);
    setGisFeatureLayerFilter(gisFeatureLayerFilterData);
    setdashboardSummary(landSummary);
    setGisList({
      data: {
        request: filterRequest,
      },
    });
  };

  const [appliedFilters, setAppliedFilters] = useState({
    business: "",
    state: "",
    project: "",
  });
  const filterData = (e: any, field: string) => {
    let filterData: any = gisFilterDataClone;
    if (e?.target?.value) {
      console.log("e?.target?.value", e?.target?.value);
      let filters = { ...appliedFilters };
      if (field === "business") {
        filters = { ...appliedFilters, business: e.target.value };
        setAppliedFilters(filters);
      }
      if (field === "state") {
        filters = { ...appliedFilters, state: e.target.value };
        setAppliedFilters(filters);
      }
      if (field === "project") {
        filters = { ...appliedFilters, project: e.target.value };
        setAppliedFilters(filters);
      }

      console.log("filters", filters);
      if (filters.business) {
        filterData = filterData.filter((request: any) => {
          return Number(request.businessName) === Number(filters.business);
        });
        gisFeatureLayerFilterData.BusinessName = filters.business;
        createFilterData(filterData, "business");
        setValue("businessName", filters.business);
      }

      if (filters.state) {
        filterData = filterData.filter((request: any) => {
          return Number(request.state.id) === Number(filters.state);
        });
        gisFeatureLayerFilterData.State = filters.state;
        createFilterData(filterData, "state");
        setValue("state", filters.state);
      }

      if (filters.project) {
        filterData = filterData.filter((request: any) => {
          return request.site === filters.project;
        });
        gisFeatureLayerFilterData.Site = filters.project;
        createFilterData(filterData, "site");
        setValue("project", filters.project);
      }

      setGisFilterData(filterData);
    }
  };

  const createFilterData = (data: any, skip: string = "") => {
    const businessData = [];
    console.log("skip", skip, data);
    if (skip !== "business") {
      for (const parcel of data) {
        const index = businessData.findIndex(
          (business) => business.id === parcel.business.id
        );
        if (index === -1) {
          businessData.push(parcel.business);
        }
      }
      setBusiness(businessData);
    }

    if (skip !== "state") {
      const states = [];
      for (const parcel of data) {
        const index = states.findIndex((state) => state.id === parcel.state.id);
        if (index === -1) {
          states.push(parcel.state);
        }
      }
      setState(states);
    }

    if (skip !== "site") {
      const sites = [];
      for (const parcel of data) {
        const index = sites.findIndex((site) => site === parcel.site);
        if (index === -1) {
          sites.push(parcel.site);
        }
      }
      setProject(sites);
    }

    setGisFilterData(filterData);
  };

  useEffect(() => {
    setGisFeatureLayerFilter({
      SurveyNo: "",
      Village: "",
      BusinessName: "",
      CompanyName: "",
      Site: "",
      State: "",
      Request_Id: "",
      abu_or_lem_user_id: "",
      le_spoc_user_id: "",
      le_dh_user_id: "",
    });

    return () => {
      setAppliedFilters({
        business: "",
        project: "",
        state: "",
      });
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}  className="filter-form" >
        <div className="row" style={{alignItems:"baseline"}}>
          <div className="col-lg-3 col-md-6 mt-4">
            <div className="form-group mb-2">
              {/* <label htmlFor="inputBusiness">Active Business</label> */}
              <select
                id="inputBusiness"
                className="form-select"
                aria-label="Default Select Business Name"
                {...register("businessName")}
                onChange={(event) => filterData(event, "business")}
                value={businessName}
              >
                <option value="">---Please Select Business Name---</option>
                {business.map((unit, index) => {
                  return <option value={unit.id}>{unit.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-4">
            <div className="form-group mb-2">
              {/* <label htmlFor="state">Active State</label> */}
              <select
                id="state"
                className="form-select"
                aria-label="Default select state"
                {...register("state")}
                onChange={(event) => filterData(event, "state")}
                value={state}
              >
                <option value="" selected>
                  ---Please Select State---
                </option>
                {states.map((state, index) => {
                  return <option value={state.id}>{state.stateName}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-4">
            <div className="form-group mb-2">
              {/* <label htmlFor="state">Active Site</label> */}
              <select
                className="form-select"
                aria-label="Default Company Name"
                {...register("project")}
                onChange={(event) => filterData(event, "project")}
                value={project}
              >
                <option value="" selected>
                  ---Select Site Name---
                </option>
                {projects.map((project: any, index: any) => {
                  return (
                    <option key={project + index} value={project}>
                      {project}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-5">
            <div className="form-group mb-2" style={{ display: "flex" }}>
              <button
                type="submit"
                className="btn bg-gradient-primary-login btn1"
              >
                APPLY FILTER
              </button>
              &nbsp;
              <button
                type="button"
                onClick={() => {
                  const landSummary = getLandSummary(gisClone.data.request);
                  setdashboardSummary(landSummary);
                  setGisList(gisClone);
                  setGisFilterData(gisFilterDataClone);
                  createFilterData(gisFilterDataClone);
                  setGisFeatureLayerFilter({
                    SurveyNo: "",
                    Village: "",
                    BusinessName: "",
                    CompanyName: "",
                    Site: "",
                    State: "",
                    Request_Id: "",
                    abu_or_lem_user_id: "",
                    le_spoc_user_id: "",
                    le_dh_user_id: "",
                  });
                  setAppliedFilters({
                    business: "",
                    project: "",
                    state: "",
                  });
                  reset();
                }}
                className="btn btn-primary btn1"
              >
                Reset FILTER
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

// imports
import { useState, useEffect } from "react";
import AnonymousLayout from "../layout/anonymous-layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Http } from "../utility/http";
import { useRecoilValue } from "recoil";
import { associatedEmail } from "../state/atom/user";

// interfaces
interface ResetPasswordFormInputs {
  email: string;
  password: string;
  confirmPassword: string;
  otp: number;
}

// This component showing the reset password form
export const ResetPassword = () => {

  // state and hooks
  const associatedEmailID = useRecoilValue(associatedEmail);
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState("");

  // validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("E-mail is required")
      .email("Enter valid e-mail"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|[^a-zA-Z\d\s:])/,
        "Password must be 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, and 1 numeric or special character"
      ),
    confirmPassword: Yup.string().required("Confirm password is required"),
    otp: Yup.number().required("OTP is required").typeError("OTP is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (resetPasswordErrorMessage && (errors.email || errors.password)) {
      setResetPasswordErrorMessage("");
    }
  }, [resetPasswordErrorMessage, errors.email, errors.password]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword((prevState) => !prevState);
  };

  /**
  Handles the form submission when the user clicks the reset button.
  This function performs an API call to reset password.
  @param {ResetPasswordFormInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: ResetPasswordFormInputs) => {
    setLoading(true);
    setResetPasswordErrorMessage("");
    setUpdatePasswordMessage("");
    
    // Make the POST request for reset the password
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}auth/reset-password`,
      data
    )
      .then((res) => {
        setUpdatePasswordMessage(res.message);
      })
      .catch((err) => {
        setLoading(false);

        // Set an error message if the API request fails
        setResetPasswordErrorMessage(err.response.data.message);
      });
  };

  return (
    <AnonymousLayout>
      <>
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-12 mx-auto">
              <div className="card z-index-0 fadeIn3 fadeInBottom card1">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-gradient-primary-login shadow-primary border-radius-lg py-3 pe-1">
                    <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">
                      Reset Password
                    </h4>
                  </div>
                </div>

                {/* Associated EmailID */}
                {associatedEmailID && (
                  <div className="text-center">
                    <h4>Check Your Email</h4>
                    <p >
                      We have sent the OTP for password reset to{" "}
                      <strong>{associatedEmailID}</strong>
                    </p>
                  </div>
                )}

                {/* Reset Password Error Message */}
                {resetPasswordErrorMessage && (
                  <span className="mt-2 mb-0 text-center text-danger">
                    {resetPasswordErrorMessage}
                  </span>
                )}

                <div className="card-body">
                  {updatePasswordMessage ? (
                    <p className="text-center">{updatePasswordMessage}</p>
                  ) : (
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="text-start"
                    >
                      {/* Email */}
                      <div
                        className={`input-group input-group-outline ${
                          errors.email ? "mb-1" : "mb-3"
                        } `}
                      >
                        <input
                          type="email"
                          className="form-control"
                          {...register("email")}
                          placeholder="Email Address"
                        />
                      </div>
                      <div className="mb-3">
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </div>

                      {/* Password  */}
                      <div
                        className={`input-group input-group-outline ${
                          errors.password ? "mb-1" : "mb-3"
                        } `}
                      >
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          {...register("password")}
                          placeholder="Password"
                        />
                        <span
                          className="input-group-text eye-icon"
                          onClick={togglePasswordVisibility}
                        >
                          <i
                            className={`fa fa-eye${
                              showPassword ? "-slash" : ""
                            }`}
                          ></i>
                        </span>
                      </div>
                      <div className="mb-3">
                        {errors.password && (
                          <span className="text-danger">
                            {errors.password.message}
                          </span>
                        )}
                      </div>

                    {/* Confirm password */}
                      <div
                        className={`input-group input-group-outline ${
                          errors.confirmPassword ? "mb-1" : "mb-3"
                        } `}
                      >
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control"
                          {...register("confirmPassword")}
                          placeholder="Confirm Password"
                        />
                        <span
                          className="input-group-text eye-icon"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          <i
                            className={`fa fa-eye${
                              showConfirmPassword ? "-slash" : ""
                            }`}
                          ></i>
                        </span>
                      </div>
                      <div className="mb-3">
                        {errors.confirmPassword && (
                          <span className="text-danger">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                      </div>

                      {/* OTP */}
                      <div
                        className={`input-group input-group-outline ${
                          errors.email ? "mb-1" : "mb-3"
                        } `}
                      >
                        <input
                          type="number"
                          className="form-control"
                          {...register("otp")}
                          placeholder="OTP"
                        />
                      </div>
                      <div className="mb-0">
                        {errors.otp && (
                          <span className="text-danger">
                            {errors.otp.message}
                          </span>
                        )}
                      </div>

                    {/* Submit button */}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn bg-gradient-primary-login w-100 my-4 mb-2 btn1"
                          disabled={loading}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Reset Password"
                          )}
                        </button>
                      </div>
                    </form>
                  )}

                  {/* Go to sign in page */}
                  <div className=" text-center pt-0 px-lg-2 px-1">
                    <p className="mb-2 text-sm mx-auto">
                      Go to{" "}
                      <Link
                        to="/"
                        className="text-primary text-gradient font-weight-bold"
                      >
                        Sign in{" "}
                      </Link>
                      page.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </AnonymousLayout>
  );
};

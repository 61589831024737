import { useEffect } from "react";
import { Toaster } from "../component/toaster";
import { MainLayout } from "../layout/main-layout";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";
import { requestList } from "../state/atom/request";
import { useSetRecoilState } from "recoil";
import { RequestHomeMap } from "../component/request-home-map";
import { selectedMenu } from "../state/atom/generic";
import { Menu } from "../shared/generic.constant";
import { MisList } from "../component/report/mis-list";
import { MisFilter } from "../component/report/mis-filter";
import { misReport } from "../state/atom/report";
import { ReportMap } from "../component/report/report-map";
import { validatePermission } from "../utility/permission";

export const Report = () => {
  validatePermission("menu", "report");
  const setMisReport = useSetRecoilState(misReport);
  const setSelectedMenu = useSetRecoilState(selectedMenu);

  useEffect(() => {
    setSelectedMenu(Menu.report);
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-request/mis/report`,
      null,
      apiHeaders()
    )
      .then((result) => {
        setMisReport(result);
      })
      .catch((error) => {});
  });
  return (
    <MainLayout>
      <Toaster></Toaster>
      <MisFilter></MisFilter>
      <ReportMap classList="full-map gis-map"></ReportMap>
      <MisList></MisList>
    </MainLayout>
  );
};

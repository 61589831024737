import { RequestDetails } from "./request.interface";

export const getDashBoardSummary = (filterRequest: RequestDetails[]) => {
  return {
    totalBusiness: totalBusiness(filterRequest),
    totalState: totalState(filterRequest),
    totalSite: totalSite(filterRequest),
  };
};

const totalBusiness = (filterRequest: RequestDetails[]) => {
  const activeBusinessArray: any = [];
  filterRequest.forEach((request: any) => {
    if (activeBusinessArray.indexOf(request.businessUnitName.name) === -1) {
      activeBusinessArray.push(request.businessUnitName.name);
    }
  });
  console.log("activeBusinessArray", activeBusinessArray);
  if (activeBusinessArray.length) {
    return activeBusinessArray.sort();
  } else {
    return activeBusinessArray;
  }
};

const totalState = (filterRequest: RequestDetails[]) => {
  const activeStateArray: any = [];
  filterRequest.forEach((request: any) => {
    if (activeStateArray.indexOf(request.state.stateName) === -1) {
      activeStateArray.push(request.state.stateName);
    }
  });
  if (activeStateArray.length) {
    return activeStateArray.sort();
  } else {
    return activeStateArray;
  }
};

const totalSite = (filterRequest: RequestDetails[]) => {
  const activeSiteArray: any = [];
  filterRequest.forEach((request: any) => {
    if (activeSiteArray.indexOf(request.location) === -1) {
      activeSiteArray.push(request.location);
    }
  });

  if (activeSiteArray.length) {
    return activeSiteArray.sort();
  } else {
    return activeSiteArray;
  }
};

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface props {
  totalCol?: number;
}

export const SkeletonComp = ({ totalCol }: props) => {
  const colLength = totalCol || 5;
  return (
    <SkeletonTheme
      baseColor="#ededed"
      highlightColor="#333"
      borderRadius="0.5rem"
      duration={3}
    >
      <tr>
        {[...Array(colLength)].map((item, i) => {
          return (
            <th>
              <Skeleton height={30} />
            </th>
          );
        })}
      </tr>
      <tr>
        {[...Array(colLength)].map((item, i) => {
          return (
            <th>
              <Skeleton height={30} />
            </th>
          );
        })}
      </tr>
      <tr>
        {[...Array(colLength)].map((item, i) => {
          return (
            <th>
              <Skeleton height={30} />
            </th>
          );
        })}
      </tr>
      <tr>
        {[...Array(colLength)].map((item, i) => {
          return (
            <th>
              <Skeleton height={30} />
            </th>
          );
        })}
      </tr>
      <tr>
        {[...Array(colLength)].map((item, i) => {
          return (
            <th>
              <Skeleton height={30} />
            </th>
          );
        })}
      </tr>
    </SkeletonTheme>
  );
};

// imports
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgressBar } from "react-bootstrap";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { toaster } from "../../state/atom/toaster";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import {
  editEngReportListID,
  editEngReportListToggle,
  engReportToggle,
} from "../../state/atom/eng-report";
import {
  proposalIdForAddEngReport,
  updateProposalList,
} from "../../state/atom/proposal";
import { mapCenter } from "../../state/atom/generic";
import { InfoBox } from "../info-hover";
import Select from "react-select";
import { LandType1 } from "../../shared/generic.constant";
import moment from "moment";
import { customStyles } from "../../shared/landTypeStyle";
import { Link } from "react-router-dom";

interface EngReportFromInput {
  siteVisitDate: string;
  proposalId?: string;
  nearByRailwayStation: string;
  nearByAirport: string;
  nearByCity: string;
  siteLocation: string;
  locationPdf?: string;
  proposedCapacity?: number;
  proposedArea?: number;
  comment?: string;
  nearByHighway?: string;
  elevationFromMsl?: string;
  siteOrientation?: string;
  seismicZone?: number;
  avgAnnualWindSpeed?: number;
  basicWindSpeedCode?: string;
  windZone?: number;
  annualRainfall?: number;
  nearByPoolingStation?: string;
  landType?: Array<{ value: string; label: string }>;
  soilCondition?: string;
  availabilityOfWater?: boolean;
  vegetation?: string;
  displacementOfHutsAndPeople?: boolean;
  villageRoadPassingThoughLand?: boolean;
  presenceOfHtLtLine?: boolean;
  workshopStructureOrAccessRoad?: boolean;
  isLandAdjacentToVillage?: boolean;
  presenceWater?: string;
  surveyReport?: string;
}

interface ImageInfo {
  id: number;
  name: string;
  url: string;

}

// Component displaying the proposal form.
export const EngineerReportUpdateForm = () => {
  // Validation schema
  const validationSchema = Yup.object().shape({
    siteVisitDate: Yup.date().required("Date is required"),
    nearByRailwayStation: Yup.string().required("Railway station is required"),
    nearByAirport: Yup.string().required("Airport is required"),
    landType: Yup.array(),
    nearByCity: Yup.string().required("City is required"),
    siteLocation: Yup.string().required("Site location is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<EngReportFromInput>({
    resolver: yupResolver(validationSchema),
  });

  // states and hooks
  const setMapCenter = useSetRecoilState(mapCenter);
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [images, setImages] = useState<ImageInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [engReportPopupToggle, setEngReportToggle] =
    useRecoilState(engReportToggle);
  const propId = useRecoilValue(proposalIdForAddEngReport);
  const setToasterStatus = useSetRecoilState(toaster);
  const setUpdateProposalList = useSetRecoilState(updateProposalList);

  const [basicWindSpeedFile, setBasicWindSpeedFile] = useState("");
  const [locationFile, setLocationFile] = useState("");
  const [surveyReportFile, setSurveyReportFile] = useState("");
  const [viewBasicWindSpeedFile, setViewBasicWindSpeedFile] = useState("");
  const [viewLocationFile, setViewLocationFile] = useState("");
  const [viewSurveyReportFile, setViewSurveyReportFile] = useState("");

  const [basicWindSpeedFileProgress, setBasicWindSpeedFileProgress] =
    useState(false);
  const [locationFileProgress, setLocationFileProgress] = useState(false);
  const [surveyReportFileProgress, setSurveyReportFileProgress] =
    useState(false);
  const [photographFileProgress, setPhotographFileProgress] = useState(false);
  const [addImageButton, setAddImageButton] = useState(false);

  const [editEngReportID, setEditEngReportId] =
    useRecoilState(editEngReportListID);
  const [editEngReportToggle, setEditEngReportToggle] = useRecoilState(
    editEngReportListToggle
  );
  const [engReportID, setEndReportId] = useState("");

  useEffect(() => {
    // Make the GET request for get the particular land request details
    if (editEngReportID) {
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}eng-report/${editEngReportID}`,
        null,
        apiHeaders()
      )
        .then((result) => {
          setEndReportId(result?.data?.engReport?.id);
          setViewLocationFile(result?.data?.engReport.locationPdf)
          setViewBasicWindSpeedFile(result?.data?.engReport.basicWindSpeedCode)
          setViewSurveyReportFile( result?.data?.engReport.surveyReport)
          // setImages(result?.data?.engReport.photographs);
          const image = result?.data?.engReport.photographs;
          const updatedImage = image.map((item:any) => ({
            ...item,
            url: item.name
          }));
          setImages(updatedImage);
          setValue(
            "nearByRailwayStation",
            result?.data?.engReport.nearByRailwayStation
          );
          setValue("nearByCity", result?.data?.engReport.nearByCity);
          setValue("nearByAirport", result?.data?.engReport.nearByAirport);
          setValue("siteLocation", result?.data?.engReport.siteLocation);
          setValue(
            "proposedCapacity",
            result?.data?.engReport.proposedCapacity
          );
          setValue("siteVisitDate", result?.data?.engReport.siteVisitDate);
          setValue("proposedArea", result?.data?.engReport.proposedArea);
          setValue("comment", result?.data?.engReport.comment);
          setValue("nearByHighway", result?.data?.engReport.nearByHighway);
          setValue("siteOrientation", result?.data?.engReport.siteOrientation);
          setValue(
            "elevationFromMsl",
            result?.data?.engReport.elevationFromMsl
          );
          setValue("soilCondition", result?.data?.engReport.soilCondition);
          setValue(
            "nearByPoolingStation",
            result?.data?.engReport.nearByPoolingStation
          );
          setValue("annualRainfall", result?.data?.engReport.annualRainfall);
          setValue("windZone", result?.data?.engReport.windZone);
          setValue(
            "avgAnnualWindSpeed",
            result?.data?.engReport.avgAnnualWindSpeed
          );
          setValue("seismicZone", result?.data?.engReport.seismicZone);
          setValue(
            "availabilityOfWater",
            result?.data?.engReport.availabilityOfWater
          );
          setValue("vegetation", result?.data?.engReport.vegetation);
          setValue(
            "displacementOfHutsAndPeople",
            result?.data?.engReport.displacementOfHutsAndPeople
          );
          setValue(
            "villageRoadPassingThoughLand",
            result?.data?.engReport.villageRoadPassingThoughLand
          );
          setValue(
            "presenceOfHtLtLine",
            result?.data?.engReport.presenceOfHtLtLine
          );
          setValue(
            "workshopStructureOrAccessRoad",
            result?.data?.engReport.workshopStructureOrAccessRoad
          );
          setValue(
            "isLandAdjacentToVillage",
            result?.data?.engReport.isLandAdjacentToVillage
          );
          setValue("presenceWater", result?.data?.engReport.presenceWater);
          const preFilledOptions = LandType1.filter((item) =>
            result?.data?.engReport?.landType.includes(item.value)
          ).map(({ value, label }) => ({ value, label }));
          setValue("landType", preFilledOptions);
        })
        .catch((error) => {
          //   setLoadingDetails(false);
          console.log(error);
        });
    }
  }, [editEngReportID]);

  const onSubmit = (data: any) => {
    data.basicWindSpeedCode = basicWindSpeedFile;
    data.locationPdf = locationFile;
    data.surveyReport = surveyReportFile;
    data.photograph = images;
    data.proposalId = propId;
    const modifiedTypeOfLand = data?.landType?.map((item: any) => {
      return item.value;
    });
    data.landType = modifiedTypeOfLand;
    const formateDate =
      moment(data?.siteVisitDate).format("YYYY-MM-DD") || "no";
    data.siteVisitDate = formateDate;
    const cleanedData = removeEmptyValues(data);

    if (cleanedData.annualRainfall) {
      cleanedData.annualRainfall = Number(cleanedData.annualRainfall);
    }

    if (cleanedData.windZone) {
      cleanedData.windZone = Number(cleanedData.windZone);
    }

    if (cleanedData.avgAnnualWindSpeed) {
      cleanedData.avgAnnualWindSpeed = Number(cleanedData.avgAnnualWindSpeed);
    }

    if (cleanedData.seismicZone) {
      cleanedData.seismicZone = Number(cleanedData.seismicZone);
    }

    if (cleanedData.proposedArea) {
      cleanedData.proposedArea = Number(cleanedData.proposedArea);
    }

    if (cleanedData.proposedCapacity) {
      cleanedData.proposedCapacity = Number(cleanedData.proposedCapacity);
    }

    // Make a POST request to the land request API endpoint
    Http.request(
      "put",
      `${process.env.REACT_APP_API_URL}eng-report/${engReportID}`,
      cleanedData,
      apiHeaders()
    )
      .then((result: any) => {
        // Reset the form fields
        reset();
        setUpdateProposalList(true);
        setEndReportId("");
        setEditEngReportToggle(false);
        setEditEngReportId("");
        setImages([]);
        setLocationFile("");
        setViewSurveyReportFile("");
        setViewLocationFile("");
        setViewBasicWindSpeedFile("");

        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Engineering Report",
          text: "Engineering Report update successfully",
        });
        reset({ landType: [] });
        setEngReportToggle(false);
      })
      .catch((error: any) => {
        // Set an error message if the API request fails
        setSubmitErrorMessage(error.response.data.message);
      });

    // Set loading state to false
    setLoading(false);
  };

  const removeEmptyValues = (obj: any) => {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (
        newObj[key] === "" ||
        newObj[key] === null ||
        newObj[key] === undefined
      ) {
        delete newObj[key];
      }
    }
    return newObj;
  };

  // Handle WindSpeed file change
  const basicWindSpeedFileChanges = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setBasicWindSpeedFileProgress(true);

    // Make a POST request to the Wind Speed File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/eng-report/wind-speed`,
      formData
    )
      .then((result: any) => {
        setBasicWindSpeedFile(result.fileName);
        setBasicWindSpeedFileProgress(false);
      })
      .catch((error: any) => {
        setBasicWindSpeedFileProgress(false);
      });
  };

  // Handle Location File file change
  const locationFileChanges = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setLocationFileProgress(true);

    // Make a POST request to the Location File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/eng-report/location`,
      formData
    )
      .then((result: any) => {
        setLocationFile(result.fileName);
        setLocationFileProgress(false);
      })
      .catch((error: any) => {
        setLocationFileProgress(false);
      });
  };

  // Handle Survey Report File file change
  const surveyReportChanges = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setSurveyReportFileProgress(true);

    // Make a POST request to the Survey Report File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/eng-report/survey`,
      formData
    )
      .then((result: any) => {
        setSurveyReportFile(result.fileName);
        setSurveyReportFileProgress(false);
      })
      .catch((error: any) => {
        setSurveyReportFileProgress(false);
      });
  };

  // Handle the on wheel change
  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
  };

  const handleImageChange = (event: any, index: number) => {
    setPhotographFileProgress(true);
    setAddImageButton(true);
    const formData = new FormData();
    formData.append("document", event.target.files[0]);

    // Make a POST request to the Photograph API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/eng-report/photo`,
      formData
    )
      .then((result: any) => {
        const updatedImages = [...images];
        updatedImages[index] = {
          id: index + 1,
          name: result.fileName,
          url: result.url,
        };
        setImages(updatedImages);
        setPhotographFileProgress(false);
        setAddImageButton(false);
      })
      .catch((error: any) => {
        setPhotographFileProgress(false);
        setAddImageButton(false);
      });
  };

  const handleAddImage = () => {
    setImages([...images, { id: images.length + 1, name: "", url:''}]);
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  return (
    <div className="box">
      <div
        className={
          "new-proposal-init new-proposal-popup align-self-end " +
          (!editEngReportToggle ? "new-proposal-init--collapsed" : "")
        }
      >
        <div
          className="text-left panel-title sticky-heading"
          style={{ background: "#375db1", position:'sticky', zIndex:'1' }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "15px" }}
          >
            Update Engineering Report{" "}
          </span>
          <span
            className="close-btn"
            id="new-proposal-close"
            onClick={() => {
              setEditEngReportToggle(false);
              clearErrors();
              setImages([])
            }}
          >
            &times;
          </span>
        </div>
        <div style={{ overflowY: "auto" }}>
          <h6 className="mb-0 panel-header">
            Fill all the details for update engineering report.
          </h6>
          {submitErrorMessage && (
            <div className="text-center">
              <span className="text-warning">{submitErrorMessage}</span>
            </div>
          )}

          <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
            {/* Nearest Railway Station */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Nearest Railway Station*</label>
              <input
                type="text"
                className="form-control"
                id="nearByRailwayStationName"
                placeholder="for example Sabarmati-Ahmedabad"
                {...register("nearByRailwayStation")}
              />

              {errors.nearByRailwayStation && (
                <span className="text-warning">
                  {errors.nearByRailwayStation.message}
                </span>
              )}
            </div>

            {/* Nearest Airport */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Nearest Airport*</label>
              <input
                type="text"
                className="form-control"
                id="nearByAirport"
                placeholder="for example SVP Airport-Ahmedabad"
                {...register("nearByAirport")}
              />

              {errors.nearByAirport && (
                <span className="text-warning">
                  {errors.nearByAirport.message}
                </span>
              )}
            </div>

            {/* Nearest City */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Nearest City*</label>
              <input
                type="text"
                className="form-control"
                id="nearByCity"
                placeholder="for example Ahmedabad"
                {...register("nearByCity")}
              />

              {errors.nearByCity && (
                <span className="text-warning">
                  {errors.nearByCity.message}
                </span>
              )}
            </div>

            {/* Visit Date  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Visit Date*</label>
              <input
                type="date"
                className="form-control"
                id="siteVisitDate"
                placeholder="for example 10|10|2023"
                {...register("siteVisitDate")}
              />

              {errors.siteVisitDate && (
                <span className="text-warning">
                  {errors.siteVisitDate.message}
                </span>
              )}
            </div>

            {/* Site Location */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Site Location*</label>
              <input
                type="text"
                className="form-control"
                id="siteLocation"
                placeholder="for example Survey number-285, Bhadaj, Ahmedabad"
                {...register("siteLocation")}
              />

              {errors.siteLocation && (
                <span className="text-warning">
                  {errors.siteLocation.message}
                </span>
              )}
            </div>

            {/* Location DOC  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                Location DOC (PDF)
              </label>
              &nbsp;
              <InfoBox
                data={"Location DOC (PDF) will show the Location DOC."}
              ></InfoBox>
              {viewLocationFile && (
                <>
                  <br></br>
                  <Link
                    target="_blank"
                    title="View File"
                    style={{ color: "#fff", textDecoration: "none" }}
                    to={viewLocationFile}
                    className="view-file"
                  >
                    <i className="bi bi-pencil-square"></i> &nbsp;View File
                  </Link>
                  &nbsp;
                </>
              )}
              {viewLocationFile && (
                <>
                  <br></br>
                  <span
                    onClick={() => {
                      setViewLocationFile("");
                      setLocationFile("");
                      setValue("locationPdf", '');
                    }}
                    className="a-link"
                  >
                    &nbsp;<i className="bi bi-folder-x"></i>&nbsp; Remove File
                  </span>
                  <br></br>
                </>
              )}
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="locationPdfEngg"
                {...register("locationPdf")}
                onChange={locationFileChanges}
                placeholder={locationFile ? locationFile : "Select a file..."}
              />
              {errors.locationPdf && (
                <span className="text-warning">
                  {errors.locationPdf.message}
                </span>
              )}
              {locationFileProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* Proposed capacity  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Proposed capacity</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="proposedCapacity"
                placeholder="for example 75"
                onWheel={numberInputOnWheelPreventChange}
                {...register("proposedCapacity")}
              />

              {errors.proposedCapacity && (
                <span className="text-warning">
                  {errors.proposedCapacity.message}
                </span>
              )}
            </div>

            {/* Proposed area  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Proposed Area</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="proposedArea"
                placeholder="for example 150"
                onWheel={numberInputOnWheelPreventChange}
                {...register("proposedArea")}
              />

              {errors.proposedArea && (
                <span className="text-warning">
                  {errors.proposedArea.message}
                </span>
              )}
            </div>

            {/*Comment */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputSRequest">Comment</label>
              <input
                type="text"
                className="form-control"
                id="comment"
                placeholder="for example Land is located at outskirts of Bhadaj villege"
                {...register("comment")}
              />

              {errors.comment && (
                <span className="text-warning">{errors.comment.message}</span>
              )}
            </div>

            {/* Nearest Highway */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Nearest Highway</label>
              <input
                type="text"
                className="form-control"
                id="nearByHighway"
                placeholder="for example SH-222"
                {...register("nearByHighway")}
              />

              {errors.nearByHighway && (
                <span className="text-warning">
                  {errors.nearByHighway.message}
                </span>
              )}
            </div>

            {/* Elevation from msl */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Elevation from msl</label>
              <input
                type="number"
                className="form-control"
                id="elevationFromMsl"
                placeholder="for example SH-222"
                {...register("elevationFromMsl")}
                onWheel={numberInputOnWheelPreventChange}
              />

              {errors.elevationFromMsl && (
                <span className="text-warning">
                  {errors.elevationFromMsl.message}
                </span>
              )}
            </div>

            {/* Site Orientation */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Site Orientation</label>
              <input
                type="text"
                className="form-control"
                id="siteOrientation"
                placeholder="for example Land of the site is slightly sloping in the north-west direction"
                {...register("siteOrientation")}
              />

              {errors.siteOrientation && (
                <span className="text-warning">
                  {errors.siteOrientation.message}
                </span>
              )}
            </div>

            {/* Seismic Zone  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Seismic Zone</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="seismicZone"
                placeholder="for example 2"
                onWheel={numberInputOnWheelPreventChange}
                {...register("seismicZone")}
              />

              {errors.seismicZone && (
                <span className="text-warning">
                  {errors.seismicZone.message}
                </span>
              )}
            </div>

            {/* Average Annual Wind Speed  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Average Annual Wind Speed</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="avgAnnualWindSpeed"
                placeholder="for example 20"
                onWheel={numberInputOnWheelPreventChange}
                {...register("avgAnnualWindSpeed")}
              />

              {errors.avgAnnualWindSpeed && (
                <span className="text-warning">
                  {errors.avgAnnualWindSpeed.message}
                </span>
              )}
            </div>

            {/* Basic Wind Speed Code DOC  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                Basic Wind Speed Code DOC (PDF)
              </label>
              &nbsp;
              <InfoBox
                data={
                  "Basic Wind Speed Code DOC (PDF) will show the Wind Speed Code DOC."
                }
              ></InfoBox>
              {viewBasicWindSpeedFile && (
                <>
                  <br></br>
                  <Link
                    target="_blank"
                    title="View File"
                    style={{ color: "#fff", textDecoration: "none" }}
                    to={viewBasicWindSpeedFile}
                    className="view-file"
                  >
                    <i className="bi bi-pencil-square"></i> &nbsp;View File
                  </Link>
                  &nbsp;
                </>
              )}
              {viewBasicWindSpeedFile && (
                <>
                  <br></br>
                  <span
                    onClick={() => {
                      setViewBasicWindSpeedFile("");
                      setBasicWindSpeedFile("");
                      setValue("basicWindSpeedCode", '');
                    }}
                    className="a-link"
                  >
                    &nbsp;<i className="bi bi-folder-x"></i>&nbsp; Remove File
                  </span>
                  <br></br>
                </>
              )}
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="basicWindSpeedCode"
                {...register("basicWindSpeedCode")}
                onChange={basicWindSpeedFileChanges}
                placeholder={basicWindSpeedFile ? basicWindSpeedFile : "Select a file..."}
              />
              {errors.basicWindSpeedCode && (
                <span className="text-warning">
                  {errors.basicWindSpeedCode.message}
                </span>
              )}
              {basicWindSpeedFileProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* Wind Zone  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Wind Zone</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="windZone"
                placeholder="for example 5"
                onWheel={numberInputOnWheelPreventChange}
                {...register("windZone")}
              />

              {errors.windZone && (
                <span className="text-warning">{errors.windZone.message}</span>
              )}
            </div>

            {/* Annual Rain fall  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Annual Rain fall</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="annualRainfall"
                placeholder="for example 30"
                onWheel={numberInputOnWheelPreventChange}
                {...register("annualRainfall")}
              />

              {errors.annualRainfall && (
                <span className="text-warning">
                  {errors.annualRainfall.message}
                </span>
              )}
            </div>

            {/* Nearest Pooling Station */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Nearest Pooling Station</label>
              <input
                type="text"
                className="form-control"
                id="nearByPoolingStation"
                placeholder="for example Bhadaj 66-kv"
                {...register("nearByPoolingStation")}
              />

              {errors.nearByPoolingStation && (
                <span className="text-warning">
                  {errors.nearByPoolingStation.message}
                </span>
              )}
            </div>

            {/* Type Of Land */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputTLand">Type Of Land</label>
              <Controller
                name="landType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={LandType1}
                    className="basic-multi-select"
                    placeholder="Select Land Type"
                    classNamePrefix="select"
                    styles={customStyles}
                  />
                )}
              />

              {errors.landType && (
                <span className="text-warning">{errors.landType.message}</span>
              )}
            </div>

            {/* Soil Condition */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Soil Condition</label>
              <input
                type="text"
                className="form-control"
                id="soilCondition"
                placeholder="for example Thin dry Black soil layer with rock bed beneath the land"
                {...register("soilCondition")}
              />

              {errors.soilCondition && (
                <span className="text-warning">
                  {errors.soilCondition.message}
                </span>
              )}
            </div>

            {/* Availability Of Water */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <input
                  type="checkbox"
                  id="availabilityOfWater"
                  className="form-check-input"
                  {...register("availabilityOfWater")}
                />{" "}
                Availability Of Water
              </label>
              &nbsp;
              <InfoBox
                data={
                  "Availability Of Water will show the Water is Available or not."
                }
              ></InfoBox>
              <br></br>
              {errors.availabilityOfWater && (
                <span className="text-warning">
                  {errors.availabilityOfWater.message}
                </span>
              )}
            </div>

            {/* Vegetation */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Vegetation</label>
              <input
                type="text"
                className="form-control"
                id="vegetation"
                placeholder="for example There are around 20 trees on the land"
                {...register("vegetation")}
              />

              {errors.vegetation && (
                <span className="text-warning">
                  {errors.vegetation.message}
                </span>
              )}
            </div>

            {/* Displacement Of Huts And People */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <input
                  type="checkbox"
                  id="displacementOfHutsAndPeople"
                  className="form-check-input"
                  {...register("displacementOfHutsAndPeople")}
                />{" "}
                Displacement Of Huts And People
              </label>
              &nbsp;
              <InfoBox
                data={
                  "Displacement Of Huts And People will show the Displacement Of Huts And People."
                }
              ></InfoBox>
              <br></br>
              <br></br>
              {errors.displacementOfHutsAndPeople && (
                <span className="text-warning">
                  {errors.displacementOfHutsAndPeople.message}
                </span>
              )}
            </div>

            {/* Village Road Passing Though Land */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <input
                  type="checkbox"
                  id="villageRoadPassingThoughLand"
                  className="form-check-input"
                  {...register("villageRoadPassingThoughLand")}
                />{" "}
                Village Road Passing Though Land
              </label>
              &nbsp;
              <InfoBox
                data={
                  "Village Road Passing Though Land will show the Village Road Passing Though Land."
                }
              ></InfoBox>
              <br></br>
              <br></br>
              {errors.villageRoadPassingThoughLand && (
                <span className="text-warning">
                  {errors.villageRoadPassingThoughLand.message}
                </span>
              )}
            </div>

            {/* Presence Of HtLt Line */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <input
                  type="checkbox"
                  id="presenceOfHtLtLine"
                  className="form-check-input"
                  {...register("presenceOfHtLtLine")}
                />{" "}
                Presence Of HT/LT Line
              </label>
              &nbsp;
              <InfoBox
                data={
                  "Presence Of HtLt Line will show the HtLt Line Presence or not."
                }
              ></InfoBox>
              <br></br>
              <br></br>
              {errors.presenceOfHtLtLine && (
                <span className="text-warning">
                  {errors.presenceOfHtLtLine.message}
                </span>
              )}
            </div>

            {/* Workshop Structure Or Access Road */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <input
                  type="checkbox"
                  id="workshopStructureOrAccessRoad"
                  className="form-check-input"
                  {...register("workshopStructureOrAccessRoad")}
                />{" "}
                Workshop Structure Or Access Road
              </label>
              &nbsp;
              <InfoBox
                data={
                  "Workshop Structure Or Access Road will show the Workshop Structure Or Access Road."
                }
              ></InfoBox>
              <br></br>
              <br></br>
              {errors.workshopStructureOrAccessRoad && (
                <span className="text-warning">
                  {errors.workshopStructureOrAccessRoad.message}
                </span>
              )}
            </div>

            {/* Is Land Adjacent To Village */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <input
                  type="checkbox"
                  id="isLandAdjacentToVillage"
                  className="form-check-input"
                  {...register("isLandAdjacentToVillage")}
                />{" "}
                Is Land Adjacent To Village
              </label>
              &nbsp;
              <InfoBox
                data={
                  "Is Land Adjacent To Village will show the Land Adjacent To Village."
                }
              ></InfoBox>
              <br></br>
              <br></br>
              {errors.isLandAdjacentToVillage && (
                <span className="text-warning">
                  {errors.isLandAdjacentToVillage.message}
                </span>
              )}
            </div>

            {/* Presence Water */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Source of Water</label>
              <input
                type="text"
                className="form-control"
                id="presenceWater"
                placeholder="for example Nearby water canal is 1.5 km away from the land"
                {...register("presenceWater")}
              />

              {errors.presenceWater && (
                <span className="text-warning">
                  {errors.presenceWater.message}
                </span>
              )}
            </div>

            {/* Survey Report  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                Survey Report (PDF)
              </label>
              &nbsp;
              <InfoBox
                data={"Survey Report DOC (PDF) will show the Survey Report."}
              ></InfoBox>
              {viewSurveyReportFile && (
                <>
                  <br></br>
                  <Link
                    target="_blank"
                    title="View File"
                    style={{ color: "#fff", textDecoration: "none" }}
                    to={viewSurveyReportFile}
                    className="view-file"
                  >
                    <i className="bi bi-pencil-square"></i> &nbsp;View File
                  </Link>
                  &nbsp;
                </>
              )}
              {viewSurveyReportFile && (
                <>
                  <br></br>
                  <span
                    onClick={() => {
                      setViewSurveyReportFile('')
                      setSurveyReportFile('')
                      setValue("surveyReport", '');
                    }}
                    className="a-link"
                  >
                    &nbsp;<i className="bi bi-folder-x"></i>&nbsp; Remove File
                  </span>
                  <br></br>
                </>
              )}
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="surveyReport"
                {...register("surveyReport")}
                onChange={surveyReportChanges}
              />
              {errors.surveyReport && (
                <span className="text-warning">
                  {errors.surveyReport.message}
                </span>
              )}
              {surveyReportFileProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {images.map((image, index) => (
              <div className="form-group px-3 py-2" key={image.id}>
                <label
                  htmlFor={`photograph_${index + 1}`}
                  className="form-label"
                >
                  Photograph {index + 1} (JPG)
                </label>
                &nbsp;
                <InfoBox
                  data={`Photograph ${index + 1} will show the Photograph ${
                    index + 1
                  }`}
                ></InfoBox>
                <br></br>
                {image.url && (
                  <Link
                    target="_blank"
                    title="View File"
                    style={{ color: "#fff", textDecoration: "none" }}
                    to={image.url}
                    className="view-file"
                  >
                    <i className="bi bi-pencil-square"></i> &nbsp;View File
                  </Link>
                )}
                <div style={{ display: "flex" }}>
                  <input
                    style={{ width: "500px", justifyContent: "space-between" }}
                    className="form-control"
                    type="file"
                    accept="image/*"
                    id={`photograph_${index + 1}`}
                    onChange={(e) => handleImageChange(e, index)}
                  />
                  <div style={{ paddingLeft: "5px" }}>
                    {image && (
                      <button
                        className="btn btn-danger btn-xl"
                        onClick={() => handleRemoveImage(index)}
                      >
                        <i className="bi-trash-fill "></i>
                      </button>
                    )}
                  </div>
                </div>
                {photographFileProgress && (
                  <ProgressBar
                    animated
                    now={95}
                    label="uploading..."
                    style={{ height: "12px" }}
                  />
                )}
                {/* <br></br> */}
              </div>
            ))}
            {addImageButton === true ? (
              <div className="form-group px-3 py-2">
                <button
                  className="btn btn-primary btn-sm"
                  type="button"
                  onClick={handleAddImage}
                  disabled={true}
                >
                  Add Image
                </button>
              </div>
            ) : (
              <div className="form-group px-3 py-2">
                <button
                  className="btn btn-primary btn-sm"
                  type="button"
                  onClick={handleAddImage}
                >
                  Add Image
                </button>
              </div>
            )}

            {/* Submit Button */}
            <div className="form-group px-3 py-2">
              <button
                type="submit"
                className="btn bg-gradient-primary-login w-100 my-4 mb-2 btn1"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "UPDATE REPORT"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

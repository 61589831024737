// @ts-nocheck
import { useEffect, useRef, useState, useMemo } from "react";
import { loadModules } from "esri-loader";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  gisFeatureLayerFilter,
  gisList,
  landDetailsToggle,
  landParcelId,
  landSummaryToggle,
  siteFilterState,
} from "../../state/atom/gis";
import { mapCenter } from "../../state/atom/generic";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Http } from "../../utility/http";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { getUserDetails } from "../../utility/jwt";
import { RoleName } from "../../shared/generic.constant";
import { ROLES, ROLESNAME } from "../../shared/role.constant";
import { requestList } from "../../state/atom/request";

export interface Props {
  classList?: string;
  mapHeight?: string;
  showExtraButton: boolean;
}

export const GISMap = ({ classList, mapHeight, showExtraButton }: Props) => {
  const setLandSummaryToggle = useSetRecoilState(landSummaryToggle);
  const landRequest = useRecoilValue(requestList);
  const [transparency, setTransparency] = useState(false);
  const [toggleLabel, setToggleLabel] = useState(true);
  const setLandDetailsToggle = useSetRecoilState(landDetailsToggle);
  const request = useRecoilValue(gisList);
  const setLandParcelId = useSetRecoilState(landParcelId);
  const mapCenterPostion = useRecoilValue(mapCenter);
  const [sites, setSite] = useState([]);
  const [siteFilter, setSiteFilter] = useRecoilState(siteFilterState);
  const mapRef = useRef(null);
  let [gisFeatureLayerFilterData, setGisFeatureLayerFilter] = useRecoilState(
    gisFeatureLayerFilter
  );
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPdf, setPdfLoading] = useState(false);
  const measurementWidgetRef = useRef(null);
  const [view, setView] = useState(null);
  const filterRef = useRef(null);
  const { user }: JwtPayLoad = getUserDetails();
  const navigate = useNavigate();

  const createMap = useMemo(
    () => async () => {
      loadModules(
        [
          "esri/Map",
          "esri/views/MapView",
          "esri/layers/FeatureLayer",
          "esri/widgets/Measurement",
          "esri/widgets/BasemapGallery",
          "esri/widgets/Expand",
          "esri/widgets/Legend",
          "esri/layers/WebTileLayer",
          "esri/widgets/BasemapToggle",
          "esri/Basemap",
          "esri/layers/GraphicsLayer",
          "esri/symbols/TextSymbol",
        ],
        { css: true }
      )
        .then(
          ([
            Map,
            MapView,
            FeatureLayer,
            Measurement,
            BasemapGallery,
            Expand,
            Legend,
            WebTileLayer,
            BasemapToggle,
            Basemap,
            GraphicsLayer,
            TextSymbol,
          ]) => {
            const map = new Map({
              basemap: "satellite",
            });

            const view = new MapView({
              container: mapRef.current,
              map: map,
              zoom: 5,
              center: mapCenterPostion.center,
            });
            setView(view);

            /* Start point */
            // Create a GraphicsLayer to hold graphics
            const graphicsLayer = new GraphicsLayer();

            // Add the GraphicsLayer to the map
            map.add(graphicsLayer);
            /* const labelClass = {
              symbol: {
                type: "text",
                color: "white",
                font: {
                  size: 12,
                  weight: "bold",
                },
                haloColor: "black",
                haloSize: 1,
              },
              labelPlacement: "above-center",
              labelExpressionInfo: {
                expression: "$feature.name", 
              },
            };
            graphicsLayer.labelingInfo = [labelClass]; */

            landRequest.data.landRequest.forEach((marker) => {
              let markerSymbol = {
                type: "picture-marker",
                width: "12px",
                height: "12px",
              };

              if (marker.requestStatus === "pending") {
                markerSymbol.url = "images/pending-marker.png";
              }

              if (marker.requestStatus === "in_progress") {
                markerSymbol.url = "images/in-progress-marker.png";
              }
              if (marker.requestStatus === "acquisition") {
                markerSymbol.url = "images/acquisition-marker.png";
              }

              if (marker.requestStatus === "proposal_assigned") {
                markerSymbol.url = "images/proposal-assigned-marker.png";
              }

              const point = {
                type: "point",
                longitude: marker.locationLon,
                latitude: marker.locationLat,
              };

              var txtSym = new TextSymbol({
                text: marker.location,
                yoffset: 7,
                color: "#fff",
              });

              const markerGraphic = {
                geometry: point,
                symbol: markerSymbol,
                attributes: {
                  type: "picture",
                },
              };

              const textGraphic = {
                geometry: point,
                symbol: txtSym,
                attributes: {
                  type: "text",
                },
                visible: false,
              };

              graphicsLayer.add(textGraphic);
              graphicsLayer.add(markerGraphic);
            });

            view.watch("zoom", (zoomLevel) => {
              // Handle zoom change here
              graphicsLayer.graphics.forEach((pointGraphic) => {
                if (pointGraphic.attributes.type === "text") {
                  if (zoomLevel > 8) {
                    pointGraphic.visible = true;
                  } else {
                    pointGraphic.visible = false;
                  }
                }
              });
            });
            /* End point */

            const measurement = new Measurement({
              view: view,
              activeTool: null,
            });
            view.ui.add(measurement, "top-right");
            measurementWidgetRef.current = measurement;

            const featureLayer = new FeatureLayer({
              url: process.env.REACT_APP_ARC_GIS_URL,
              outFields: ["*"],
              definitionExpression: "site='not-exist'",
              labelingInfo: [
                {
                  labelPlacement: "below-right",
                  labelExpressionInfo: {
                    expression: "$feature.SurveyNo",
                  },
                  maxScale: 1,
                  minScale: 15000,
                },
              ],
            });
            filterRef.current = featureLayer;

            // Add widget to the bottom right corner of the view

            const districtBoundray = new FeatureLayer({
              url: process.env.REACT_APP_DISTRICT_BOUNDARY,
              outFields: ["*"],
            });
            map.add(districtBoundray);

            const stateBoundray = new FeatureLayer({
              url: process.env.REACT_APP_STATE_BOUNDARY,
              outFields: ["*"],
            });
            map.add(stateBoundray);

            const villageBoundray = new FeatureLayer({
              url: process.env.REACT_APP_VILLAGE_BOUNDARY,
              outFields: ["*"],
            });
            map.add(villageBoundray);

            const url = window.location.pathname;
            if(url==="/gis"){

              const legend = new Legend({
                view: view,
                layerInfos: [
                  {
                    layer: featureLayer,
                    title: "Land Parcel Status",
                  },
                ],
              });
              view.ui.add(legend, "bottom-right");
            }
            map.add(featureLayer);

            const GoogleStreets = new WebTileLayer({
              urlTemplate:
                "https://mts1.google.com/vt/lyrs=m@186112443&hl=x-local&src=app&x={col}&y={row}&z={level}&s=Galile",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });
            const goglesa = new WebTileLayer({
              urlTemplate:
                "https://mts1.google.com/vt/lyrs=s@186112443&hl=x-local&src=app&x={col}&y={row}&z={level}&s=Galile",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });

            const gogleSatellite = new WebTileLayer({
              urlTemplate:
                "https://mt1.google.com/vt/lyrs=s&hl=en&z={level}&x={col}&y={row}",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });

            const googlesate = new Basemap({
              baseLayers: [goglesa],
              thumbnailUrl: "images/google-hybrid.jpeg",
            });
            const toggle1 = new BasemapToggle(
              {
                //titleVisible: false,
                view: view,
                nextBasemap: googlesate,
              },
              "goglesat1"
            );

            const googlestrt = new Basemap({
              baseLayers: [GoogleStreets],
              thumbnailUrl: "images/google-street.jpeg",
            });
            const toggle2 = new BasemapToggle(
              {
                // titleVisible: false,
                view: view,
                nextBasemap: googlestrt,
              },
              "goglesat2"
            );

            const googleSate = new Basemap({
              baseLayers: [gogleSatellite],
              thumbnailUrl: "images/satellite.jpeg",
            });
            const toggle3 = new BasemapToggle(
              {
                // titleVisible: false,
                view: view,
                nextBasemap: googleSate,
              },
              "goglesat3"
            );

            //commented due to zoom to issue
            /* featureLayer
              .when(() => {
                return featureLayer.queryExtent();
              })
              .then((res) => {
                console.log("Zoom to extent 1")
                if (res.extent) {
                  view.goTo(res.extent).catch(function (error) {
                    console.error(error, "-----");
                  });
                }
              }); */

            for (const req of request.data.request) {
              if (req.landParcel.length) {
                for (const parcel of req.landParcel) {
                  const index = sites.findIndex(
                    (site) => site.value === parcel.site
                  );
                  if (index === -1) {
                    sites.push({
                      label: parcel.site,
                      value: parcel.site,
                      business: req.businessUnit.name,
                    });
                  }
                }
              }
            }

            console.log("sites", sites);
            if (sites.length) {
              sites.sort((a, b) => {
                const labelA = a.label.toUpperCase();
                const labelB = b.label.toUpperCase();

                if (labelA < labelB) {
                  return -1;
                }
                if (labelA > labelB) {
                  return 1;
                }
                return 0;
              });
              console.log("sitesSorted", sites);
              setSite(sites);
            }
            setIsLoading(false);

            view.on("click", (event) => {
              view.hitTest(event).then((response) => {
                const results = response.results;
                console.log("results", results);
                if (results.length > 0) {
                  const graphic = results[0].graphic;
                  console.log("graphic", graphic);
                  const parcelId = graphic.attributes
                    ? graphic.attributes.id
                    : 0;
                  //const parcelId = 4;
                  const url = window.location.pathname;
                  if (parcelId) {
                    setLandSummaryToggle(false);
                    setLandParcelId(parcelId);
                    setLandDetailsToggle(true);
                    if (url === "/dashboard") {
                      navigate("/gis");
                    }
                  }
                }
              });
            });
          }
        )
        .catch((error) =>
          console.error("Error loading the Esri modules:", error)
        );
    },
    [
      mapCenterPostion.center,
      navigate,
      request.data.request,
      setLandDetailsToggle,
      setLandParcelId,
      sites,
      setLandSummaryToggle,
      landRequest,
    ]
  );

  useEffect(() => {
    createMap();
  }, [createMap]);

  const toggleMeasurementType = (mesaureType) => {
    if (measurementWidgetRef.current) {
      measurementWidgetRef.current.activeTool = mesaureType;
    } else {
      measurementWidgetRef.current.clear();
    }
  };

  useEffect(() => {
    return () => {
      setSiteFilter("");
    };
  }, [setSiteFilter]);

  useEffect(() => {
    console.log("testttt");
    if (
      Object.values(gisFeatureLayerFilterData).filter((item) => item !== "")
        .length > 0
    ) {
      let definitionExpression = "";
      if (user.role === ROLES.abu_spoc) {
        definitionExpression = `AND abu_or_lem_user_id='${user.userId}'`;
      }
      if (user.role === ROLES.le_spoc) {
        definitionExpression = `AND le_spoc_user_id='${user.userId}'`;
      }
      if (user.role === ROLES.le_dh) {
        definitionExpression = `AND le_dh_user_id='${user.userId}'`;
      }

      for (const filter of Object.keys(gisFeatureLayerFilterData)) {
        if (gisFeatureLayerFilterData[filter]) {
          if (typeof gisFeatureLayerFilterData[filter] == "string") {
            definitionExpression += `AND ${filter}='${gisFeatureLayerFilterData[filter]}'`;
          } else {
            definitionExpression += `AND ${filter}=${gisFeatureLayerFilterData[filter]}`;
          }
        }
      }

      if (siteFilter) {
        definitionExpression += ` AND site='${siteFilter}'`;
      }

      if (definitionExpression) {
        definitionExpression = definitionExpression.slice(4);
      }
      console.log("definitionExpression3", definitionExpression);
      if (
        gisFeatureLayerFilterData.SurveyNo === "" &&
        gisFeatureLayerFilterData.Village === "" &&
        gisFeatureLayerFilterData.BusinessName === "" &&
        gisFeatureLayerFilterData.CompanyName === "" &&
        gisFeatureLayerFilterData.State === "" &&
        gisFeatureLayerFilterData.Site === "" &&
        gisFeatureLayerFilterData.Request_Id === "" &&
        siteFilter === ""
      ) {
        definitionExpression = "";
      }
      console.log("definitionExpression4", definitionExpression);
      if (view) {
        try {
          if (filterRef.current) {
            if (definitionExpression) {
              filterRef.current.definitionExpression = definitionExpression;
              filterRef.current
                .when(() => {
                  return filterRef.current.queryExtent();
                })
                .then((res) => {
                  if (res.extent) {
                    console.log("Zoom to extent 2");
                    view.goTo(res.extent).catch(function (error) {
                      console.error(error);
                    });
                  }
                });
            } else {
              filterRef.current.definitionExpression = "site='not-exist'";
              view.goTo({
                zoom: 5,
                center: mapCenterPostion.center,
              });

              console.log("i am in else");
            }
          } else {
            console.log("i am out");
          }
        } catch (e) {
          console.log("Error", e);
        }
      }
    } else {
      if (view) {
        filterRef.current.definitionExpression = "site='not-exist'";
        view.goTo({
          zoom: 5,
          center: mapCenterPostion.center,
        });
      }
    }
  }, [siteFilter, view, gisFeatureLayerFilterData, user.userId, user.role]);

  const exportKML = () => {
    setLoading(true);
    if (siteFilter) {
      Http.request(
        "get",
        `${process.env.REACT_APP_EXPORT_KML_URL}&Site=${siteFilter}`,
        null
      )
        .then((result) => {
          setLoading(false);
          if (
            process.env.REACT_APP_API_URL.indexOf("developerteam.xyz") === -1
          ) {
            console.log("Result", result);
            const kmlUrl = result.results[0].value.url;
            window.location.href = kmlUrl;
          } else {
            const kmlUrl =
              "https://media-lams.developerteam.xyz/proposal/0d7c71d69421deda8b15542687569b46.kml";
            window.location.href = kmlUrl;
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (str.length > 1) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str.charAt(0).toUpperCase();
    }
  };

  const printPDF = () => {
    setPdfLoading(true);
    if (siteFilter) {
      const site = sites.find((s) => s.value === siteFilter);
      console.log("user", user);
      const userName = `${capitalizeFirstLetter(
        user?.firstName
      )} ${capitalizeFirstLetter(user?.lastName)}`;
      console.log(
        `${process.env.REACT_APP_PRINT_URL}&site_name=${siteFilter}&business_unit=${site?.business}&user=${userName}`
      );
      //return;
      Http.request(
        "get",
        `${process.env.REACT_APP_PRINT_URL}&site_name=${siteFilter}&business_unit=${site?.business}&user=${userName}`,
        null
      )
        .then((result) => {
          setPdfLoading(false);
          if (
            process.env.REACT_APP_API_URL.indexOf("developerteam.xyz") === -1
          ) {
            console.log("Result", result);
            const kmlUrl = result.results[0].value.url;
            window.location.href = kmlUrl;
          } else {
            console.log("Result1");
            const kmlUrl = "https://www.africau.edu/images/default/sample.pdf";
            window.location.href = kmlUrl;
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log(error);
        });
    }
  };

  const setLabel = () => {
    const tglable = !toggleLabel;
    setToggleLabel(tglable);
    let nameClass = {
      labelPlacement: "below-right",
      labelExpressionInfo: {
        expression: tglable ? "$feature.SurveyNo" : "",
      },
    };
    filterRef.current.labelingInfo = [nameClass];
  };

  const manageRangeSlider = () => {
    var opacitySliderContainer = document.getElementById(
      "opacitySliderContainer"
    );
    var opacitySlider = document.getElementById("opacitySlider");
    var sliderHandle = document.getElementById("sliderHandle");

    opacitySliderContainer.addEventListener("input", function (event) {
      var opacityValue = event.target.value;
      filterRef.current.opacity = opacityValue;
      sliderHandle.style.left = opacityValue * 100 + "%";
    });

    // Initialize slider handle
    sliderHandle.style.left = filterRef.current.opacity * 100 + "%";
  };

  return (
    <>
      <div
        ref={mapRef}
        id="viewDiv"
        style={{ height: mapHeight || "100vh" }}
        className={classList}
      >
        {showExtraButton && (
          <>
            <div className="site-filter">
              <Select
                className="site-filter-box"
                placeholder="Select Site"
                isClearable
                isLoading={isLoading}
                options={sites}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSiteFilter(selectedOption.value);
                    setGisFeatureLayerFilter({ Site: selectedOption.value });
                  } else {
                    setSiteFilter(``);
                    setGisFeatureLayerFilter({
                      ...gisFeatureLayerFilterData,
                      Site: "",
                    });
                    /* filterRef.current
                      .when(() => {
                        return filterRef.current.queryExtent();
                      })
                      .then((res) => {
                        console.log("zoom to extent 3")
                        if (res.extent) {
                          view.goTo(res.extent);
                        }
                      }); */
                  }
                }}
              />
              {/* <button className="btn-grad gis-button">
                <i className="material-icons opacity-10 pe-1">print</i>
                <span className="dashboard-icons mt-1">Print</span>
              </button> */}
              <button
                className={
                  "btn-grad gis-button " + (siteFilter ? "" : "disabled")
                }
                disabled={siteFilter ? false : true}
                onClick={printPDF}
              >
                {!loadingPdf && (
                  <i className="material-icons opacity-10 pe-1">print</i>
                )}
                {loadingPdf && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="dashboard-icons mt-1">&nbsp;Print</span>
              </button>

              <button
                className={
                  "btn-grad gis-button " + (siteFilter ? "" : "disabled")
                }
                disabled={siteFilter ? false : true}
                onClick={exportKML}
              >
                {!loading && (
                  <i className="material-icons opacity-10 pe-1">
                    file_download
                  </i>
                )}
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="dashboard-icons mt-1">&nbsp;KML/KMZ</span>
              </button>
            </div>

            <div
              id="base-map-container"
              className={"base-map-container "}
            ></div>

            <div className="measurement-area">
              <Select
                className="mesaure-box"
                placeholder="Measure"
                isClearable
                options={[
                  {
                    label: "Distance",
                    value: "distance",
                  },
                  {
                    label: "Area",
                    value: "area",
                  },
                ]}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    toggleMeasurementType(selectedOption.value);
                  } else {
                    toggleMeasurementType(null);
                  }
                }}
              />
            </div>

            <div id="opacitySliderContainer" className="trans-slider">
              <button
                className="btn-grad gis-button"
                onClick={() => setTransparency(!transparency)}
              >
                <span className="gis-label">Transparency</span>
                <span title="Transparency" className="material-icons gis-icon">
                  content_paste_search
                </span>
              </button>
              {transparency && (
                <>
                  <input
                    type="range"
                    onChange={manageRangeSlider}
                    min="0"
                    max="1"
                    step="0.01"
                    defaultValue="1"
                    id="opacitySlider"
                  />
                  <div id="sliderHandle"></div>
                </>
              )}
            </div>

            <button
              className="btn-grad label-button"
              onClick={() => {
                setLabel();
              }}
            >
              <span className="gis-label">Label</span>
              <span title="Label" className="material-icons gis-icon">
                label
              </span>
            </button>

            <div className="gis-map-type">
              <div className="dropdown">
                <div className="nav-item dropdown align-items-center pe-2">
                  <Link
                    className="nav-link font-weight-bold d-flex text-white dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <i className="material-icons opacity-10 pe-1">public</i>
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    style={{ minWidth: "90px" }}
                  >
                    <li className="map-type-li">
                      <Link id="goglesat1">
                        <span></span>
                      </Link>
                    </li>
                    <li className="map-type-li">
                      {" "}
                      <Link id="goglesat2">
                        <span></span>
                      </Link>
                    </li>
                    <li className="map-type-li">
                      <Link id="goglesat3">
                        <span></span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

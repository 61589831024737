import { useRecoilState, useRecoilValue } from "recoil";
import { misReport, reportToggle } from "../../state/atom/report";
import { ExportXlsx } from "./export-xlsx";
import { ExportPdf } from "./export-pdf";

export const MisList = () => {
  const [repoToggle, setReportToggle] = useRecoilState(reportToggle);
  const misReportData = useRecoilValue(misReport);
  return (
    <>
      <div className="box center-box">
        <div
          className=" a-link request-list-corner-btn request-list-corner-btn--collapsed "
          id="request-list-corner-btn"
          onClick={() => {
            setReportToggle(!repoToggle);
          }}
        >
          <span
            style={{ padding: "0 5px" }}
            className="nav-link font-weight-bold d-flex text-white corner-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons">MIS List</span>
          </span>
        </div>
        <div
          className={
            "request-list-init " +
            (!repoToggle ? "request-list-init--collapsed" : "")
          }
          style={{ left: "452px", width: "65%", overflowY: "scroll" }}
        >
          <div
            className="text-right panel-title mb-0"
            style={{
              background: "#375db1",
              alignItems: "center",
              position: "sticky",
              top: "0",
            }}
          >
            <span
              style={{ color: "white", fontSize: "24px", paddingLeft: "20px" }}
            >
              Existing MIS List
            </span>
            <div className="form-group d-flex justify-content-end  mx-5 my-2">
              <ExportXlsx key="xlsx"></ExportXlsx> &nbsp;&nbsp;
              <ExportPdf key="pdf"></ExportPdf>
            </div>
            <span
              className="close-btn"
              id="request-list-close"
              onClick={() => {
                setReportToggle(!repoToggle);
              }}
            >
              &times;
            </span>
          </div>
          <div>
            <div
              className="table-responsive p-0" /* style={{ maxHeight: "80vh" }} */
            >
              <table className="table align-items-center justify-content-center mb-0 text-white ">
                <thead
                  style={{ background: "#47a0e7d9", color: "white" }}
                  className="table-head"
                >
                  <tr>
                    <th className="text-uppercase text-xxs font-weight-bolder">
                      BUSINESS
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                      COMPANY CODE
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      PLANT CODE
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      REQUEST ID
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      SITE NAME
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      STATE
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      DISTRICT
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      TEHSIL
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      VILLAGE
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      SELLERS
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      SURVEY NO
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      AREA (in ACRES)
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Free Hold Area
                    </th>
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Lease Area
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Used Area
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Company Code Sap Code
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Aggregator Land Spoc
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Nick Name Company
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Old Name of Company
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Type Of Conveyance Deed
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Conveyance Deed No
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Registration Date
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Sub Lease To Company
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Sub Lease To Company Code
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Sub Lease From Company
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      SubLease From Company Code
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Ceiling Permission
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Revenue Map Status
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Lease Period Start Date
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Lease Period End Date
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Total Mortgaged Area
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      File No
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Mortgaged At
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Mortgage Start Date
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Mortgage End Date
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Litigation
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Case No
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Court Name With Address
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Remarks
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Availability Of Photocopy
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Storage Location
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Floor
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Floor Type
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Racks
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Shelf No
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Gis Mapping Status
                    </th>

                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Survey Status
                    </th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "14px" }}>
                  {misReportData?.data?.landRequest.map(
                    (req: any, index: number) => {
                      return req.landParcel.map(
                        (parcel: any, index: number) => {
                          return (
                            <tr key={parcel.id}>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.business?.name}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel.companyCode}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel.plantCode}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {req?.requestName}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.site}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.stateData?.stateName}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.districtData?.districtName}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.cityData?.city}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.village}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.sellerName}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.surveyNo}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.parcelArea}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.freeHoldArea}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.leaseArea}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.usedArea}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.companyCodeSapCode}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.aggregatorLandSpoc}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.nickNameCompany}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.oldNameofCompany}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.typeOfConveyanceDeed}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.conveyanceDeedNo}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.registrationDate}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.subLeaseToCompany}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.subLeaseToCompanyCode}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.subLeaseFromCompany}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.subLeaseFromCompanyCode}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.ceilingPermission}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.revenueMapStatus}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.leasePeriodStartDate}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.leasePeriodEndDate}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.totalMortgagedArea}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.fileNo}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.mortgagedAt}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.mortgageStartDate}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.mortgageEndDate}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.litigation}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.caseNo}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.courtNameWithaddress}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.remarks}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.availabilityOfPhotocopy}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.storageLocation}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.floor}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.floorType}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.racks}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.shelfNo}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.statusOfGisMapping}
                                </p>
                              </td>

                              <td>
                                <p className="text-sm font-weight-bold mb-0">
                                  {parcel?.statusOfSurvey}
                                </p>
                              </td>
                            </tr>
                          );
                        }
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

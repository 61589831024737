// imports
import { useRecoilState, useRecoilValue } from "recoil";
import { acquistionStatusToggle } from "../../state/atom/proposal";
import { requestId } from "../../state/atom/request";
import { useEffect } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { landSummary } from "../../state/atom/gis";
import ReactECharts from "echarts-for-react";
import { fixNumber } from "../../shared/generic.constant";
// Component displaying Acquistation dtatus.
export const AcquistionStatus = () => {
  // state and hooks
  const [acqStatusToggle, setAcqStatusToggle] = useRecoilState(
    acquistionStatusToggle
  );
  const reqId = useRecoilValue(requestId);
  const [landSummaryData, setLandSummary] = useRecoilState(landSummary);
  useEffect(() => {
    if (reqId) {
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}land-parcel/details/${reqId}`,
        null,
        apiHeaders()
      )
        .then((result) => {
          setLandSummary(result.data.summary);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [reqId, setLandSummary]);

  const option = {
    /* title: {
      text: "",
    },
    tooltip: {},
    legend: {
      data: ["销量"],
    }, */
    xAxis: {
      data: ["Area Required ", "Sale Deed", "Mutation", "NA"],
      axisLabel: {
        rotate: 30,
        color: "#fff",
      },
    },
    yAxis: {
      axisLabel: {
        color: "#fff",
      },
      axisLine: {
        lineStyle: {
          type: "dashed",
        },
      },
    },
    series: [
      {
        name: "Area in Acres",
        type: "bar",
        data: [
          landSummaryData.totalAreaRequired,
          landSummaryData.totalSalesDeed,
          landSummaryData.totalMutationStatus,
          landSummaryData.totalNAStatus,
        ],
        barWidth: "20%",
        color: "rgba(255, 255, 255, .8)",
      },
    ],
  };

  return (
    <>
      <div className="box">
        <div
          className={
            "analysis-detail-init " +
            (!acqStatusToggle ? "analysis-detail-init--collapsed" : "")
          }
        >
          <div
            className="text-right panel-title"
            style={{ background: "#375db1" }}
          >
            <span style={{ color: "white", fontSize: "24px" }}>
              Acquisition Status
            </span>
            <span
              className="close-btn"
              id="analysis-detail-close"
              onClick={() => {
                setAcqStatusToggle(!acqStatusToggle);
              }}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "85vh" }}>
            <h6 className="mb-0 panel-header">Acquisition Status</h6>
            <h6 className="text-uppercase text-white font-weight-bolder mt-3 ps-2 panel-sub-title">
              Statistics
            </h6>
            <table className="table">
              <tbody>
                <tr>
                  <th>Total Area Required</th>
                  <td>{fixNumber(landSummaryData.totalAreaRequired)}</td>
                </tr>
                <tr>
                  <th>Total Area Acquired </th>
                  <td>{fixNumber(landSummaryData.totalAreaAccquired)}</td>
                </tr>
                <tr>
                  <th>Total Sale deed</th>
                  <td>{landSummaryData.totalSalesDeed}</td>
                </tr>
                <tr>
                  <th>Mutation Status</th>
                  <td>{landSummaryData.totalMutationStatus}</td>
                </tr>
                <tr>
                  <th>NA Status</th>
                  <td>{landSummaryData.totalNAStatus}</td>
                </tr>
                <tr>
                  <th>TSR Status</th>
                  <td>{landSummaryData.totalTSRStatus}</td>
                </tr>
                <tr>
                  <th>Free Hold Area</th>
                  <td>{fixNumber(landSummaryData.totalFreeHoldArea)}</td>
                </tr>
                <tr>
                  <th>Lease Area </th>
                  <td>{fixNumber(landSummaryData.totalLeaseArea)}</td>
                </tr>
              </tbody>
            </table>
            <h6 className="text-uppercase text-white font-weight-bolder mt-3 ps-2 panel-sub-title">
              Chart
            </h6>
            <div className="acq-staus-chart">
              <ReactECharts option={option} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// imports
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { useRecoilState, useSetRecoilState } from "recoil";
import { toaster } from "../../state/atom/toaster";
import {
  aggregatorToggle,
  updateAggregatorList,
} from "../../state/atom/aggregator";
import { ProgressBar } from "react-bootstrap";
import { InfoBox } from "../info-hover";

// interfaces
interface AggregatorRegisterFormInputs {
  firstName: string;
  lastName: string;
  address?: string;
  phoneNumber: string | undefined;
  email: string;
  aadharNumber?: string;
  bankName?: string;
  accHolderName?: string;
  ifscCode?: string;
  accountNumber?: string;
  panNumber?: string;
  workExperience?: number;
  gstNumber?: string;
  itr_1?: string;
  itr_2?: string;
  itr_3?: string;
}

// Component displaying a Aggregator registration form.
export const AggregatorRegistration = () => {
  // Validation schema for input field
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test(
        "is-10-digits",
        "Phone Number must be 10 digits",
        (value) => value.toString().length === 10
      ),
    email: Yup.string()
      .email("Invalid email format"),
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<AggregatorRegisterFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  // state and hooks
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [toasterData, setToasterStatus] = useRecoilState(toaster);
  const [aggToggle, setAggregatorToggle] = useRecoilState(aggregatorToggle);
  const [itr1File, setITR1File] = useState("");
  const [itr2File, setITR2File] = useState("");
  const [itr3File, setITR3File] = useState("");
  const [itr1FileUploadProgress, setITR1FileUploadProgress] = useState(false);
  const [itr2FileUploadProgress, setITR2FileUploadProgress] = useState(false);
  const [itr3FileUploadProgress, setITR3FileUploadProgress] = useState(false);
  const setUpdateAggregatorList = useSetRecoilState(updateAggregatorList);

  /**
  Handles the form submission when the user clicks the submit button.
  This function performs an API call to create registration of aggregator.
  @param {AggregatorRegisterFormInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: AggregatorRegisterFormInputs) => {
    data.itr_1 = itr1File || "";
    data.itr_2 = itr2File || "";
    data.itr_3 = itr3File || "";

    
    const cleanedData = removeEmptyValues(data);

    if (cleanedData.workExperience) {
      cleanedData.workExperience = Number(cleanedData.workExperience);
    }

   
    // Make a POST request to the registration aggregator
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}aggregator`,
      cleanedData,
      apiHeaders()
    )
      .then((result: any) => {
        // Hide the Aggregator form
        setAggregatorToggle(false);

        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Aggregator",
          text: "Aggregator registered successfully",
        });

        //reset thr form fields
        reset();
        setSubmitErrorMessage("");
        setUpdateAggregatorList(true);
      })
      .catch((error: any) => {
        // Set an error message if the API request fails
        setSubmitErrorMessage(error.response.data.message);
      });
  };

  const removeEmptyValues = (obj: any) => {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (
        newObj[key] === "" ||
        newObj[key] === null ||
        newObj[key] === undefined
      ) {
        delete newObj[key];
      }
    }
    return newObj;
  };

  // Handle ITR 1 file change
  const itr1FileChanges = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setITR1FileUploadProgress(true);

    // Make a POST request to the ITR 1 File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/aggergator/itr`,
      formData
    )
      .then((result: any) => {
        setITR1File(result.fileName);
        setITR1FileUploadProgress(false);
      })
      .catch((error: any) => {
        setITR1FileUploadProgress(false);
      });
  };

  // Handle ITR 2 file change
  const itr2FileChanges = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setITR2FileUploadProgress(true);

    // Make a POST request to the ITR 2 File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/aggergator/itr`,
      formData
    )
      .then((result: any) => {
        setITR2File(result.fileName);
        setITR2FileUploadProgress(false);
      })
      .catch((error: any) => {
        setITR2FileUploadProgress(false);
      });
  };

  // Handle ITR 3 file change
  const itr3FileChanges = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setITR3FileUploadProgress(true);

    // Make a POST request to the ITR 3 File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/aggergator/itr`,
      formData
    )
      .then((result: any) => {
        setITR3File(result.fileName);
        setITR3FileUploadProgress(false);
      })
      .catch((error: any) => {
        setITR3FileUploadProgress(false);
      });
  };

  // prevent the onwheel change
  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
  };

  return (
    <>
      <div
        className={
          "new-request-init new-aggerator-popup align-self-end " +
          (!aggToggle ? "new-request-init--collapsed" : "")
        }
      >
        <div
          className="text-left panel-title sticky-heading"
          style={{ background: "#375db1" }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "40px" }}
          >
            Aggregator Registration{" "}
          </span>
          <span
            className="close-btn"
            id="new-request-close"
            onClick={() => {
              setAggregatorToggle(false);
              clearErrors();
            }}
          >
            &times;
          </span>
        </div>
        <div style={{ overflowY: "auto" }}>
          <h6 className="mb-0 panel-header">
            Fill all the details for Aggregator registration.
          </h6>
          {submitErrorMessage && (
            <div className="text-center">
              <span className="text-warning">{submitErrorMessage}</span>
            </div>
          )}

          <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
            {/* First Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                {...register("firstName")}
                placeholder="for example Rakesh"
              />

              {errors.firstName && (
                <span className="text-warning">{errors.firstName.message}</span>
              )}
            </div>

            {/* Last Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="lastName">Last Name*</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                {...register("lastName")}
                placeholder="for example Sharma"
              />

              {errors.lastName && (
                <span className="text-warning">{errors.lastName.message}</span>
              )}
            </div>

            {/* Phone Number */}
            <div className="form-group px-3 py-2">
              <label htmlFor="phoneNumber">Phone Number*</label>
              <input
                type="number"
                className="form-control"
                id="phoneNumber"
                {...register("phoneNumber")}
                onWheel={numberInputOnWheelPreventChange}
                placeholder="for example 9588956898"
              />

              {errors.phoneNumber && (
                <span className="text-warning">
                  {errors.phoneNumber.message}
                </span>
              )}
            </div>

            {/* Address */}
            <div className="form-group px-3 py-2">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                {...register("address")}
                placeholder="for example 01, xyz- ahmedabad 563255"
              />

              {errors.address && (
                <span className="text-warning">{errors.address.message}</span>
              )}
            </div>

            {/* Email */}
            <div className="form-group px-3 py-2">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                {...register("email")}
                placeholder="for example jon@gmail.com"
              />

              {errors.email && (
                <span className="text-warning">{errors.email.message}</span>
              )}
            </div>

            {/* Aadhar Number */}
            <div className="form-group px-3 py-2">
              <label htmlFor="aadharNumber">Aadhar Number</label>
              <input
                type="number"
                className="form-control"
                id="aadharNumber"
                {...register("aadharNumber")}
                onWheel={numberInputOnWheelPreventChange}
                placeholder="for example 896556895989"
              />

              {errors.aadharNumber && (
                <span className="text-warning">
                  {errors.aadharNumber.message}
                </span>
              )}
            </div>

            {/* Bank Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="bankName">Bank Name</label>
              <input
                type="text"
                className="form-control"
                id="bankName"
                {...register("bankName")}
                placeholder="for example HDFC bank"
              />

              {errors.bankName && (
                <span className="text-warning">{errors.bankName.message}</span>
              )}
            </div>

            {/* Account Holder Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="accHolderName">Account Holder Name</label>
              <input
                type="text"
                className="form-control"
                id="accHolderName"
                {...register("accHolderName")}
                placeholder="for example Rakesh Sharma"
              />

              {errors.accHolderName && (
                <span className="text-warning">
                  {errors.accHolderName.message}
                </span>
              )}
            </div>

            {/* IFSC Code */}
            <div className="form-group px-3 py-2">
              <label htmlFor="ifscCode">IFSC Code</label>
              <input
                type="text"
                className="form-control"
                id="ifscCode"
                {...register("ifscCode")}
                placeholder="for example HDFC0002003"
              />

              {errors.ifscCode && (
                <span className="text-warning">{errors.ifscCode.message}</span>
              )}
            </div>

            {/* Account Number */}
            <div className="form-group px-3 py-2">
              <label htmlFor="accountNumber">Account Number</label>
              <input
                type="text"
                className="form-control"
                id="accountNumber"
                {...register("accountNumber")}
                placeholder="for example 568956689696858"
              />

              {errors.accountNumber && (
                <span className="text-warning">
                  {errors.accountNumber.message}
                </span>
              )}
            </div>

            {/* PAN Number */}
            <div className="form-group px-3 py-2">
              <label htmlFor="panNumber">PAN Number</label>
              <input
                type="text"
                className="form-control"
                id="panNumber"
                {...register("panNumber")}
                placeholder="for example JQP45987SD"
              />

              {errors.panNumber && (
                <span className="text-warning">{errors.panNumber.message}</span>
              )}
            </div>

            {/* Work Experience */}
            <div className="form-group px-3 py-2">
              <label htmlFor="workExperience">Work Experience (In year)</label>
              <input
                type="number"
                className="form-control"
                id="workExperience"
                {...register("workExperience")}
                onWheel={numberInputOnWheelPreventChange}
                placeholder="for example 2"
              />

              {errors.workExperience && (
                <span className="text-warning">
                  {errors.workExperience.message}
                </span>
              )}
            </div>

            {/* GST Number */}
            <div className="form-group px-3 py-2">
              <label htmlFor="gstNumber">GST Number</label>
              <input
                type="text"
                className="form-control"
                id="gstNumber"
                {...register("gstNumber")}
                placeholder="for example GSTIN5896256"
              />

              {errors.gstNumber && (
                <span className="text-warning">{errors.gstNumber.message}</span>
              )}
            </div>

            {/* ITR 1 */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                ITR 1 (PDF)
              </label>
              &nbsp;
              <InfoBox data={"Attach PDF Document."}></InfoBox>
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="itr_1"
                {...register("itr_1")}
                onChange={itr1FileChanges}
              />
              {errors.itr_1 && (
                <span className="text-warning">{errors.itr_1.message}</span>
              )}
              {itr1FileUploadProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* ITR 2 */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                ITR 2 (PDF)
              </label>
              &nbsp;
              <InfoBox data={"Attach PDF Document."}></InfoBox>
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="itr_2"
                {...register("itr_2")}
                onChange={itr2FileChanges}
              />
              {errors.itr_2 && (
                <span className="text-warning">{errors.itr_2.message}</span>
              )}
              {itr2FileUploadProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* ITR 3 */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                ITR 3 (PDF)
              </label>
              &nbsp;
              <InfoBox data={"Attach PDF Document."}></InfoBox>
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="itr_3"
                {...register("itr_3")}
                onChange={itr3FileChanges}
              />
              {errors.itr_3 && (
                <span className="text-warning">{errors.itr_3.message}</span>
              )}
              {itr3FileUploadProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* Submit button */}
            <div className="form-group px-3 py-2">
              <button
                type="submit"
                className="btn bg-gradient-primary-login w-100 my-4 mb-2 btn1"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

// @ts-nocheck
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useRecoilValue } from "recoil";
import { misReport } from "../../state/atom/report";
import logo from "./../../assets/img/logo.jpg";
import { getUserDetails } from "../../utility/jwt";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { ROLESNAME } from "../../shared/role.constant";
import moment from "moment";
import { fixNumber } from "../../shared/generic.constant";
import { gisFeatureLayerFilter } from "../../state/atom/gis";

export const ExportPdf = () => {
  const { user }: JwtPayLoad = getUserDetails();
  const misReportData = useRecoilValue(misReport);
  let gisFeatureLayerFilterData = useRecoilValue(gisFeatureLayerFilter);
  const landParcelData: any = [];

  const mainSummaryData: any = [];
  const totalRequest = misReportData.data.landRequest.length;
  let totalProposal = 0;
  let totalAreRequired = 0;
  let totalSalesDeed = 0;
  let totalMutation = 0;
  let totalNa = 0;
  let i = 1;
  let landObject: any = {};
  misReportData.data.landRequest.forEach((request: any) => {
    totalProposal += request.proposals.length;
    totalAreRequired += request.area;
    for (const landParcel of request.landParcel) {
      landObject = {};
      if (landParcel.saleDeedStatus === "YES") {
        totalSalesDeed += Number(landParcel.parcelArea);
      }
      if (landParcel.mutationStatus === "YES") {
        totalMutation += Number(landParcel.parcelArea);
      }
      if (landParcel.naStatus === "YES") {
        totalNa += Number(landParcel.parcelArea);
      }

      landObject.sNo = i;
      landObject.siteName = landParcel.site;
      landObject.business = landParcel.business.name;
      landObject.fullNameCompany = landParcel.company.companyName;
      landObject.nickNameCompany = landParcel.company.companyName;
      landObject.state = landParcel.stateData.stateName;
      landObject.district = landParcel.districtData.districtName;
      landObject.tehsil = landParcel.cityData.city;
      landObject.village = landParcel.village;
      landObject.sellersName = landParcel.sellerName;
      landObject.surveyNo = landParcel.surveyNo;
      landObject.area = landParcel.parcelArea;
      landObject.landType = landParcel.landType;
      landObject.typeOfConveyanceDeed = "NA";
      landObject.saleDeedStatus = landParcel.saleDeedStatus;
      landObject.mutationStatus = landParcel.mutationStatus;
      landObject.nAStatus = landParcel.naStatus;
      landObject.finalTSRStatus = landParcel.tsrStatus;
      landParcelData.push(landObject);
      i += 1;
    }
  });
  console.log("landParcelData", landParcelData);

  const landParcelColumn: any = [
    { Header: "S.No", accessor: "sNo" },
    { Header: "Site Name", accessor: "siteName" },
    { Header: "Business", accessor: "business" },
    { Header: "Full Name of Company", accessor: "fullNameCompany" },
    { Header: "Company Nickname", accessor: "nickNameCompany" },
    { Header: "State", accessor: "state" },
    { Header: "District", accessor: "district" },
    { Header: "Tehsil", accessor: "tehsil" },
    { Header: "Village", accessor: "village" },
    { Header: "Sellers Name", accessor: "sellersName" },
    { Header: "Survey No", accessor: "surveyNo" },
    { Header: "Area (Acre)", accessor: "area" },
    { Header: "Category of Land", accessor: "landType" },
    { Header: "Type of Conveyance Deed", accessor: "typeOfConveyanceDeed" },
    { Header: "Sale deed Status", accessor: "saleDeedStatus" },
    { Header: "Mutation Status", accessor: "mutationStatus" },
    { Header: "NA Status", accessor: "nAStatus" },
    { Header: "Final TSR Status", accessor: "finalTSRStatus" },
  ];

  mainSummaryData.push({
    totolRequest: fixNumber(totalRequest),
    totalProposal: fixNumber(totalProposal),
    totalAreRequired: fixNumber(totalAreRequired),
    totalSalesDeed: fixNumber(totalSalesDeed),
    totalMutation: fixNumber(totalMutation),
    totalNa: fixNumber(totalNa),
  });

  const mainSummaryColumn: any = [
    { Header: "Total Request", accessor: "totolRequest" },
    { Header: "Total Proposals", accessor: "totalProposal" },
    { Header: "Total Area Required", accessor: "totalAreRequired" },
    { Header: "Total Sale Deed", accessor: "totalSalesDeed" },
    { Header: "Total Mutation", accessor: "totalMutation" },
    { Header: "Total NA", accessor: "totalNa" },
  ];

  const exportToPDF = async () => {
    const doc = new jsPDF("l", "mm", "a3");
    const properties = {
      title: "Mis Report",
    };
    doc.setProperties(properties);

    const img = new Image();
    img.src = logo;
    let startY = 10;
    const labelColor = [55, 93, 177];
    img.onload = () => {
      /* Cover Page */
      const imgWidth = 200; // Adjust as needed
      const imgHeight = (img.height * imgWidth) / img.width;

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();

      const xPos = (pdfWidth - imgWidth) / 2;
      const yPos = (pdfHeight - imgHeight) / 2 - 50;

      startY = yPos + imgHeight - 20;

      doc.addImage(img, "jpeg", xPos, yPos, imgWidth, imgHeight);

      /* Set Font */
      doc.setFont("Adani-Regular"); // Set the font for the document
      /* !End of set font */

      doc.setFontSize(16);
      doc.setTextColor(labelColor[0], labelColor[1], labelColor[2]);
      let text = "Adani Land Acquisition Management System (LAMS) Report";
      let textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      let xPosition = (doc.internal.pageSize.getWidth() - textWidth) / 2;
      doc.text(text, xPosition, startY).setFont(undefined, "bold");
      startY += 14;

      doc.setFontSize(14);
      doc.setTextColor(labelColor[0], labelColor[1], labelColor[2]);
      const exportedBy = `${user?.firstName} ${user?.lastName}`;
      text = `Exported By: ${exportedBy}`;
      textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      xPosition = (doc.internal.pageSize.getWidth() - textWidth) / 2 - 15;
      doc.text("Exported By:", xPosition, startY);
      doc.setTextColor(100);
      doc.text(exportedBy, xPosition + 40, startY);
      startY += 7;

      doc.setFontSize(14);
      doc.setTextColor(labelColor[0], labelColor[1], labelColor[2]);
      const userType = `${ROLESNAME[user?.role]}`;
      text = `User Type: ${userType}`;
      textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      doc.text("User Type:", xPosition, startY);
      doc.setTextColor(100);
      doc.text(userType, xPosition + 40, startY);
      startY += 7;

      doc.setFontSize(14);
      doc.setTextColor(labelColor[0], labelColor[1], labelColor[2]);
      const date = moment().format("MMM D YYYY, h:mm a");
      text = `Date: ${date}`;
      textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      doc.text("Date:", xPosition, startY);
      doc.setTextColor(100);
      doc.text(date, xPosition + 40, startY);
      startY += 7;

      /* Cover Page */

      doc.addPage();
      startY = 20;
      doc.setFontSize(15);
      doc.text("Brief Summary", 15, startY);
      startY += 10;
      const mainSummaryColumnNames = mainSummaryColumn.map(
        (column: any) => column.Header
      );
      const mainSummaryDataRows = mainSummaryData.map((item: any) =>
        mainSummaryColumn.map((column: any) => item[column.accessor])
      );
      doc.autoTable({
        head: [mainSummaryColumnNames],
        body: mainSummaryDataRows,
        startY: startY,
        styles: { font: "Adani-Regular" },
        headStyles: {
          fillColor: [labelColor[0], labelColor[1], labelColor[2]],
        },
      });
      startY += 60;

      /* Add Chart */
      const data = [
        fixNumber(totalRequest),
        fixNumber(totalProposal),
        fixNumber(totalAreRequired),
        fixNumber(totalSalesDeed),
        fixNumber(totalMutation),
        fixNumber(totalNa),
      ];
      const labels = [
        "Request",
        "Proposal",
        "Area Required",
        "Sale Deed",
        "Mutation",
        "NA",
      ];

      const maxDataValue = Math.max(...data);
      const chartHeight = 50;
      const barWidth = 15;
      const spaceBetweenBars = 30;

      const startX = 85;

      doc.setFontSize(12);
      /* doc.text(startX, startY, "GRAPH"); */

      for (let i = 0; i < data.length; i++) {
        const x = startX + i * (barWidth + spaceBetweenBars);
        const barHeight = (data[i] / maxDataValue) * chartHeight;

        doc.setFillColor(55, 93, 177); // Set random fill color
        doc.rect(
          x,
          startY + chartHeight - barHeight,
          barWidth,
          barHeight,
          "DF"
        );
        doc.text(
          x + barWidth / 2 - 5,
          startY + chartHeight + 10,
          `${labels[i]}(${data[i]})`,
          null,
          null,
          "center"
        );
      }
      /* End of Chart */

      doc.addPage();
      startY = 20;
      doc.setFontSize(15);
      doc.text("Land Parcel", 15, startY);
      startY += 10;
      const landParcelColumnNames = landParcelColumn.map(
        (column: any) => column.Header
      );
      const landParcelDataRows = landParcelData.map((item: any) =>
        landParcelColumn.map((column: any) => item[column.accessor])
      );
      doc.autoTable({
        head: [landParcelColumnNames],
        body: landParcelDataRows,
        startY: startY,
        styles: { font: "Adani-Regular" },
        headStyles: {
          fillColor: [labelColor[0], labelColor[1], labelColor[2]],
        },
      });

      const fileName=gisFeatureLayerFilterData?.site
      ? gisFeatureLayerFilterData?.site:"Mis Report";
      doc.save(`${fileName}.pdf`);
    };

    //doc.save("Mis Report.pdf");
  };

  return (
    <>
      <button
        type="button"
        onClick={exportToPDF}
        className="btn bg-gradient-primary-login btn1"
      >
        EXPORT in PDF
      </button>
    </>
  );
};

// states
import { useState, useEffect } from "react";
import AnonymousLayout from "../layout/anonymous-layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Http } from "../utility/http";
import { useSetRecoilState } from "recoil";
import { associatedEmail } from "../state/atom/user";

// interfaces
interface ForgotPasswordForm {
  email: string;
}

// component showing the forgot password input field.
export const ForgotPassword = () => {

  // state and hooks
  const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] =
    useState("");
  const [loading, setLoading] = useState(false);
  const setAssociatedEmailID = useSetRecoilState(associatedEmail);
  let navigate = useNavigate();

  // validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("E-mail is required")
      .email("Enter valid e-mail"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (forgotPasswordErrorMessage && errors.email) {
      setForgotPasswordErrorMessage("");
    }
  }, [forgotPasswordErrorMessage, errors.email]);

  /**
  Handles the form submission when the user clicks the get OTP button.
  This function performs an API call to get otp for reset password
  @param {ForgotPasswordForm} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: ForgotPasswordForm) => {
    setAssociatedEmailID(data.email);
    setLoading(true);
    setForgotPasswordErrorMessage("");

    // Make POST request for forgot password API end point.
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}auth/forgot-password`,
      data
    )
      .then((res) => {
        setLoading(false);
        
        // Otp is sended on associate email and redirect on reset password form.
        navigate("/reset-password");
      })
      .catch((err) => {
        setLoading(false);
        // Set an error message if the API request fails
        setForgotPasswordErrorMessage(err.response.data.message);
      });
  };

  return (
    <AnonymousLayout>
      <>
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-12 mx-auto">
              <div className="card z-index-0 fadeIn3 fadeInBottom card1">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-gradient-primary-login shadow-primary border-radius-lg py-3 pe-1">
                    <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">
                      Forgot Password
                    </h4>
                  </div>
                </div>
                {forgotPasswordErrorMessage && (
                  <span className="mt-2 mb-0 text-center text-danger">
                    {forgotPasswordErrorMessage}
                  </span>
                )}
                <div className="card-body">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="text-start"
                  >
                    {/* Email */}
                    <div
                      className={`input-group input-group-outline ${
                        errors.email ? "mb-1" : "mb-3"
                      } `}
                    >
                      <input
                        type="email"
                        className="form-control"
                        {...register("email")}
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="mb-3">
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                    
                    {/* Send OTP button */}
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn bg-gradient-primary-login w-100 my-4 mb-2 btn1"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Send OTP"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </AnonymousLayout>
  );
};

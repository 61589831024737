import { Link } from "react-router-dom";
import { landSummary, landSummaryToggle } from "../../state/atom/gis";
import { useRecoilState, useRecoilValue } from "recoil";
import { fixNumber } from "../../shared/generic.constant";
import { useEffect, useState } from "react";

export const LandSummary = () => {
  const [gisSummaryToggle, setLandSummaryToggle] =
    useRecoilState(landSummaryToggle);
  const [toggleBusiness, setToggleBusiness] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [toggleSite, setToggleSite] = useState(false);
  const [toggleLandType, setToggleLandType] = useState(false);
  const landSummaryDetails = useRecoilValue(landSummary);
  return (
    <>
      <div className="box">
        <div
          className={
            "new-request-corner-btn land-summary-btn align-self-end " +
            (!gisSummaryToggle ? "new-request-corner-btn--collapsed" : "")
          }
          style={{ right: "-59px" }}
          id="new-request-corner-btn"
        >
          <Link
            to=""
            onClick={() => {
              setLandSummaryToggle(true);
            }}
            className="nav-link font-weight-bold d-flex text-white corner-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons p-1">
              Land Summary
            </span>
          </Link>
        </div>
        <div
          className={
            "new-request-init align-self-end " +
            (!gisSummaryToggle ? "new-request-init--collapsed" : "")
          }
        >
          <div
            className="text-left panel-title"
            style={{ background: "#375db1" }}
          >
            <span
              style={{ color: "white", fontSize: "24px", paddingLeft: "40px" }}
            >
              Land Summary
            </span>
            <span
              className="close-btn"
              id="new-request-close"
              onClick={() => setLandSummaryToggle(false)}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "600px" }}>
            <h6 className="mb-0 panel-header">Land Summary (In Acres)</h6>
            <table className="table">
              <tbody>
                <tr>
                  <th>
                    <span
                      onClick={() => {
                        setToggleBusiness(!toggleBusiness);
                      }}
                      className="a-link-with-underline"
                    >
                      Business
                    </span>
                  </th>
                  <td>
                    <span>
                      {landSummaryDetails?.activeBusiness
                        ? landSummaryDetails?.activeBusiness.length
                        : 0}
                    </span>
                  </td>
                </tr>
                {toggleBusiness && (
                  <tr>
                    <td colSpan={2}>
                      <ul className="expand-list">
                        {landSummaryDetails?.activeBusiness &&
                          landSummaryDetails?.activeBusiness.length > 0 &&
                          landSummaryDetails?.activeBusiness.map(
                            (activeBusiness: any) => {
                              return (
                                <li>
                                  <span>{activeBusiness.name}</span>
                                  <span>{fixNumber(activeBusiness.area)}</span>
                                </li>
                              );
                            }
                          )}
                      </ul>
                    </td>
                  </tr>
                )}

                <tr>
                  <th>
                    <span
                      onClick={() => {
                        setToggleState(!toggleState);
                      }}
                      className="a-link-with-underline"
                    >
                      State
                    </span>
                  </th>
                  <td>
                    <span className="action-btn">
                      {landSummaryDetails?.totalState
                        ? landSummaryDetails?.totalState.length
                        : 0}
                    </span>
                  </td>
                </tr>

                {toggleState && (
                  <tr>
                    <td colSpan={2}>
                      <ul className="expand-list">
                        {landSummaryDetails?.totalState &&
                          landSummaryDetails?.totalState.length > 0 &&
                          landSummaryDetails?.totalState.map((state: any) => {
                            return (
                              <li>
                                <span>{state.name}</span>
                                <span>{fixNumber(state.area)}</span>
                              </li>
                            );
                          })}
                      </ul>
                    </td>
                  </tr>
                )}

                <tr>
                  <th>Total Area Required</th>
                  <td>{fixNumber(landSummaryDetails.totalAreaRequired)} </td>
                </tr>
                <tr>
                  <th>
                    <span
                      onClick={() => {
                        setToggleSite(!toggleSite);
                      }}
                      className="a-link-with-underline"
                    >
                      Site
                    </span>
                  </th>
                  <td>
                    <span className="action-btn">
                      {landSummaryDetails?.totalSite
                        ? landSummaryDetails?.totalSite.length
                        : 0}
                    </span>
                  </td>
                </tr>

                {toggleSite && (
                  <tr>
                    <td colSpan={2}>
                      <ul className="expand-list">
                        {landSummaryDetails?.totalSite &&
                          landSummaryDetails?.totalSite.length > 0 &&
                          landSummaryDetails?.totalSite.map((site: any) => {
                            return (
                              <li>
                                <span>{site.name}</span>
                                <span>{fixNumber(site.area)}</span>
                              </li>
                            );
                          })}
                      </ul>
                    </td>
                  </tr>
                )}


                <tr>
                  <th>
                    <span
                      onClick={() => {
                        setToggleLandType(!toggleLandType);
                      }}
                      className="a-link-with-underline"
                    >
                      Land Type
                    </span>
                  </th>
                  <td>
                    <span className="action-btn">
                      {landSummaryDetails?.landType
                        ? landSummaryDetails?.landType.length
                        : 0}
                    </span>
                  </td>
                </tr>

                {toggleLandType && (
                  <tr>
                    <td colSpan={2}>
                      <ul className="expand-list">
                        {landSummaryDetails?.landType &&
                          landSummaryDetails?.landType.length > 0 &&
                          landSummaryDetails?.landType.map((land: any) => {
                            return (
                              <li>
                                <span>{land.name}</span>
                                <span>{fixNumber(land.area)}</span>
                              </li>
                            );
                          })}
                      </ul>
                    </td>
                  </tr>
                )}

                <tr>
                  <th>Total Area Acquired</th>
                  <td>{fixNumber(landSummaryDetails.totalAreaAccquired)} </td>
                </tr>
                <tr>
                  <th>Total Sale deed</th>
                  <td>{fixNumber(landSummaryDetails.totalSalesDeed)} </td>
                </tr>
                <tr>
                  <th>Mutation Status</th>
                  <td>{fixNumber(landSummaryDetails.totalMutationStatus)} </td>
                </tr>
                <tr>
                  <th>NA Status</th>
                  <td>{fixNumber(landSummaryDetails.totalNAStatus)} </td>
                </tr>
                <tr>
                  <th>TSR Status</th>
                  <td>{fixNumber(landSummaryDetails.totalTSRStatus)} </td>
                </tr>
                <tr>
                  <th>Free Hold Area</th>
                  <td>{fixNumber(landSummaryDetails.totalFreeHoldArea)} </td>
                </tr>
                <tr>
                  <th>Lease Area</th>
                  <td>{fixNumber(landSummaryDetails.totalLeaseArea)} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { requestDetailsToggle, requestId } from "../state/atom/request";
import {
  proposalDetailsToggle,
  proposalId,
  proposalIdForViewEngReport,
} from "../state/atom/proposal";
import { viewEngReportToggle } from "../state/atom/eng-report";

export const getNotificationType = (type: string) => {
  if (type === "new_request") {
    return "New Request 🎉";
  } else if (type === "new_proposal") {
    return "New Proposal 🎉";
  } else if (type === "update_request") {
    return "Request Update 🔄";
  } else if (type === "assign_request") {
    return "Request Assigned ✔️";
  } else if (type === "assign_proposal") {
    return "Proposal Assigned ✔️";
  } else if (type === "accept_proposal") {
    return "Proposal Accepted ✔️";
  } else if (type === "reject_proposal") {
    return "Proposal Rejected 🛑";
  } else if (type === "close_request") {
    return "Request Closed";
  } else if (type === "eng_report_created") {
    return "Engineer Report Created 🎉";
  } else if (type === "eng_report_accepted") {
    return "Engineer Report Accepted ✔️";
  } else if (type === "eng_report_rejected") {
    return "Engineer Report Rejected 🛑";
  } else if (type === "eng_report_updated") {
    return "Engineer Report Updated 🔄";
  } else if (type === "update_proposal") {
    return "Proposal Updated 🔄";
  } else {
    return "Request On Hold 🛑";
  }
};

export const getNotificationMessages = (
  type: string,
  requestName: string,
  proposalName: string = "",
  assigneeName: string = "",
  resourceId: string,
  proposalID: string
) => {
  if (type === "new_request") {
    return (
      <h5 className="item-info">
        A new request <strong>{requestName}</strong> has been created.
      </h5>
    );
  } else if (type === "new_proposal") {
    return (
      <h5 className="item-info">
        A new proposal <strong>{proposalName}</strong> has been added to the{" "}
        <strong>{requestName}</strong> land request.
      </h5>
    );
  } else if (type === "assign_request") {
    return (
      <h5 className="item-info">
        The request <strong>{requestName}</strong> has been assigned to{" "}
        <strong>{assigneeName}</strong>.
      </h5>
    );
  } else if (type === "assign_proposal") {
    return (
      <h5 className="item-info">
        The proposal <strong>{proposalName}</strong> has been assigned to{" "}
        <strong>{assigneeName}</strong>.
      </h5>
    );
  } else if (type === "update_proposal") {
    return (
      <h5 className="item-info">
        The proposal <strong>{proposalName}</strong> has been assigned to{" "}
        <strong>{assigneeName}</strong>.
      </h5>
    );
  } else if (type === "close_request") {
    return (
      <h5 className="item-info">
        The request <strong>{requestName}</strong> has been closed.
      </h5>
    );
  } else if (type === "eng_report_created") {
    return (
      <h5 className="item-info">
        The Engineer Report has been Added to the{" "}
        <strong>{proposalName}</strong> Proposal.
      </h5>
    );
  } else if (type === "eng_report_accepted") {
    return (
      <h5 className="item-info">
        The Engineer Report <strong>{requestName}</strong> has been Accepted.
      </h5>
    );
  } else if (type === "eng_report_rejected") {
    return (
      <h5 className="item-info">
        The Engineer Report <strong>{requestName}</strong> has been Rejected.
      </h5>
    );
  } else {
    return (
      <h5 className="item-info">
        The request <strong>{requestName}</strong> has been put on{" "}
        <strong>Hold</strong>.
      </h5>
    );
  }
};

interface Props {
  type: string;
  resourceId: string;
  requestName: string;
  proposalName: string;
  assigneeName: string;
  proposalID: string;
  // proposalIDForEdit: string;
}

export const GetNotificationMessages = ({
  type,
  resourceId,
  proposalID,
  requestName,
  proposalName,
  assigneeName,
}: // proposalIDForEdit
Props) => {
  const setRequestId = useSetRecoilState(requestId);
  const [reqDetailsToggle, setRequestDetailsToggle] =
    useRecoilState(requestDetailsToggle);
  const navigate = useNavigate();
  const setProposalDetailsToggle = useSetRecoilState(proposalDetailsToggle);
  const setEngDetailsToggle = useSetRecoilState(viewEngReportToggle);
  const setProposalId = useSetRecoilState(proposalId);

  const openNotification = (type: string, resourceId: string) => {
    setRequestDetailsToggle(false);
    setProposalDetailsToggle(false);
    if (
      type === "new_request" ||
      type === "update_request" ||
      type === "assign_request" ||
      type === "close_request" ||
      type === "hold_request"
    ) {
      setRequestId(resourceId);
      setRequestDetailsToggle(true);
      navigate("/home");
    }

    if (
      type === "new_proposal" ||
      type === "assign_proposal" ||
      type === "update_proposal" ||
      type === "accept_proposal" ||
      type === "reject_proposal"
    ) {
      setProposalId(resourceId);
      setProposalDetailsToggle(true);
      navigate("/home");
    }

    if (
      type === "eng_report_rejected" ||
      type === "eng_report_accepted" ||
      type === "eng_report_created" ||
      type === "eng_report_updated"
    ) {
      setProposalId(resourceId);
      setEngDetailsToggle(true);
      navigate("/home");
    }
  };
  return (
    <>
      {type === "new_request" && (
        <h5 className="item-info">
          A new request{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {requestName}
          </span>{" "}
          has been created.
        </h5>
      )}
      {type === "update_request" && (
        <h5 className="item-info">
          Request{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {requestName}
          </span>{" "}
          has been updated.
        </h5>
      )}
      {type === "new_proposal" && (
        <h5 className="item-info">
          A new proposal{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {proposalName}
          </span>{" "}
          has been added to the <strong>{requestName}</strong> land request.
        </h5>
      )}
      {type === "update_proposal" && (
        <h5 className="item-info">
          The proposal{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {proposalName}
          </span>{" "}
          has been Updated.
        </h5>
      )}
      {type === "accept_proposal" && (
        <h5 className="item-info">
          The proposal{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {proposalName}
          </span>{" "}
          has been Accpected.
        </h5>
      )}
      {type === "reject_proposal" && (
        <h5 className="item-info">
          The proposal{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {proposalName}
          </span>{" "}
          has been Rejected.
        </h5>
      )}
      {type === "assign_request" && (
        <h5 className="item-info">
          The request{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {requestName}
          </span>{" "}
          has been assigned to <strong>{assigneeName}</strong>.
        </h5>
      )}

      {type === "assign_proposal" && (
        <h5 className="item-info">
          The proposal{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {proposalName}
          </span>{" "}
          has been assigned to <strong>{assigneeName}</strong>.
        </h5>
      )}

      {type === "close_request" && (
        <h5 className="item-info">
          The request{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {requestName}
          </span>{" "}
          has been closed.
        </h5>
      )}

      {type === "hold_request" && (
        <h5 className="item-info">
          The request{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, resourceId)}
          >
            {requestName}
          </span>{" "}
          has been closed.
        </h5>
      )}

      {type === "eng_report_created" && (
        <h5 className="item-info">
          The Engineer Report has been Added to the{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, proposalID)}
          >
            {proposalName}
          </span>{" "}
          Proposal.
        </h5>
      )}
      {type === "eng_report_accepted" && (
        <h5 className="item-info">
          The Engineer Report{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, proposalID)}
          >
            {requestName}
          </span>{" "}
          has been Accepted.
        </h5>
      )}
      {type === "eng_report_updated" && (
        <h5 className="item-info">
          The Engineer Report{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, proposalID)}
          >
            {requestName}
          </span>{" "}
          has been Updated.
        </h5>
      )}
      {type === "eng_report_rejected" && (
        <h5 className="item-info">
          The Engineer Report{" "}
          <span
            className="notification-link"
            onClick={() => openNotification(type, proposalID)}
          >
            {requestName}
          </span>{" "}
          has been Rejected.
        </h5>
      )}
    </>
  );
};

import { Modal } from "react-bootstrap";
import {
  districtIdState,
  editDistrictModalToggle,
} from "../../../../state/atom/settings";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Http } from "../../../../utility/http";
import { apiHeaders } from "../../../../utility/generic";
import { toaster } from "../../../../state/atom/toaster";

type EditDistrictForm = {
  districtName: string;
  lat: number;
  lon: number;
};

export const EditDistrictModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useRecoilState(editDistrictModalToggle);
  const districtId = useRecoilValue(districtIdState);
  const setToasterStatus = useSetRecoilState(toaster);

  useEffect(() => {
    const getDistrict = async () => {
      await Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}district/${districtId}`,
        apiHeaders()
      )
        .then((res: any) => {
          // console.log(res);
          const district = res.data.district;
          setValue("districtName", district.districtName);
          setValue("lat", district.lat);
          setValue("lon", district.lon);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    getDistrict();
  }, []);

  const editDistrictValidationSchema = Yup.object().shape({
    districtName: Yup.string().required("District name is required."),
    lat: Yup.number()
      .typeError("latitude must be a number.")
      .required("Latitude is required."),
    lon: Yup.number()
      .typeError("Longitude must be a number.")
      .required("Longitude is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<EditDistrictForm>({
    resolver: yupResolver(editDistrictValidationSchema),
  });

  const onSubmitHandler = async (data: EditDistrictForm) => {
    setLoading(true);
    await Http.request(
      "put",
      `${process.env.REACT_APP_API_URL}district/${districtId}`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: "District Updated.",
          text: result.message,
        });
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNumericInput = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9.-]/g, "");
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          className="panel-title text-white"
          style={{ marginBottom: 0 }}
          closeButton
          closeVariant="white"
        >
          <Modal.Title>Edit District</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {submitErrorMessage && (
            <div className="text-center py-1">
              <span className="text-danger">{submitErrorMessage}</span>
            </div>
          )}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="d-flex flex-column">
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label className="form-label" htmlFor="inputStype" style={{ color: "black" }}>
                      District Name
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="stateName"
                    placeholder="TAPI"
                    {...register("districtName")}
                  />
                  {errors.districtName && (
                    <span className="text-danger">
                      {errors.districtName.message}
                    </span>
                  )}
                </div>
                {/* Latitude */}
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label className="form-label" htmlFor="inputStype" style={{ color: "black" }}>
                      Latitude
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="latitude"
                    placeholder="34.56789"
                    onInput={handleNumericInput}
                    {...register("lat")}
                  />

                  {errors.lat && (
                    <span className="text-danger">{errors.lat.message}</span>
                  )}
                </div>
                {/* Longitude */}
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label className="form-label" htmlFor="inputStype" style={{ color: "black" }}>
                      Longitude
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="longitude"
                    placeholder="98.45678"
                    onInput={handleNumericInput}
                    {...register("lon")}
                  />

                  {errors.lon && (
                    <span className="text-danger">{errors.lon.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group px-3 py-2">
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1 w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

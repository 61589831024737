// @ts-nocheck
import { useEffect, useRef, useState, useMemo } from "react";
import { loadModules } from "esri-loader";

import { mapCenter } from "../../state/atom/generic";
import { gisFeatureLayerFilter } from "../../state/atom/gis";
import { useRecoilValue } from "recoil";
import { forceRender } from "../../state/atom/report";
import { Link } from "react-router-dom";
import { getUserDetails } from "../../utility/jwt";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { ROLES } from "../../shared/role.constant";

export interface Props {
  classList?: string;
  mapHeight?: string;
}
export const ReportMap = ({ classList, mapHeight }: Props) => {
  const { user }: JwtPayLoad = getUserDetails();
  const mapCenterPostion = useRecoilValue(mapCenter);
  const mapRef = useRef(null);
  const [view, setView] = useState(null);
  const filterRef = useRef(null);
  const [mapView, setMapView] = useState("satellite");
  const gisFeatureLayerFilterData = useRecoilValue(gisFeatureLayerFilter);

  //Need this line strictly here, otherwise zoom to extent not work
  const forceRenderData = useRecoilValue(forceRender);

  const createMap = useMemo(
    () => async () => {
      loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/layers/WebTileLayer",
        "esri/widgets/BasemapToggle",
        "esri/Basemap",
      ])
        .then(
          ([
            Map,
            MapView,
            FeatureLayer,
            WebTileLayer,
            BasemapToggle,
            Basemap,
          ]) => {
            const map = new Map({
              basemap: mapView,
            });

            const view = new MapView({
              container: mapRef.current,
              map: map,
              zoom: 6,
              center: mapCenterPostion.center,
            });
            setView(view);

            const stateBoundray = new FeatureLayer({
              url: process.env.REACT_APP_STATE_BOUNDARY,
              outFields: ["*"],
            });
            map.add(stateBoundray);

            const GoogleStreets = new WebTileLayer({
              urlTemplate:
                "https://mts1.google.com/vt/lyrs=m@186112443&hl=x-local&src=app&x={col}&y={row}&z={level}&s=Galile",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });
            const goglesa = new WebTileLayer({
              urlTemplate:
                "https://mts1.google.com/vt/lyrs=s@186112443&hl=x-local&src=app&x={col}&y={row}&z={level}&s=Galile",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });

            const gogleSatellite = new WebTileLayer({
              urlTemplate:
                "https://mt1.google.com/vt/lyrs=s&hl=en&z={level}&x={col}&y={row}",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });

            const googlesate = new Basemap({
              baseLayers: [goglesa],
              thumbnailUrl: "images/google-hybrid.jpeg",
            });
            const toggle1 = new BasemapToggle(
              {
                //titleVisible: false,
                view: view,
                nextBasemap: googlesate,
              },
              "goglesat1"
            );

            const googlestrt = new Basemap({
              baseLayers: [GoogleStreets],
              thumbnailUrl: "images/google-street.jpeg",
            });
            const toggle2 = new BasemapToggle(
              {
                // titleVisible: false,
                view: view,
                nextBasemap: googlestrt,
              },
              "goglesat2"
            );

            const googleSate = new Basemap({
              baseLayers: [gogleSatellite],
              thumbnailUrl: "images/satellite.jpeg",
            });
            const toggle3 = new BasemapToggle(
              {
                // titleVisible: false,
                view: view,
                nextBasemap: googleSate,
              },
              "goglesat3"
            );

            const featureLayer = new FeatureLayer({
              url: process.env.REACT_APP_ARC_GIS_URL,
              outFields: ["*"],
              definitionExpression: "",
            });
            filterRef.current = featureLayer;
            map.add(featureLayer);

            featureLayer
              .when(() => {
                return featureLayer.queryExtent();
              })
              .then((res) => {
                if (res.extent) {
                  view.goTo(res.extent).catch(function (error) {
                    console.error(error);
                  });
                }
              });
          }
        )
        .catch((error) => {
          console.error("Error loading ArcGIS modules:", error);
        });
    },
    [mapCenterPostion.center, mapView]
  );
  useEffect(() => {
    createMap();
  }, [createMap]);

  useEffect(() => {
    let definitionExpression = "";
    if (user.role === ROLES.abu_spoc) {
      definitionExpression = `AND abu_or_lem_user_id='${user.userId}'`;
    }
    if (user.role === ROLES.le_spoc) {
      definitionExpression = `AND le_spoc_user_id='${user.userId}'`;
    }
    if (user.role === ROLES.le_dh) {
      definitionExpression = `AND le_dh_user_id='${user.userId}'`;
    }

    for (const filter of Object.keys(gisFeatureLayerFilterData)) {
      if (gisFeatureLayerFilterData[filter]) {
        if (typeof gisFeatureLayerFilterData[filter] == "string") {
          definitionExpression += `AND ${filter}='${gisFeatureLayerFilterData[filter]}'`;
        } else {
          definitionExpression += `AND ${filter}=${gisFeatureLayerFilterData[filter]}`;
        }
      }
    }

    if (definitionExpression) {
      definitionExpression = definitionExpression.slice(4);
    }
    if (view) {
      if (filterRef.current) {
        if (definitionExpression) {
          filterRef.current.definitionExpression = definitionExpression;
          filterRef.current
            .when(() => {
              return filterRef.current.queryExtent();
            })
            .then((res) => {
              if (res.extent) {
                view.goTo(res.extent).catch(function (error) {
                  console.error(error);
                });
              }
            });
        } else {
          console.log("definitionExpression1111", definitionExpression);
          filterRef.current.definitionExpression = "";
        }
      }
    }
  }, [
    view,
    gisFeatureLayerFilterData,
    forceRenderData,
    user.role,
    user.userId,
  ]);

  return (
    <div
      ref={mapRef}
      id="viewDiv"
      style={{ height: mapHeight || "100vh" }}
      className={classList}
    >
      <div className="map-type">
        <div className="dropdown">
          <div className="nav-item dropdown align-items-center pe-2">
            <Link
              className="nav-link font-weight-bold d-flex text-white dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
            >
              <i className="material-icons opacity-10 pe-1">public</i>
            </Link>
            <ul
              className="dropdown-menu dropdown-menu-end"
              style={{ minWidth: "90px" }}
            >
              <li className="map-type-li">
                <Link id="goglesat1">
                  <span></span>
                </Link>
              </li>
              <li className="map-type-li">
                {" "}
                <Link id="goglesat2">
                  <span></span>
                </Link>
              </li>
              <li className="map-type-li">
                <Link id="goglesat3">
                  <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

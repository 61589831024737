import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { addCompanyModalToggle } from "../../../../state/atom/settings";
import { useRecoilState, useSetRecoilState } from "recoil";
import { toaster } from "../../../../state/atom/toaster";
import { Http } from "../../../../utility/http";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiHeaders } from "../../../../utility/generic";
import * as Yup from "yup";
import Select from "react-select";

type BusinessUnitType = {
  id: number;
  name: string;
};

type AddCompanyForm = {
  businessUnitId: number;
  name: string;
};

export const AddCompanyModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [businessUnitList, setBusinessUnitList] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number | undefined>();
  const [showModal, setShowModal] = useRecoilState(addCompanyModalToggle);
  const setToasterStatus = useSetRecoilState(toaster);

  useEffect(() => {
    const getBusinessUnits = () => {
      setLoading(true);
      Http.request("get", `${process.env.REACT_APP_API_URL}business-units`)
        .then((result: any) => {
          setBusinessUnitList(result.data.items);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    getBusinessUnits();
  }, []);

  const options = businessUnitList.map((item: BusinessUnitType) => ({
    value: item.id,
    label: item.name.toUpperCase(),
  }));

  const addCompanyValidationSchema = Yup.object().shape({
    businessUnitId: Yup.number().required("Please select the Business Unit."),
    name: Yup.string().required("Name is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AddCompanyForm>({
    resolver: yupResolver(addCompanyValidationSchema),
  });

  const onSubmitHandler = async (data: AddCompanyForm) => {
    setLoading(true);
    await Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}company/business-unit?business-unit=${selectedValue}`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: "Company Added",
          text: result.message,
        });
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  // handle onChange event of the dropdown
  const handleChange = (event: any) => {
    console.log(event);
    if (event) {
      setSelectedValue(event.value);
      setValue("businessUnitId", event.value);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          className="panel-title"
          style={{ marginBottom: 0 }}
          closeButton
          closeVariant="white"
        >
          <Modal.Title className="text-white">Add Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {submitErrorMessage && (
              <div className="text-center py-1">
                <span className="text-danger">{submitErrorMessage}</span>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="d-flex flex-column">
                {/* Select Business Unit  */}
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="selectBusinessUnit"
                      style={{ color: "black" }}
                    >
                      Business Unit
                    </label>
                  </div>
                  <Select
                    closeMenuOnSelect={true}
                    isSearchable
                    options={options}
                    // value={options.find((item) => item.value === selectedValue)}
                    // {...register("districtId")}
                    onChange={handleChange}
                    placeholder="Select Business Unit."
                  />
                  {errors.businessUnitId && (
                    <span className="text-danger">
                      {errors.businessUnitId.message}
                    </span>
                  )}
                </div>
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="inputStype"
                      style={{ color: "black" }}
                    >
                      Company Name
                    </label>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    id="stateName"
                    placeholder="Enter company name"
                    {...register("name")}
                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group px-3 py-2">
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1 w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

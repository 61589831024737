// imports
import { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { changePasswordToggle } from "../state/atom/generic";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";
import { toaster } from "../state/atom/toaster";

// interfaces
interface ChangePasswordFormInputs {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

/**
Component displaying change password form.
*/
export const ChangePassword = () => {

  // state and hooks
  const [changePassword, setChangePassword] =
    useRecoilState(changePasswordToggle);
  const [loading, setLoading] = useState(false);
  const setToasterStatus = useSetRecoilState(toaster);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] =
    useState("");

  //validation schema
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|[^a-zA-Z\d\s:])/,
        "Password must be 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, and 1 numeric or special character"
      ),
    confirmNewPassword: Yup.string().required("Confirm password is required"),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (
      changePasswordErrorMessage &&
      (errors.oldPassword || errors.newPassword || errors.confirmNewPassword)
    ) {
      setChangePasswordErrorMessage("");
    }
  }, [
    changePasswordErrorMessage,
    errors.oldPassword,
    errors.newPassword,
    errors.confirmNewPassword,
  ]);

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  /**
  Handles the form submission when the user clicks the update password.
  This function performs an API call to update password.
  @param {ChangePasswordFormInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: ChangePasswordFormInputs) => {
    setLoading(true);
    if (changePasswordErrorMessage) {
      setChangePasswordErrorMessage("");
    }

    // Make POST request for change password API end point.
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}auth/change-password`,
      data,
      apiHeaders()
    )
      .then((res) => {

        // reset all input field
        reset();

        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Password Update",
          text: "Password Update successfully",
        });
        setChangePassword(false);
        setLoading(false);
        setShowOldPassword(false);
        setShowNewPassword(false);
        setShowConfirmPassword(false);
      })
      .catch((err) => {
        setLoading(false);

        // Set an error message if the API request fails
        setChangePasswordErrorMessage(err.response.data.message);
      });
  };

  return (
    <div className="box">
      <div
        className={
          "new-request-init align-self-end " +
          (!changePassword ? "new-request-init--collapsed" : "")
        }
      >
        <div
          className="text-right panel-title"
          style={{ background: "#375db1" }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "20px" }}
          >
            Change Password
          </span>
          <span
            className="close-btn"
            id="request-detail-close"
            onClick={() => setChangePassword(false)}
          >
            &times;
          </span>
        </div>

        <div style={{ overflowY: "auto", maxHeight: "75vh" }}>
          <h6 className="mb-0 panel-header">Change Password</h6>
          <div className="text-center py-2">
            {changePasswordErrorMessage && (
              <span className="mt-2 mb-0 text-center text-warning">
                {changePasswordErrorMessage}
              </span>
            )}
          </div>
          <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
            
            {/* Old password */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputOldPassword">Old Password</label>
              <div className="input-group">
                <input
                  type={showOldPassword ? "text" : "password"}
                  className="form-control"
                  id="inputOldPassword"
                  placeholder="Enter old password"
                  autoComplete="current-password"
                  {...register("oldPassword")}
                />
                <span
                  className="input-group-text eye-icon"
                  onClick={toggleOldPasswordVisibility}
                >
                  <i
                    className={`fa fa-eye${showOldPassword ? "-slash" : ""}`}
                  ></i>
                </span>
              </div>
              {errors.oldPassword && (
                <span className="text-warning">
                  {errors.oldPassword.message}
                </span>
              )}
            </div>

            {/* New password */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputNewPassword">New Password</label>
              <div className="input-group">
                <input
                  type={showNewPassword ? "text" : "password"}
                  className="form-control"
                  id="inputNewPassword"
                  placeholder="Enter new password"
                  autoComplete="new-password"
                  {...register("newPassword")}
                />
                <span
                  className="input-group-text eye-icon"
                  onClick={toggleNewPasswordVisibility}
                >
                  <i
                    className={`fa fa-eye${showNewPassword ? "-slash" : ""}`}
                  ></i>
                </span>
              </div>
              {errors.newPassword && (
                <span className="text-warning">
                  {errors.newPassword.message}
                </span>
              )}
            </div>

            {/* Confirm password */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputConfirmPassword">Confirm Password</label>
              <div className="input-group">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  id="inputConfirmPassword"
                  placeholder="Enter confirm password"
                  autoComplete="new-password"
                  {...register("confirmNewPassword")}
                />
                <span
                  className="input-group-text eye-icon"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <i
                    className={`fa fa-eye${
                      showConfirmPassword ? "-slash" : ""
                    }`}
                  ></i>
                </span>
              </div>
              {errors.confirmNewPassword && (
                <span className="text-warning">
                  {errors.confirmNewPassword.message}
                </span>
              )}
            </div>

            {/* Submit button */}
            <div className="form-group px-3 py-4">
              <button
                type="submit"
                className="btn bg-gradient-primary-login w-100 btn1"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Change Password"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

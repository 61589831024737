import React from "react";
import { MainLayout } from "../layout/main-layout";
import { Container, Row, Col } from "react-bootstrap";
import { SideBarComp } from "../component/settingsPage/SideBarComp";
import StatesTableList from "../component/settingsPage/StatesTableList";
import { useRecoilValue } from "recoil";
import { selectedSettingsMenu } from "../state/atom/generic";
import { SettingsMenu } from "../shared/generic.constant";
import { DistrictTableList } from "../component/settingsPage/DistrictTableList";
import { CityTableList } from "../component/settingsPage/CityTableList";
import { BusinessTableList } from "../component/settingsPage/BusinessTableList";
import { CompanyTableList } from "../component/settingsPage/CompanyTableList";
import { Toaster } from "../component/toaster";
import { validatePermission } from "../utility/permission";

export const SettingsPage: React.FC = () => {
  validatePermission("settings", "can_view");
  const menu = useRecoilValue(selectedSettingsMenu);

  return (
    <>
      <MainLayout>
        {/* Component showing the toaster bar */}
        <Toaster />
        <div className="container-fluid main-content full-layout-content">
          <Container fluid>
            <Row>
              <Col sm={12} md={3} lg={3} xl={2} className="sidebar">
                <SideBarComp />
              </Col>
              <Col
                sm={12}
                md={9}
                lg={9}
                xl={10}
                className="p-3 main-content-settings"
              >
                {/* Your main content goes here */}
                {menu === SettingsMenu.state && (
                  <div>
                    <h1>States</h1>
                    <StatesTableList />
                  </div>
                )}
                {menu === SettingsMenu.district && (
                  <div>
                    <h1>Districts</h1>
                    <DistrictTableList />
                  </div>
                )}
                {menu === SettingsMenu.city && (
                  <div>
                    <h1>Cities</h1>
                    <CityTableList />
                  </div>
                )}
                {menu === SettingsMenu.business && (
                  <div>
                    <h1>Business&#45;Unit</h1>
                    <BusinessTableList />
                  </div>
                )}
                {menu === SettingsMenu.company && (
                  <div>
                    <h1>Companies</h1>
                    <CompanyTableList />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </MainLayout>
    </>
  );
};

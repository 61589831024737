import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  editStateModalToggle,
  stateIdState,
} from "../../../../state/atom/settings";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Http } from "../../../../utility/http";
import { apiHeaders } from "../../../../utility/generic";
import { toaster } from "../../../../state/atom/toaster";

type EditStateForm = {
  stateName: string;
  stateCode: string;
  lat: number;
  lon: number;
};

export const EditStateModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useRecoilState(editStateModalToggle);
  const stateId = useRecoilValue(stateIdState);
  const setToasterStatus = useSetRecoilState(toaster);
  useEffect(() => {
    const getState = async () => {
      await Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}states/${stateId}`,
        apiHeaders()
      )
        .then((res: any) => {
          // console.log(res);
          const state = res;
          setValue("stateName", state.stateName);
          setValue("stateCode", state.stateCode);
          setValue("lat", state.lat);
          setValue("lon", state.lon);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    getState();
  }, []);

  const editStateValidationSchema = Yup.object().shape({
    stateName: Yup.string().required("State name is required."),
    stateCode: Yup.string().required("State code is required."),
    lat: Yup.number()
      .typeError("Latitude must be a number.")
      .required("Latitude is required."),
    lon: Yup.number()
      .typeError("Longitude must be a number.")
      .required("Longitude is required."),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditStateForm>({
    resolver: yupResolver(editStateValidationSchema),
  });

  const onSubmitHandler = async (data: EditStateForm) => {
    setLoading(true);
    await Http.request(
      "put",
      `${process.env.REACT_APP_API_URL}states/${stateId}`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: "State Updated.",
          text: result.message,
        });
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNumericInput = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9.-]/g, "");
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header
        className="panel-title text-white"
        style={{ marginBottom: 0 }}
        closeButton
        closeVariant="white"
      >
        <Modal.Title>Edit State</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {submitErrorMessage && (
            <div className="text-center py-1">
              <span className="text-danger">{submitErrorMessage}</span>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="d-flex flex-column">
              {/* State Name */}
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label
                    className="form-label"
                    htmlFor="inputStype"
                    style={{ color: "black" }}
                  >
                    State Name
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    errors.stateName ? "is-invalid" : ""
                  }`}
                  id="stateName"
                  {...register("stateName")}
                />
                {errors.stateName && (
                  <span className="text-danger">
                    {errors.stateName.message}
                  </span>
                )}
              </div>
              {/* State Code */}
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label
                    className="form-label"
                    htmlFor="inputStype"
                    style={{ color: "black" }}
                  >
                    State Code
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    errors.stateCode ? "is-invalid" : ""
                  }`}
                  id="stateCode"
                  {...register("stateCode")}
                />
                {errors.stateCode && (
                  <span className="text-danger">
                    {errors.stateCode.message}
                  </span>
                )}
              </div>
              {/* Latitude */}
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label
                    className="form-label"
                    htmlFor="inputStype"
                    style={{ color: "black" }}
                  >
                    Latitude
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${errors.lat ? "is-invalid" : ""}`}
                  id="latitude"
                  onInput={handleNumericInput}
                  {...register("lat")}
                />

                {errors.lat && (
                  <span className="text-danger">{errors.lat.message}</span>
                )}
              </div>
              {/* Longitude */}
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label
                    className="form-label"
                    htmlFor="inputStype"
                    style={{ color: "black" }}
                  >
                    Longitude
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${errors.lon ? "is-invalid" : ""}`}
                  id="longitude"
                  onInput={handleNumericInput}
                  {...register("lon")}
                />

                {errors.lon && (
                  <span className="text-danger">{errors.lon.message}</span>
                )}
              </div>
            </div>
            <div className="form-group px-3 py-2">
              <button
                type="submit"
                className="btn bg-gradient-primary-login btn1 w-100"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Add State
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

import { Link } from "react-router-dom";

export const NotFound = () => {
    return (
        <div className="container text-center d-flex align-items-center justify-content-center vh-100">
          <div className="row">
            <div className="col-md-12">
              <div className="error-template">
                <h1>Oops!</h1>
                <h2>404 Not Found</h2>
                <div className="error-details">
                  Sorry, an error has occurred. The requested page was not found!
                </div>
                <div className="error-actions">
                  <Link
                    to="/home"
                    className="btn btn-primary btn-sm my-3"
                  >
                    Take Me Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};

// imports
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { useRecoilState, useSetRecoilState } from "recoil";
import { toaster } from "../../state/atom/toaster";
import { addUserToggle, updateUserList } from "../../state/atom/user";
import { RoleName } from "../../shared/generic.constant";
import { Business, Role } from "../../shared/generic.interface";
import { ROLESNAME, ROLESNAME1 } from "../../shared/role.constant";
import { ROLES_TYPE } from "../../shared/role.interface";
import Select from "react-select";
import { customStyles } from "../../shared/landTypeStyle";
// interfaces
interface AddUserFromInputs {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
  employeeId: string;
  businessUnit: Array<{ value: string; label: string }>;
  role: string;
  password: string;
}

/**
Component displaying a add user form.
*/
export const AddUserForm = () => {
  // validation schema
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string().test(
      "is-10-digits",
      "Phone Number must be 10 digits",
      (value) => !value || value.toString().length === 10
    ),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    employeeId: Yup.string().required("Employee ID is required"),
    businessUnit: Yup.array()
      .min(1, "Business Name is required")
      .required("Business Name is required"),
    role: Yup.string().required("Role is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<AddUserFromInputs>({
    resolver: yupResolver(validationSchema),
  });

  // state and hooks
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const setToasterStatus = useSetRecoilState(toaster);
  const [addUser, setAddUserToggle] = useRecoilState(addUserToggle);
  const [showPassword, setShowPassword] = useState(false);
  const setUpdateUsersList = useSetRecoilState(updateUserList);
  const [businessUnit, setBusinessUnit] = useState([]);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}generic/business-unit`,
      null
    ).then((result) => {
      const businessUnit = result.data.businessUnit.map((unit: any) => {
        return {
          value: unit.id,
          label: unit.name,
        };
      });
      setBusinessUnit(businessUnit);
    });
  }, []);

  /**
  Handles the form submission when the user clicks the submit button.
  This function performs an API call to add new user.
  @param {AddUserFromInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: AddUserFromInputs) => {
    data.businessUnit = data.businessUnit.map((unit: any) => {
      return unit.value;
    });
    // Make POST reuest for add the user API endpoint
    setSubmitErrorMessage("");

    if (!data.phoneNumber) {
      delete data["phoneNumber"];
    }
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}users`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        reset();
        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "User Added",
          text: "User Added successfully",
        });

        // hide add user form
        setAddUserToggle(false);

        // update user list
        setUpdateUsersList(true);
      })
      .catch((error: any) => {
        // Set an error message if the API request fails
        setSubmitErrorMessage(error.response.data.message);
      });
    setLoading(false);
  };

  // prevent the onwheel change
  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
  };

  return (
    <>
      <div
        className={
          "new-request-init align-self-end " +
          (!addUser ? "new-request-init--collapsed" : "")
        }
      >
        <div
          className="text-left panel-title sticky-heading"
          style={{ background: "#375db1" }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "40px" }}
          >
            Add User{" "}
          </span>
          <span
            className="close-btn"
            id="new-request-close"
            onClick={() => {
              setAddUserToggle(false);
              clearErrors();
            }}
          >
            &times;
          </span>
        </div>
        <div style={{ overflowY: "auto", maxHeight: "85vh" }}>
          <h6 className="mb-0 panel-header">
            Fill all the details to add new User.
          </h6>
          {submitErrorMessage && (
            <div className="text-center">
              <span className="text-warning">{submitErrorMessage}</span>
            </div>
          )}

          <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
            {/* First Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">First Name*</label>
              <input
                type="text"
                className="form-control"
                id="firstNameAgg"
                placeholder="for example Rahul"
                {...register("firstName")}
                autoComplete="no-google-autofill"
              />

              {errors.firstName && (
                <span className="text-warning">{errors.firstName.message}</span>
              )}
            </div>

            {/* Last Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Last Name* </label>
              <input
                type="text"
                className="form-control"
                id="lastNameAgg"
                placeholder="for example Kumar"
                autoComplete="no-google-autofill"
                {...register("lastName")}
              />

              {errors.lastName && (
                <span className="text-warning">{errors.lastName.message}</span>
              )}
            </div>

            {/* Phone Number */}
            <div className="form-group px-3 py-2">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="number"
                className="form-control"
                id="phoneNumber"
                {...register("phoneNumber")}
                onWheel={numberInputOnWheelPreventChange}
                placeholder="for example 9588956898"
              />

              {errors.phoneNumber && (
                <span className="text-warning">
                  {errors.phoneNumber.message}
                </span>
              )}
            </div>

            {/* Email */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Email*</label>
              <input
                type="email"
                className="form-control"
                id="emailID"
                placeholder="for example jon@gmail.com"
                autoComplete="new-email"
                {...register("email")}
              />

              {errors.email && (
                <span className="text-warning">{errors.email.message}</span>
              )}
            </div>

            {/* Employee Id */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Employee Id*</label>
              <input
                type="text"
                className="form-control"
                id="employeeID"
                placeholder="for example AEL-GM-1234"
                autoComplete="off"
                {...register("employeeId")}
              />

              {errors.employeeId && (
                <span className="text-warning">
                  {errors.employeeId.message}
                </span>
              )}
            </div>

            {/* Business Name */}
            {/* <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Business Name*</label>
              <select
                className="form-select"
                aria-label="Default Select Business Name"
                autoComplete="no-google-autofill"
                {...register("businessUnit")}
              >
                <option value="">---Please Select Business Name---</option>
                {businessUnit.map((unit: Business, index) => {
                  return (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  );
                })}
              </select>

              {errors.businessUnit && (
                <span className="text-warning">
                  {errors.businessUnit.message}
                </span>
              )}
            </div> */}

            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Business Name*</label>
              <Controller
                name="businessUnit"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={businessUnit}
                    className="basic-multi-select"
                    placeholder="Select Business Name"
                    classNamePrefix="select"
                    styles={customStyles}
                  />
                )}
              />

              {errors.businessUnit && (
                <span className="text-warning">
                  {errors.businessUnit.message}
                </span>
              )}
            </div>

            {/* Role */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Role*</label>
              <select
                className="form-select"
                aria-label="Default Select Business Name"
                autoComplete="off"
                {...register("role")}
              >
                <option value="">---Please Select Role---</option>
                {Object.keys(ROLESNAME1).map((unit, index) => {
                  return (
                    <option key={unit} value={unit}>
                      {ROLESNAME1[unit as keyof ROLES_TYPE]}
                    </option>
                  );
                })}
              </select>

              {errors.role && (
                <span className="text-warning">{errors.role.message}</span>
              )}
            </div>

            {/* Password */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Password*</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="Enter your password"
                  autoComplete="new-password"
                  {...register("password")}
                />
                <button
                  className="btn btn-outline-secondary eye-icon text-white border-white"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="fa fa-eye-slash text-white"></i>
                  ) : (
                    <i className="fa fa-eye text-white"></i>
                  )}
                </button>
              </div>

              {errors.password && (
                <span className="text-warning">{errors.password.message}</span>
              )}
            </div>

            {/* Submit button */}
            <div className="form-group px-3 py-2">
              <button
                type="submit"
                className="btn bg-gradient-primary-login btn1 w-100"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

import * as XLSX from "xlsx";
import fileDownload from "js-file-download";
import { useRecoilValue } from "recoil";
import { misReport } from "../../state/atom/report";
import { gisFeatureLayerFilter } from "../../state/atom/gis";

export const ExportXlsx = () => {
  const misReportData = useRecoilValue(misReport);
  let gisFeatureLayerFilterData = useRecoilValue(gisFeatureLayerFilter);
  const landParcelData: any = [];
  let landObject: any = {};
  const exportToXls = () => {
    misReportData.data.landRequest.forEach((request: any) => {
      for (const landParcel of request.landParcel) {
        landObject = {};
        landObject["Site Name"] = landParcel.site;
        landObject["Business"] = landParcel.business.name;
        landObject["Company Code"] = landParcel.companyCode;
        landObject["Plant Code"] = landParcel.plantCode;
        landObject["Full Name Company"] = landParcel.company.companyName;
        landObject["Nick Name Company"] = landParcel.company.companyName;
        landObject["State"] = landParcel.stateData.stateName;
        landObject["District"] = landParcel.districtData.districtName;
        landObject["Tehsil"] = landParcel.cityData.city;
        landObject["Village"] = landParcel.village;
        landObject["Site"] = landParcel.site;
        landObject["Sellers Name"] = landParcel.sellerName;
        landObject["Survey No"] = landParcel.surveyNo;
        landObject["Parcel Area"] = landParcel.parcelArea;
        landObject["Land Type"] = landParcel.landType;
        landObject["Acquired Status"] = landParcel.acquiredStatus;
        landObject["Type Of Conveyance Deed"] = "NA";
        landObject["Sale DeedS tatus"] = landParcel.saleDeedStatus;
        landObject["Mutation Status"] = landParcel.mutationStatus;
        landObject["NA Status"] = landParcel.naStatus;
        landObject["Final TSR Status"] = landParcel.tsrStatus;
        landObject["Free Hold Area"] = landParcel.freeHoldArea;
        landObject["Lease Area"] = landParcel.leaseArea;
        landObject["Used Area"] = landParcel.usedArea;
        landObject["Company Code Sap Code"] = landParcel.companyCodeSapCode;
        landObject["Aggregator Land Spoc"] = landParcel.aggregatorLandSpoc;
        landObject["Nick Name Company"] = landParcel.nickNameCompany;
        landObject["Old Name of Company"] = landParcel.oldNameofCompany;
        landObject["Type Of Conveyance Deed"] = landParcel.typeOfConveyanceDeed;
        landObject["Conveyance Deed No"] = landParcel.conveyanceDeedNo;
        landObject["Registration Date"] = landParcel.registrationDate;
        landObject["Sub Lease To Company"] = landParcel.subLeaseToCompany;
        landObject["Sub Lease To Company Code"] =
          landParcel.subLeaseToCompanyCode;
        landObject["Sub Lease From Company"] = landParcel.subLeaseFromCompany;
        landObject["SubLease From Company Code"] =
          landParcel.subLeaseFromCompanyCode;
        landObject["Ceiling Permission"] = landParcel.ceilingPermission;
        landObject["Revenue Map Status"] = landParcel.revenueMapStatus;
        landObject["Lease Period Start Date"] = landParcel.leasePeriodStartDate;
        landObject["Lease Period End Date"] = landParcel.leasePeriodEndDate;
        landObject["Total Mortgaged Area"] = landParcel.totalMortgagedArea;
        landObject["File No"] = landParcel.fileNo;
        landObject["Mortgaged At"] = landParcel.mortgagedAt;
        landObject["Mortgage Start Date"] = landParcel.mortgageStartDate;
        landObject["Mortgage End Date"] = landParcel.mortgageEndDate;
        landObject["Litigation"] = landParcel.litigation;
        landObject["Case No"] = landParcel.caseNo;
        landObject["Court Name With Address"] = landParcel.courtNameWithaddress;
        landObject["Remarks"] = landParcel.remarks;
        landObject["Availability Of Photocopy"] =
          landParcel.availabilityOfPhotocopy;
        landObject["Storage Location"] = landParcel.storageLocation;
        landObject["Floor"] = landParcel.floor;
        landObject["Floor Type"] = landParcel.floorType;
        landObject["Racks"] = landParcel.racks;
        landObject["Shelf No"] = landParcel.shelfNo;
        landObject["Gis Mapping Status"] = landParcel.statusOfGisMapping;
        landObject["Survey Status"] = landParcel.statusOfSurvey;
        landParcelData.push(landObject);
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(landParcelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    const fileName=gisFeatureLayerFilterData?.site
      ? gisFeatureLayerFilterData?.site:"Mis Report";
    fileDownload(dataBlob, `${fileName}.xlsx`);
  };
  return (
    <>
      <button
        type="button"
        onClick={exportToXls}
        className="btn bg-gradient-primary-login btn1"
      >
        EXPORT in XLS
      </button>
    </>
  );
};

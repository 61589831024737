import { useEffect } from "react";
import { Toaster } from "../component/toaster";
import { MainLayout } from "../layout/main-layout";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";
import { useSetRecoilState } from "recoil";
import { selectedMenu } from "../state/atom/generic";
import { Menu } from "../shared/generic.constant";
import { GisFilter } from "../component/gis/gis-filter";
import { gisList } from "../state/atom/gis";
import { GISMap } from "../component/gis/gis-map";
import { LandDetails } from "../component/gis/land-details";
import { requestList } from "../state/atom/request";
import { LandSummary } from "../component/gis/land-summary";
import { validatePermission } from "../utility/permission";

export const GIS = () => {
  const setGisList = useSetRecoilState(gisList);
  const setRequestList = useSetRecoilState(requestList);
  const setSelectedMenu = useSetRecoilState(selectedMenu);
  setSelectedMenu(Menu.gis);
  validatePermission("menu", "gis");

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-request`,
      null,
      apiHeaders()
    )
      .then((result) => {
        setRequestList(result);
      })
      .catch((error) => {});
  });

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-parcel`,
      null,
      apiHeaders()
    )
      .then((result) => {
        setGisList(result);
      })
      .catch((error) => {});
  });
  return (
    <MainLayout>
      <Toaster></Toaster>
      <GisFilter></GisFilter>
      <GISMap classList="full-map gis-map" showExtraButton={true}></GISMap>
      <LandDetails></LandDetails>
      <LandSummary></LandSummary>
    </MainLayout>
  );
};

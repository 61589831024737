import { useRecoilState, useSetRecoilState } from "recoil";
import { Business, Company, State } from "../../shared/generic.interface";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { mapCenter } from "../../state/atom/generic";
import {
  forceRender,
  misReport,
  msiFilterToggle,
} from "../../state/atom/report";
import { gisFeatureLayerFilter } from "../../state/atom/gis";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { getUserDetails } from "../../utility/jwt";
import { ROLES } from "../../shared/role.constant";
interface MisFilterFormInputs {
  businessName: string;
  companyName: string;
  state: string;
  district: string;
  requestId: string;
  proposalId: string;
  city: string;
  village: string;
  site: string;
  surveyNo: string;
}
export const MisFilter = () => {
  const { user }: JwtPayLoad = getUserDetails();
  let [gisFeatureLayerFilterData, setGisFeatureLayerFilter] = useRecoilState(
    gisFeatureLayerFilter
  );
  const [misReportData, setMisReportData] = useRecoilState(misReport);
  const [misReportDataClone, setMisReportDataClone] = useState<any>([]);
  const [misReportFilter, setMisReportFilter] = useState<any>([]);
  const setForceRender = useSetRecoilState(forceRender);
  const [states, setState] = useState<State[]>([]);
  const [surveys, setSurvey] = useState<State[]>([]);
  const [sites, setSite] = useState<State[]>([]);
  const [business, setBusiness] = useState<Business[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [requests, setRequest] = useState<any>([]);
  const [proposals, setProposals] = useState<any>([]);
  const isStateUpdated = useRef(false);
  const [msiFiltersToggle, setMSIFiltersToggle] =
    useRecoilState(msiFilterToggle);
  const validationSchema = Yup.object().shape({
    businessName: Yup.string().notRequired(),
    companyName: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    village: Yup.string().notRequired(),
    requestId: Yup.string().notRequired(),
    proposalId: Yup.string().notRequired(),
    site: Yup.string().notRequired(),
    surveyNo: Yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<MisFilterFormInputs>({
    resolver: yupResolver(validationSchema),
  });
  const businessName = watch("businessName");
  const companyName = watch("companyName");
  const state = watch("state");
  const site = watch("site");
  const surveyNo = watch("surveyNo");
  const requestId = watch("requestId");
  const proposalId = watch("proposalId");
  useEffect(() => {
    if (!isStateUpdated.current && misReportData?.data?.landRequest.length) {
      setMisReportDataClone({ ...misReportData });
      setMisReportFilter({ ...misReportData });
      createFilterData(misReportData?.data?.landRequest);
      //@ts-ignore
      isStateUpdated.current = true;
    }
  }, [isStateUpdated, misReportData]);

  const onSubmit = (data: MisFilterFormInputs) => {
    let filterRequest: any = JSON.parse(
      JSON.stringify(misReportDataClone.data.landRequest)
    );

    if (data.businessName) {
      filterRequest = filterRequest.filter((request: any) => {
        return Number(request.businessUnit) === Number(data.businessName);
      });
    }

    if (data.state) {
      filterRequest = filterRequest.filter((request: any) => {
        return Number(request.state.id) === Number(data.state);
      });
    }

    if (data.companyName) {
      filterRequest = filterRequest.filter((request: any) => {
        return Number(request.companyName.id) === Number(data.companyName);
      });
    }

    if (data.requestId) {
      filterRequest = filterRequest.filter((request: any) => {
        return request.id === data.requestId;
      });
    }
    /* console.log("gisFeatureLayerFilterData", gisFeatureLayerFilterData);
    setmapCenter({
      zoom: 10,
      center: [72.94545, 73.48353],
      isShow: false,
    }); */
    setForceRender(new Date().toString());
    setGisFeatureLayerFilter(gisFeatureLayerFilterData);
    setMisReportData({
      data: {
        landRequest: filterRequest,
      },
    });
  };

  const filterData = (e: any, field: string) => {
    let filterData: any = misReportDataClone;
    if (e.target.value) {
      if (field === "business") {
        filterData = filterData.data.landRequest.filter((request: any) => {
          return Number(request.businessUnit) === Number(e.target.value);
        });
        gisFeatureLayerFilterData.business_name = e.target.value;
        createFilterData(filterData, "business");
        setValue("businessName", e.target.value);
      }

      if (field === "company") {
        filterData = filterData.data.landRequest.filter((request: any) => {
          return Number(request.companyName.id) === Number(e.target.value);
        });
        gisFeatureLayerFilterData.company_name = e.target.value;
        createFilterData(filterData, "company");
        setValue("companyName", e.target.value);
      }

      if (field === "state") {
        filterData = filterData.data.landRequest.filter((request: any) => {
          return Number(request.state.id) === Number(e.target.value);
        });
        gisFeatureLayerFilterData.state = e.target.value;
        createFilterData(filterData, "state");
        setValue("state", e.target.value);
      }

      if (field === "request") {
        filterData = filterData.data.landRequest.filter((request: any) => {
          return request.id === e.target.value;
        });
        gisFeatureLayerFilterData.request_id = e.target.value;
        createFilterData(filterData, "request");
        setValue("requestId", e.target.value);
      }

      /* if (field === "proposal") {
        filterData = filterData.data.landRequest.filter((request: any) => {
          return request.id === e.target.value;
        });
        createFilterData(filterData, "request");
        setValue("requestId", e.target.value);

      } */

      if (field === "site") {
        filterData = filterData.data.landRequest.filter((request: any) => {
          return request.location === e.target.value;
        });
        gisFeatureLayerFilterData.site = e.target.value;
        createFilterData(filterData, "site");
        setValue("site", e.target.value);
      }

      setMisReportFilter({
        data: {
          landRequest: [...filterData],
        },
      });
    }
  };

  const createFilterData = (data: any, skip: string = "") => {
    const businessData = [];
    if (skip !== "business") {
      for (const request of data) {
        const index = businessData.findIndex(
          (business) => business.id === request.businessUnitName.id
        );
        if (index === -1) {
          businessData.push(request.businessUnitName);
        }
      }
      setBusiness(businessData);
    }
    if (skip !== "company") {
      const companies = [];
      for (const request of data) {
        const index = companies.findIndex(
          (company) => company.id === request.companyName.id
        );
        if (index === -1) {
          companies.push(request.companyName);
        }
      }
      setCompanies(companies);
    }

    if (skip !== "state") {
      const states = [];
      for (const request of data) {
        const index = states.findIndex(
          (state) => state.id === request.state.id
        );
        if (index === -1) {
          states.push(request.state);
        }
      }
      setState(states);
    }

    if (skip !== "site") {
      const states = [];
      for (const request of data) {
        const index = states.findIndex(
          (state) => state.id === request.state.id
        );
        if (index === -1) {
          states.push(request.state);
        }
      }
      setState(states);
    }

    if (skip !== "request") {
      const requests = [];
      for (const request of data) {
        requests.push({
          id: request.id,
          name: request.requestName,
        });
      }
      setRequest(requests);
    }

    if (skip !== "proposal") {
      const proposals = [];
      for (const request of data) {
        for (const proposal of request.proposals) {
          proposals.push({
            id: proposal.id,
            name: `${proposal.proposalName} : ${proposal.proposalId}`,
          });
        }
      }
      setProposals(proposals);
    }

    if (skip !== "site") {
      const sites = [];
      for (const parcel of data) {
        const index = sites.findIndex((site) => site === parcel.location);
        if (index === -1) {
          sites.push(parcel.location);
        }
      }
      setSite(sites);
    }

    /* if (skip !== "survey") {
      const surveys = [];
      for (const parcel of data) {
        const index = surveys.findIndex((survey) => survey === parcel.surveyNo);
        if (index === -1) {
          surveys.push(parcel.surveyNo);
        }
      }
      setSurvey(surveys);
    } */
  };

  return (
    <>
      <div className="box">
        <div
          className={
            "new-request-corner-btn gis-corner-btn align-self-end " +
            (!msiFiltersToggle ? "new-request-corner-btn--collapsed" : "")
          }
          onClick={() => {
            setMSIFiltersToggle(true);
          }}
          id="new-request-corner-btn"
        >
          <Link
            to=""
            className="nav-link font-weight-bold d-flex text-white corner-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons p-1">
              MIS Filter
            </span>
          </Link>
        </div>
        <div
          className={
            "mis-filter-popup " +
            (!msiFiltersToggle ? "request-detail-init--collapsed" : "")
          }
        >
          <div
            className="text-right panel-title sticky-heading"
            style={{ background: "#375db1" }}
          >
            <span style={{ color: "white", fontSize: "24px" }}>
              MIS Filters
            </span>
            <span
              className="close-btn"
              id="request-detail-close"
              onClick={() => {
                setMSIFiltersToggle(false);
              }}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "75vh" }}>
            <h6 className="mb-0 panel-header">
              Select the data for apply filter
            </h6>
            <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group px-3 py-2">
                <label htmlFor="inputBusiness">Business Name</label>
                <select
                  className="form-select"
                  aria-label="Default Business Name"
                  {...register("businessName")}
                  onChange={(event) => filterData(event, "business")}
                  value={businessName}
                >
                  <option value="">---Business Name ---</option>
                  {business.map((unit: any, index: any) => {
                    return <option value={unit.id}>{unit.name}</option>;
                  })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label htmlFor="inputBusiness">Company Name</label>
                <select
                  className="form-select"
                  aria-label="Default Company Name"
                  {...register("companyName")}
                  onChange={(event) => filterData(event, "company")}
                  value={companyName}
                >
                  <option value="">---Select Company Name---</option>
                  {companies.map((company: any, index: any) => {
                    return <option value={company.id}>{company.name}</option>;
                  })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label htmlFor="select-state">Select State</label>
                <select
                  className="form-select"
                  {...register("state")}
                  onChange={(event) => filterData(event, "state")}
                  value={state}
                >
                  <option value="">---Please Select State---</option>
                  {states.map((state: any, index: any) => {
                    return <option value={state.id}>{state.stateName}</option>;
                  })}
                </select>
              </div>

              <div className="form-group px-3 py-2">
                <label htmlFor="select-state">Select Request</label>
                <select
                  className="form-select"
                  {...register("requestId")}
                  onChange={(event) => filterData(event, "request")}
                  value={requestId}
                >
                  <option value="">---Please Select Request---</option>
                  {requests.map((request: any, index: any) => {
                    return <option value={request.id}>{request.name}</option>;
                  })}
                </select>
              </div>

              {/* <div className="form-group px-3 py-2">
                <label htmlFor="select-state">Select Proposal</label>
                <select
                  className="form-select"
                  {...register("proposalId")}
                  onChange={(event) => filterData(event, "proposal")}
                  value={proposalId}
                >
                  <option value="">---Please Select Proposal---</option>
                  {proposals.map((proposal: any, index: any) => {
                    return <option value={proposal.id}>{proposal.name}</option>;
                  })}
                </select>
                <small id="stateHelp" className="form-text text-white">
                  proposal will show the location.
                </small>
              </div> */}

              <div className="form-group px-3 py-2">
                <label htmlFor="select-city">Select Site</label>
                <select
                  className="form-control"
                  aria-label="Default City"
                  {...register("site")}
                  onChange={(event) => filterData(event, "site")}
                  value={site}
                >
                  <option value="">---Select Site---</option>
                  {sites.map((site: any, index: any) => {
                    return <option value={site}>{site}</option>;
                  })}
                </select>
              </div>

              <div
                className="form-group d-flex  mt-3 mb-3"
                style={{ justifyContent: "space-evenly" }}
              >
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    setMisReportData(misReportDataClone);
                    setMisReportFilter(misReportDataClone);
                    createFilterData(misReportDataClone.data.landRequest);
                    setForceRender(new Date().toString());
                    setGisFeatureLayerFilter({
                      SurveyNo: "",
                      Village: "",
                      BusinessName: "",
                      CompanyName: "",
                      Site: "",
                      State: "",
                      Request_Id: "",
                      abu_or_lem_user_id:
                        user.role === ROLES.abu_spoc ? user.userId : "",
                      le_spoc_user_id:
                        user.role === ROLES.le_spoc ? user.userId : "",
                      le_dh_user_id:
                        user.role === ROLES.le_dh ? user.userId : "",
                    });
                  }}
                  className="btn bg-gradient-primary-login  btn1"
                >
                  Reset FILTER
                </button>
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1"
                >
                  APPLY FILTER
                </button>
                {/* <ExportPdf key="pdf1"></ExportPdf> */}
              </div>
            </form>
          </div>
        </div>
        {/* <div className="box-inner" style={{ bottom: "246px", right: "394px" }}>
          <a
            href="/#"
            className="nav-link font-weight-bold d-flex text-white corner-btn"
            id="request-detail-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons">
              GIS Filters
            </span>
          </a>
        </div> */}
      </div>
    </>
  );
};
